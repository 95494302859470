import { Link, useLocation } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { format } from 'date-fns';

export default function UpcomingEventsView({ client }) {
	const location = useLocation();

	return client?.cases?.map((cCase) => (
		<div key={cCase.id}>
			{(cCase.officeCaseStatus === 'open' || cCase.officeCaseStatus === 'closed') &&
			cCase.events.length === 0 ? (
				// {cCase.events.length === 0 ? (
				<>
					<hr />
					<h5>
						{cCase.type} - {cCase.agency}: (no events)
					</h5>
				</>
			) : (
				<div>
					<h5>
						{cCase.type} - {cCase.agency}
					</h5>
					<Table bordered>
						<tbody>
							{cCase?.events.map((event) => (
								<tr
									key={event.id}
									className={
										(event.category === 'Filing Deadline' && event.status === 'in-progress'
											? 'table-warning'
											: undefined) ||
										(event.category === 'Filing (no deadline)' && event.status === 'in-progress'
											? 'table-warning'
											: undefined) ||
										(event.category === 'Hearing' && event.status === 'scheduled'
											? 'table-danger'
											: undefined) ||
										(event.category === 'Interview' && event.status === 'scheduled'
											? 'table-info'
											: undefined) ||
										(event.category === 'Appointment' && event.status === 'scheduled'
											? 'table-success'
											: undefined) ||
										(event.category === 'Reminder' && event.status === 'unconfirmed'
											? 'table-light'
											: undefined) ||
										'table-secondary'
									}
								>
									<td style={{ width: '15%' }}>
										{format(new Date(event.originalDateTime), 'MMMM dd, yyyy')}

										{event.category === 'Filing Deadline' ||
										event.category === 'Filing (no deadline)'
											? null
											: ' at ' + format(new Date(event.originalDateTime), 'hh:mm a')}
									</td>
									<td style={{ width: '10%' }}>
										<span
											className={
												(event.type === 'Individual' ? 'individual' : undefined) ||
												(event.type === 'Master' ? 'master' : undefined) ||
												(event.type === 'Criminal' ? 'criminal' : undefined) ||
												''
											}
										>
											{event.type}
										</span>{' '}
										{event.category}
									</td>

									<td style={{ width: '20%' }}>
										{event.regarding || <span className='text-muted fst-italic'>(Regarding)</span>}
									</td>
									<td style={{ width: '10%' }}>
										{event.location || <span className='text-muted fst-italic'>(Location)</span>}
									</td>
									<td style={{ width: '10%' }}>
										{(event.immigrationJudge && 'IJ: ' + event.immigrationJudge) || (
											<span className='text-muted fst-italic'>(IJ)</span>
										)}
									</td>
									{/* <td className='text-center' style={{ width: '5%' }}>
                    {event.status}
                  </td> */}
									<td className='text-center' style={{ width: '5%' }}>
										<Link
											to={{
												pathname: `/update-event/${event.id}`,
												state: {
													prevURL: location.pathname,
													category: event.category,
													clientID: client.id,
													caseID: cCase.id,
												},
											}}
										>
											Edit
										</Link>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			)}
		</div>
	));
}
