import { useState, useEffect, useContext } from 'react';
import { Table, Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { RestQueriesContext } from '../../api/restQueries';
import { AppContext } from '../../contexts/AppContext';
import CardShell from '../../app/components/CardShell';
import NotSet from '../../app/components/NotSet';

export default function ViewAllClientsPage() {
	const [limit] = useState(20);
	const [start, setStart] = useState(0);
	const [prevDisabled, setPrevDisabled] = useState(true);
	const [nextDisabled, setNextDisabled] = useState(false);
	const [firstDisabled, setFirstDisabled] = useState(false);
	const [clientsCounter, setClientsCounter] = useState(20);
	const [page, setPage] = useState(1);
	const { getAllClients, countClients } = useContext(RestQueriesContext);
	const { selectCase, cCase } = useContext(AppContext);
	const { data: count } = useQuery('countClients', countClients);
	const {
		isLoading,
		isError,
		data: clients,
		error,
		isFetching,
	} = useQuery(['getAllClients', start, limit], () => getAllClients(start, limit));

	useEffect(() => {
		if (cCase) {
			selectCase(null);
		}
	}, [cCase, selectCase]);

	useEffect(() => {
		if (clientsCounter > clients?.length) {
			setClientsCounter(clients?.length);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (clientsCounter <= 20) {
			setPrevDisabled(true);
			setFirstDisabled(true);
		}
		if (clientsCounter > 20) {
			setPrevDisabled(false);
			setFirstDisabled(false);
		}
		if (clientsCounter >= count) {
			setNextDisabled(true);
		}
		if (clientsCounter < count) {
			setNextDisabled(false);
		}
	}, [clientsCounter, count]);

	const nextPage = () => {
		setStart((start) => start + 20);
		if (count - clientsCounter > 20) setClientsCounter((clientsCounter) => clientsCounter + 20);
		if (count - clientsCounter < 20)
			setClientsCounter((clientsCounter) => clientsCounter + (count % 20));
		setPage((page) => page + 1);
	};

	const prevPage = () => {
		setStart((start) => start - 20);
		if (clientsCounter === count)
			setClientsCounter((clientsCounter) => clientsCounter - (count % 20));
		if (clientsCounter < count) setClientsCounter((clientsCounter) => clientsCounter - 20);
		setPage((page) => page - 1);
	};

	const firstPage = () => {
		setStart(0);
		setClientsCounter(20);
		setPage(1);
	};

	if (isLoading)
		return (
			<CardShell header={`All Clients (${count}) `} headerSize='h4'>
				<h4 className='text-center'>Loading clients...</h4>
			</CardShell>
		);

	if (isError)
		return (
			<CardShell header={`All Clients (${count}) `} headerSize='h4'>
				<h5 className='text-center'>Loading clients list failed - {error.message}</h5>
			</CardShell>
		);

	return clients?.length === 0 ? (
		'No Clients'
	) : (
		<>
			<CardShell header={`All Clients (${clientsCounter} of ${count}) `} headerSize='h4'>
				<div className='pb-5'>
					<Table size='sm'>
						<thead>
							<tr className=''>
								<th style={{ width: '15%', textAlign: 'left' }}>Client</th>
								<th style={{ width: '7%', textAlign: 'left' }}>A#</th>
								<th style={{ width: '8%', textAlign: 'left' }}>Phone</th>
								{/* <th style={{ width: '15%', textAlign: 'left' }}>Email </th>
								<th style={{ width: '20%', textAlign: 'left' }}>Address</th>
								<th style={{ width: '5%' }}>Gender</th>
								<th style={{ width: '6%' }}>Country</th>
								<th style={{ width: '6%' }}>Best Language</th>
								<th style={{ width: '5%' }}>Religion</th>
								<th style={{ width: '5%' }}>Sect</th> */}
								<th>Cases</th>
							</tr>
						</thead>
						<tbody>
							{clients?.map((client, i) => (
								<tr key={client.id} className='text-center'>
									<td
										className='text-start copy'
										onClick={() =>
											navigator.clipboard.writeText(client.lastName + ', ' + client.firstName)
										}
									>
										<Link to={`/view-client/${client.id}`}>
											{client.lastName}, {client.firstName}
										</Link>
									</td>
									{/* <td>{client.aNumber}</td> */}
									<td
										className='text-start copy'
										onClick={() => navigator.clipboard.writeText(client.aNumber)}
									>
										{(client.aNumber &&
											client.aNumber.substr(0, 3) +
												'-' +
												client.aNumber.substr(3, 3) +
												'-' +
												client.aNumber.substr(6)) || <NotSet />}
									</td>
									<td
										className='text-start copy'
										onClick={() => navigator.clipboard.writeText(client.phone_1)}
									>
										{/* {client.phone_1 || <NotSet />} <br /> {client.phone_2 || <NotSet />} */}
										{client.phone_1 &&
											'(' +
												client.phone_1.substr(0, 3) +
												') ' +
												client.phone_1.substr(3, 3) +
												'-' +
												client.phone_1.substr(6)}
									</td>
									<td className='text-start'>
										{client.cases.map((cCase) => (
											<span key={cCase.id}>
												{cCase.type} - [{cCase.officeCaseStatus}] <br />
											</span>
										))}
									</td>
									{/* <td
										className='text-start copy'
										onClick={() => navigator.clipboard.writeText(client.email_1)}
									>
										{client.email_1 || <NotSet />}
									</td>
									<td
										className='text-start copy'
										onClick={() => navigator.clipboard.writeText(client.mailingAddress)}
									>
										{client.mailingAddress || <NotSet />}
									</td>
									<td>{client.gender || <NotSet />}</td>
									<td>{client.countryOfBirth || <NotSet />}</td>
									<td>{client.bestLanguage || <NotSet />}</td>
									<td>{client.religion || <NotSet />}</td>
									<td>{client.sect || <NotSet />}</td> */}
								</tr>
							))}
						</tbody>
					</Table>
				</div>
				<Row>
					<Col className='text-center mb-3'>
						{' '}
						<h4>{page}</h4>
					</Col>
				</Row>
				<Row>
					<Col></Col>
					<Col className='text-center'>
						<ButtonGroup>
							<Button onClick={prevPage} disabled={prevDisabled}>
								Previous
							</Button>
							<Button onClick={firstPage} disabled={firstDisabled}>
								First Page
							</Button>
							<Button onClick={nextPage} disabled={nextDisabled}>
								Next
							</Button>
						</ButtonGroup>
					</Col>
					<Col className='text-end'>{isFetching && <p>cheking for new data...</p>}</Col>
				</Row>
				<div></div>
				<div></div>
			</CardShell>
		</>
	);
}
