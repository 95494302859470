import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';
import { agenciesList, serviceTypesList } from '../../../app/components/DataLists';

export default function ServiceForm({
	addServiceSubmitFunction,
	updateServiceSubmitFunction,
	service,
	caseID,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			date: service?.date || null,
			type: service?.status || null,
			description: service?.description || null,
			agency: service?.agency || null,
		},
	});

	useEffect(() => {
		service &&
			reset({
				...service,
				date: (service.date && format(new Date(service.date), 'yyyy-MM-dd')) || null,
			});
	}, [reset, service]);

	const onSubmit = (formData) => {
		if (!service) {
			addServiceSubmitFunction({
				...formData,
				cases: [caseID],
				date: (formData.date && formData.date + 'T00:00') || null,
			});
		}
		if (service) {
			updateServiceSubmitFunction({
				...formData,
				date: (formData.date && formData.date + 'T00:00') || null,
			});
		}
	};

	return (
		<>
			{/* <h3>Service</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('type')}
						id='type'
						type='text'
						placeholder='Type'
						list='serviceTypes'
						required
					/>
					<label htmlFor='type'>Type</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='serviceTypes'>
						{serviceTypesList.map((service) => (
							<option key={service} value={service} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('description')}
						id='description'
						type='text'
						placeholder='Description'
					/>
					<label htmlFor='description'>Description</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						id='agency'
						{...register('agency')}
						list='agencies'
						type='text'
						placeholder='Agency'
					/>
					<label htmlFor='agency'>Agency</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='agencies'>
						{agenciesList.map((agency) => (
							<option key={agency} value={agency} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						max='2999-12-31'
						{...register('date')}
						id='date'
						type='date'
						placeholder='Date'
					/>
					<label htmlFor='date'>Date</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
