import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import HistoryUpdateForm from '../components/forms/History-Update-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateCaseUpdate, getCaseUpdate } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateHistoryUpdatePage() {
	const { updateCaseUpdate, getCaseUpdate } = useContext(RestQueriesContext);
	const { client, cCase } = useContext(AppContext);
	const history = useHistory();
	const { updateID } = useParams();

	const {
		isLoading,
		isError,
		data: caseUpdate,
		error,
	} = useQuery(['getCaseUpdate', updateID], () => getCaseUpdate(updateID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateCaseUpdate(updateID, formData), {
		onSuccess: () => {
			// history.push('/view-client/' + client.id);
			history.push(`/view-client/${client.id}/${cCase.id}`);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} cCase={cCase} caption='Edit Case Update' />
				<HistoryUpdateForm
					updateCaseUpdateSubmitFunction={onSubmit}
					caseID={cCase.id}
					caseUpdate={caseUpdate}
				/>
			</Container>
		</>
	);
}
