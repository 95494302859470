import { Accordion } from 'react-bootstrap';

export default function AccordionShell({ children, header, textAlignment }) {
	return (
		<Accordion>
			<Accordion.Item style={{ border: '', backgroundColor: '' }} eventKey='0'>
				<Accordion.Header>{header}</Accordion.Header>
				<Accordion.Body>{children}</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
}
