export const formatPhoneNum = (num) => {
	return '(' + num.substr(0, 3) + ') ' + num.substr(3, 3) + '-' + num.substr(6);
};

export const formatANum = (aNum) => {
	return aNum.substr(0, 3) + '-' + aNum.substr(3, 3) + '-' + aNum.substr(6);
};

export const confDeleteFn = (id, mutate, text) => {
	if (window.confirm('Are you sure you want to remove this ' + text + ' ?')) {
		mutate(id);
		return true;
	} else {
		return false;
	}
};
