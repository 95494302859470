import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import AppContextProvider from './contexts/AppContext';

import ProvideAuth from './contexts/AuthContext';
// import { ProvideAuth } from './app/auth/use-auth';
import ProvideRestQueries from './api/restQueries';
import ProvideGqlQueries from './api/gqlQueries';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

const queryClient = new QueryClient();

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Router>
				<ProvideAuth>
					<ProvideGqlQueries>
						<ProvideRestQueries>
							<AppContextProvider>
								<App />
							</AppContextProvider>
						</ProvideRestQueries>
					</ProvideGqlQueries>
				</ProvideAuth>
			</Router>
			{/* <ReactQueryDevtools /> */}
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
