import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function NoticeOfAppearanceForm({
	addNoticeOfAppearanceSubmitFunction,
	updateNoticeOfAppearanceSubmitFunction,
	notice,
	caseID,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			type: notice?.type || null,
			status: notice?.status || null,
			dateOfFiling: notice?.dateOfFiling || null,
		},
	});

	useEffect(() => {
		notice &&
			reset({
				...notice,
				dateOfFiling:
					(notice.dateOfFiling && format(new Date(notice.dateOfFiling), 'yyyy-MM-dd')) || null,
			});
	}, [reset, notice]);

	const onSubmit = (formData) => {
		if (!notice) {
			addNoticeOfAppearanceSubmitFunction({
				...formData,
				cases: [caseID],
				dateOfFiling: (formData.dateOfFiling && formData.dateOfFiling + 'T00:00') || null,
			});
		}
		if (notice) {
			updateNoticeOfAppearanceSubmitFunction({
				...formData,
				dateOfFiling: (formData.dateOfFiling && formData.dateOfFiling + 'T00:00') || null,
			});
		}
	};

	return (
		<>
			{/* <h3>Notice of Entry of Appearance</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<select className='form-select' {...register('type')} id='type' required>
						<option value='G-28'>G-28</option>
						<option value='EOIR-28'>EOIR-28</option>
						<option value='EOIR-27'>EOIR-27</option>
						<option value='AO-458'>AO-458</option>
					</select>
					<label htmlFor='type'>Type</label>
				</div>

				<div className='form-floating mb-3'>
					<select className='form-select' {...register('status')} id='status' required>
						<option value='not filed'>not filed</option>
						<option value='filed'>filed</option>
					</select>
					<label htmlFor='status'>Status</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('dateOfFiling')}
						max='2999-12-31'
						id='dateOfFiling'
						type='date'
						placeholder='Date of Filing'
					/>
					<label htmlFor='dateOfFiling'>Date of Filing</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
