import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import NotSet from '../../../../app/components/NotSet';
import { formatANum, formatPhoneNum, confDeleteFn } from '../../../../app/utils/helper-functions';

export default function ChildrenView({ children, mutate }) {
	const location = useLocation();

	return children?.length === 0 ? null : (
		<>
			<h5>Children</h5>
			<Table bordered size='sm' className='text-center'>
				<thead>
					<tr>
						<th>Name</th>
						<th>Phone</th>
						<th>Email</th>
						{/* <th>Address</th> */}
						<th>Relation</th>
						<th>Date of Birth</th>
						<th>Immigration Status</th>
						{/* <th>Status Expiry Date</th> */}
						<th>A-Number</th>
						<th>Role</th>
						{/* <th>Note</th> */}
						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{children?.map((child) => (
						<tr key={child.id}>
							<td>
								{child.firstName} {child.lastName}
							</td>
							<td>{(child.phone && formatPhoneNum(child.phone)) || <NotSet />}</td>
							<td>{child.email || <NotSet />}</td>
							{/* <td>{child.address || <NotSet />}</td> */}
							<td>{child.relationToClient || <NotSet />}</td>
							<td>
								{(child.dateOfBirth && format(new Date(child.dateOfBirth), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td>
							<td>{child.immigrationStatus || <NotSet />}</td>
							{/* <td>
								{(child.immigrationStatusExpiryDate &&
									format(new Date(child.immigrationStatusExpiryDate), 'MM/dd/yyyy')) || <NotSet />}
							</td> */}
							<td>{(child.aNumber && formatANum(child.aNumber)) || <NotSet />}</td>
							<td>{child.role || <NotSet />}</td>
							{/* <td>{child.note || <NotSet />}</td> */}

							<td>
								<Link
									to={{
										pathname: `/update-family-member/${child.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td>
								<Button variant='link' onClick={() => confDeleteFn(child.id, mutate, 'Child')}>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
}
