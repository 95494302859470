import { useEffect, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Card, Row, Col, ListGroup } from 'react-bootstrap';
import ClientProfileView from '../components/views/Client-Profile-View';
import ClientCasesView from '../../case/components/views/Client-Cases-View';
import NotSet from '../../app/components/NotSet';
import ClientDocumentsView from '../../document/components/views/Client-Documents-View';
import { GqlQueriesContext } from '../../api/gqlQueries';
import ContactInformationView from '../components/views/Contact-Information-view';
import UpcomingEventsView from '../../case/components/views/events/Upcoming-Events-View';
import { format, intervalToDuration } from 'date-fns';
import { formatANum, formatPhoneNum } from '../../app/utils/helper-functions';

export default function MainClientPage() {
	const { getClientGQL } = useContext(GqlQueriesContext);
	const { selectClient, refetchClientFn } = useContext(AppContext);
	const { clientID } = useParams();

	const {
		isLoading,
		isError,
		data: client,
		error,
		refetch: refetchClient,
	} = useQuery(['getClientGQL', clientID], () => getClientGQL(clientID));

	useEffect(() => {
		if (client) {
			selectClient(client);
			refetchClientFn(refetchClient);
		}
		// eslint-disable-next-line
	}, [client, refetchClient]);

	if (isLoading) return <p>Loading...</p>;

	if (isError) return <p>Error: {error.message}</p>;

	const clientFullName =
		client.lastName + ', ' + client.firstName + ' ' + (client.middleName || '');

	// const formatANum =
	// 	client.aNumber &&
	// 	client.aNumber.substr(0, 3) +
	// 		'-' +
	// 		client.aNumber.substr(3, 3) +
	// 		'-' +
	// 		client.aNumber.substr(6);

	// const formatted_phone_num_1 =
	// 	'(' +
	// 	client.phone_1.substr(0, 3) +
	// 	') ' +
	// 	client.phone_1.substr(3, 3) +
	// 	'-' +
	// 	client.phone_1.substr(6);

	// const formatted_phone_num_2 =
	// 	'(' +
	// 	client.phone_2.substr(0, 3) +
	// 	') ' +
	// 	client.phone_2.substr(3, 3) +
	// 	'-' +
	// 	client.phone_2.substr(6);

	return (
		<>
			<Card className='client-page-cards shadow'>
				<Card.Body>
					<Row>
						<Col md={6}>
							<div>
								<h3
									className='display-5 text-center mt-5 copy'
									onClick={() =>
										navigator.clipboard.writeText(
											client.lastName + ', ' + client.firstName + '' + (client.middleName || '')
										)
									}
								>
									{client.lastName}, {client.firstName} {client.middleName}{' '}
								</h3>
								<h3
									className='display-6 text-center copy'
									onClick={() => navigator.clipboard.writeText(client.aNumber)}
								>
									{(client.aNumber && ` A${formatANum(client.aNumber)}`) || (
										<NotSet a=' A-Number is ' />
									)}
								</h3>
							</div>
							<div
								className='text-center mt-5 copy'
								onClick={() =>
									navigator.clipboard.writeText(
										clientFullName +
											' - A' +
											client.aNumber +
											'\n' +
											formatPhoneNum(client.aNumber) +
											(client.phone_2 ? '\n' + formatPhoneNum(client.phone_2) : '') +
											(client.email_1 ? '\n' + client.email_1 : '') +
											(client.email_2 ? '\n' + client.email_2 : '') +
											(client.mailingAddress ? '\n' + client.mailingAddress + ' (Mailing)' : '') +
											(client.physicalAddress ? '\n' + client.physicalAddress + ' (Physical)' : '')
									)
								}
							>
								Copy client info
							</div>
							{(client.otherNamesUsed ||
								client.countryOfBirth ||
								client.currentNationality ||
								client.countryOfResidence ||
								client.imm_status ||
								client.gender ||
								client.dateOfBirth ||
								client.maritalStatus ||
								client.numberOfChildren) && (
								<Card>
									<ListGroup variant='flush'>
										<ListGroup.Item>
											{client.otherNamesUsed && `AKA: ${client.otherNamesUsed}, `}
											{client.countryOfBirth && `native of ${client.countryOfBirth}, `}
											{client.currentNationality && `citizen of ${client.currentNationality}, `}
											{client.countryOfResidence &&
												`currently residing in ${client.countryOfResidence}, `}
											{client.imm_status && `as ${client.imm_status}, `}
										</ListGroup.Item>
										<ListGroup.Item>
											{client.dateOfBirth &&
												`
												${
													intervalToDuration({
														start: new Date(client.dateOfBirth),
														end: new Date(),
													}).years
												} years & ${
													intervalToDuration({
														start: new Date(client.dateOfBirth),
														end: new Date(),
													}).months
												} months old - (DOB: ${format(
													new Date(client.dateOfBirth),
													'MMMM dd, yyyy'
												)})`}
										</ListGroup.Item>
										<ListGroup.Item>
											{client.maritalStatus && `${client.maritalStatus}, `}
											{client.gender && `${client.gender}, `}{' '}
											{client.numberOfChildren && `with ${client.numberOfChildren} children`}
										</ListGroup.Item>
									</ListGroup>
								</Card>
							)}
						</Col>
						<Col md={6}>
							<ContactInformationView />
						</Col>
					</Row>
				</Card.Body>
			</Card>

			<Card className='client-page-cards shadow'>
				<Card.Body>
					<Row>
						<Col>
							<h3>Upcoming events:</h3>
							<UpcomingEventsView client={client} />
						</Col>
					</Row>
				</Card.Body>
			</Card>

			<Card className='client-page-cards shadow'>
				<Card.Body>
					<Tabs fill variant='tabs' defaultActiveKey='cases' id='client-main-page'>
						<Tab eventKey='cases' title={`Cases (${client.cases.length})`}>
							<ClientCasesView />
						</Tab>
						<Tab eventKey='profile' title='Profile'>
							<ClientProfileView />
						</Tab>
						<Tab eventKey='documents' title='Documents'>
							<ClientDocumentsView />
						</Tab>
					</Tabs>
				</Card.Body>
			</Card>
		</>
	);
}
