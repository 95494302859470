import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import FilingPrepChecklistForm from '../components/forms/events/Filing-Prep-Checklist-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation } from 'react-query';
// import { addChecklistItem } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function AddChecklistItemPage() {
	const { client, cCase } = useContext(AppContext);
	const { addChecklistItem } = useContext(RestQueriesContext);
	const history = useHistory();
	const { eventID } = useParams();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => addChecklistItem(formData), {
		onSuccess: (data) => {
			history.push({
				pathname: '/filing-page/' + eventID,
			});
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isMutationLoading) return <div>Loading...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				{client && <FormTitle client={client} cCase={cCase} caption='Add Checklist Item' />}
				<FilingPrepChecklistForm
					addFilingChecklistItemSubmitFunction={onSubmit}
					eventID={eventID}
				/>
			</Container>
		</>
	);
}
