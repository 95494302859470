import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import CaseForm from '../../case/components/forms/Case-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateCase, getCase } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateCasePage() {
	const { updateCase, getCase } = useContext(RestQueriesContext);
	const { client } = useContext(AppContext);
	const history = useHistory();
	const { caseID } = useParams();

	const {
		isLoading,
		isError,
		data: cCase,
		error,
	} = useQuery(['getCase', caseID], () => getCase(caseID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateCase(caseID, formData), {
		onSuccess: () => {
			history.push(`/view-client/${client.id}/${cCase.id}`);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} caption={`Edit case details: ( ${cCase.type} )`} />
				<CaseForm updateCaseSubmitFunction={onSubmit} cCase={cCase} />
			</Container>
		</>
	);
}
