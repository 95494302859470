import { useEffect, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { Row, Col } from 'react-bootstrap';
import DashboardEventsView from '../components/views/Dashboard-Events-View';
import DashboardPendingCasesView from '../components/views/Dashboard-Pending-Cases-View';
import DashboardRemindersView from '../components/views/Dashboard-Reminders-View';
import DashboardEventsDayView from '../components/views/Dashboard-Events-Day-View';
import DashboardDeadlinesRemindersView from '../components/views/Dashboard-Deadlines-Reminders-View';

export default function Dashboard() {
	const { selectCase, cCase } = useContext(AppContext);

	useEffect(() => {
		if (cCase) {
			selectCase(null);
		}
	}, [cCase, selectCase]);

	return (
		<>
			<Row>
				<Col>
					<DashboardEventsDayView />
					<Row>
						<Col md={8}>
							<DashboardEventsView />
						</Col>
						<Col md={4}>
							<DashboardDeadlinesRemindersView />
						</Col>
					</Row>

					<DashboardPendingCasesView />
					<DashboardRemindersView />
				</Col>
			</Row>
		</>
	);
}
