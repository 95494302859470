import { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import CardShell from '../components/CardShell';
import { GqlQueriesContext } from '../../api/gqlQueries';
import { AppContext } from '../../contexts/AppContext';
import SearchResultsTableView from '../components/views/Search-Results-Table-View';
import SearchResultsCardView from '../components/views/Search-Results-Card-View';
import { Button } from 'react-bootstrap';

export default function SearchResultesPage() {
	const { searchClientsGQL } = useContext(GqlQueriesContext);
	const { selectCase, cCase } = useContext(AppContext);
	const { searchTerm } = useParams();
	const [tabularView, setTabularView] = useState(true);
	const [cardView, setCardView] = useState(false);

	useEffect(() => {
		if (cCase) {
			selectCase(null);
		}
	}, [cCase, selectCase]);

	const {
		isLoading,
		isError,
		data: clients,
		error,
	} = useQuery(['searchClientsGQL', searchTerm], () => searchClientsGQL(searchTerm));

	const switchView = () => {
		setTabularView(!tabularView);
		setCardView(!cardView);
	};

	if (isLoading)
		return (
			<CardShell header='Search Resluts' headerSize='h4'>
				<h4 className='text-center'>Loading Results ...</h4>
			</CardShell>
		);

	if (isError)
		return (
			<CardShell header='Search Resluts' headerSize='h4'>
				<h4 className='text-center'>Loading Results failed - {error.message} </h4>
			</CardShell>
		);

	return clients?.length === 0 ? (
		<CardShell header={`Search Resluts (${clients.length})`} headerSize='h4'>
			<h4 className='text-center'>No clients found!</h4>
		</CardShell>
	) : (
		<>
			<div className='text-center mb-4'>
				<Button onClick={switchView}>Switch view</Button>
			</div>
			<div hidden={tabularView}>
				<CardShell
					header={`Search Resluts (${clients.length}) - [ ${searchTerm} ]`}
					headerSize='h4'
				>
					<SearchResultsTableView clients={clients} />
				</CardShell>
			</div>

			<div hidden={cardView}>
				<CardShell
					header={`Search Resluts (${clients.length}) - [ ${searchTerm} ]`}
					headerSize='h4'
				>
					<SearchResultsCardView clients={clients} />
				</CardShell>
			</div>
		</>
	);
}
