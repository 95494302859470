import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { confDeleteFn } from '../../../../app/utils/confirm-delete';
import { Table, Button } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import NotSet from '../../../../app/components/NotSet';
// import { removeFilingElement } from '../../../../api/restQueries';
import { RestQueriesContext } from '../../../../api/restQueries';

export default function FilingElementsView() {
	const { removeFilingElement } = useContext(RestQueriesContext);
	const { event, refetchEvent: refetch } = useContext(AppContext);
	const location = useLocation();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeFilingElement(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing filing element...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return event?.elements.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-filing-element/${event.id}`,
					state: { prevURL: location.pathname },
				}}
			>
				+ Filing Element
			</Link>
		</div>
	) : (
		event && (
			<>
				<Table bordered size='sm'>
					<thead>
						<tr>
							<th>Type</th>
							<th>Number / Name / Description</th>
							<th>Supporting Documents</th>
							<th colSpan='2'>Action</th>
						</tr>
					</thead>
					<tbody>
						{event?.elements.map((element) => {
							return (
								<tr key={element.id}>
									<td>{element.type || <NotSet />}</td>
									<td>
										{element.number || null} {element.name || null} {element.description || null}
									</td>
									<td>
										{element.supporting_documents?.length !== 0 ? (
											// element.supporting_documents?.map((document) => (
											// <div key={document.id}>
											<Link
												to={`/view-supporting-documents/${element.id}`}
												// to={{
												// 	pathname: `/view-supporting-documents/${element.id}`,
												// 	state: {
												// 		prevURL: location.pathname,
												// 	},
												// }}
											>
												{`View (${element.supporting_documents?.length})`}
											</Link>
										) : (
											// </div>
											// ))
											<Link
												to={{
													pathname: `/add-supporting-document/${element.id}`,
													state: {
														prevURL: location.pathname,
													},
												}}
											>
												+ Document
											</Link>
										)}
									</td>
									<td>
										<Link
											to={{
												pathname: `/update-filing-element/${element.id}`,
												state: {
													prevURL: location.pathname,
												},
											}}
										>
											Edit
										</Link>
									</td>
									<td>
										{/* <Button variant='link' onClick={() => mutate(element.id)}> */}
										<Button
											variant='link'
											onClick={() => confDeleteFn(element.id, mutate, 'Filing Element')}
										>
											Remove
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
				<div>
					<Link
						to={{
							pathname: `/add-filing-element/${event.id}`,
							state: { prevURL: location.pathname },
						}}
					>
						+ Filing Element
					</Link>
				</div>
			</>
		)
	);
}
