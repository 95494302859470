import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import {
	countriesList,
	religionsList,
	sectsList,
	ethnicitiesList,
	relationsToClientList,
	rolesInCaseList,
	immStatusList,
} from '../../../app/components/DataLists';
import { format } from 'date-fns';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function FamilyMemberForm({
	addFamilyMemberSubmitFunction,
	updateFamilyMemberSubmitFunction,
	familyMember,
	category,
	clientID,
}) {
	const location = useLocation();
	const history = useHistory();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			firstName: familyMember?.firstName || null,
			lastName: familyMember?.lastName || null,
			phone: familyMember?.phone || null,
			email: familyMember?.email || null,
			address: familyMember?.address || null,
			relationToClient: familyMember?.relationToClient || null,
			dateOfBirth: familyMember?.dateOfBirth || null,
			countryOfBirth: familyMember?.countryOfBirth || null,
			nationality: familyMember?.nationality || null,
			religion: familyMember?.religion || null,
			sect: familyMember?.sect || null,
			ethnicity: familyMember?.ethnicity || null,
			maritalStatus: familyMember?.maritalStatus || null,
			immigrationStatus: familyMember?.immigrationStatus || null,
			immigrationStatusExpiryDate: familyMember?.immigrationStatusExpiryDate || null,
			aNumber: familyMember?.aNumber || null,
			role: familyMember?.role || null,
			note: familyMember?.note || null,
		},
	});

	useEffect(() => {
		familyMember &&
			reset({
				...familyMember,
				dateOfBirth:
					(familyMember.dateOfBirth && format(new Date(familyMember.dateOfBirth), 'yyyy-MM-dd')) ||
					null,
			});
	}, [reset, familyMember]);

	const onSubmit = (formData) => {
		if (!familyMember)
			addFamilyMemberSubmitFunction({
				...formData,
				category: category,
				clients: [clientID],
				dateOfBirth: (formData.dateOfBirth && formData.dateOfBirth + 'T00:00') || null,
			});
		if (familyMember)
			updateFamilyMemberSubmitFunction({
				...formData,
				dateOfBirth: (formData.dateOfBirth && formData.dateOfBirth + 'T00:00') || null,
			});
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('firstName')}
								id='firstName'
								type='text'
								placeholder='First Name'
								required
							/>
							<label htmlFor='firstName'>First Name</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('lastName')}
								id='lastName'
								type='text'
								placeholder='Last Name'
								required
							/>
							<label htmlFor='lastName'>Last Name</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('relationToClient')}
								id='relationToClient'
								type='text'
								list='relations'
								placeholder='Relation to Client'
								required
							/>
							<label htmlFor='relationToClient'>Relation to Client</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='relations'>
								{relationsToClientList.map((relation) => (
									<option key={relation} value={relation} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('role')}
								id='role'
								type='text'
								list='roles'
								placeholder='Role'
							/>
							<label htmlFor='role'>Role in case</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='roles'>
								{rolesInCaseList.map((role) => (
									<option key={role} value={role} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('phone')}
								id='phone'
								type='text'
								placeholder='Phone'
							/>
							<label htmlFor='phoneNumber'>Phone</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('email')}
								id='email'
								type='email'
								placeholder='Email'
							/>
							<label htmlFor='email'>Email</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('address')}
								id='address'
								type='text'
								placeholder='Address'
							/>
							<label htmlFor='address'>Address</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('dateOfBirth')}
								max='2999-12-31'
								id='dateOfBirth'
								type='date'
								placeholder='Date of Birth'
							/>
							<label htmlFor='dateOfBirth'>Date of Birth</label>
						</div>
					</Col>
				</Row>

				{/* <div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('countryOfBirth')}
						id='countryOfBirth'
						type='text'
						placeholder='Country of Birth'
					/>
					<label htmlFor='countryOfBirth'>Country of Birth</label>
				</div> */}

				{category === 'Spouse' && (
					<Row>
						<Col>
							{' '}
							<div className='form-floating mb-3'>
								<input
									className='form-control'
									{...register('nationality')}
									id='nationality'
									type='text'
									list='countries'
									placeholder='Nationality'
								/>
								<label htmlFor='nationality'>Nationality</label>
								{/* ===============DATALIST OPTIONS================== */}
								<datalist id='countries'>
									{countriesList.map((country) => (
										<option key={country} value={country} />
									))}
								</datalist>
								{/* ================================================= */}
							</div>
						</Col>
						<Col>
							{' '}
							<div className='form-floating mb-3'>
								<input
									className='form-control'
									{...register('religion')}
									id='religion'
									type='text'
									list='religions'
									placeholder='Religion'
								/>
								<label htmlFor='religion'>Religion</label>
								{/* ===============DATALIST OPTIONS================== */}
								<datalist id='religions'>
									{religionsList.map((religion) => (
										<option key={religion} value={religion} />
									))}
								</datalist>
								{/* ================================================= */}
							</div>
						</Col>
						<Col>
							{' '}
							<div className='form-floating mb-3'>
								<input
									className='form-control'
									{...register('sect')}
									id='sect'
									type='text'
									list='sects'
									placeholder='Sect'
								/>
								<label htmlFor='sect'>Sect</label>
								{/* ===============DATALIST OPTIONS================== */}
								<datalist id='sects'>
									{sectsList.map((sect) => (
										<option key={sect} value={sect} />
									))}
								</datalist>
								{/* ================================================= */}
							</div>
						</Col>
						<Col>
							<div className='form-floating mb-3'>
								<input
									className='form-control'
									{...register('ethnicity')}
									id='ethnicity'
									type='text'
									list='ethnicities'
									placeholder='Ethnicity'
								/>
								<label htmlFor='ethnicity'>Ethnicity</label>
								{/* ===============DATALIST OPTIONS================== */}
								<datalist id='ethnicities'>
									{ethnicitiesList.map((ethnicity) => (
										<option key={ethnicity} value={ethnicity} />
									))}
								</datalist>
								{/* ================================================= */}
							</div>
						</Col>
					</Row>
				)}

				{/* <div className='form-floating mb-3'>
					<select className='form-select' id='maritalStatus' aria-label='Marital Status'>
						<option value=''></option>
						<option value='single'>single</option>
						<option value='married'>married</option>
						<option value='divorced'>divorced</option>
						<option value='widowed'>widowed</option>
					</select>
					<label htmlFor='maritalStatus'>Marital Status</label>
				</div> */}

				<Row>
					<Col>
						{' '}
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('immigrationStatus')}
								id='immigrationStatus'
								type='text'
								list='imm-status'
								placeholder='Immigration Status'
							/>
							<label htmlFor='immigrationStatus'>Immigration Status</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='imm-status'>
								{immStatusList.map((status) => (
									<option key={status} value={status} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
					<Col>
						{' '}
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('immigrationStatusExpiryDate')}
								max='2999-12-31'
								id='immigrationStatusExpiryDate'
								type='date'
								placeholder='Immigration Status Expiry Date'
							/>
							<label htmlFor='immigrationStatusExpiryDate'>Expiry Date</label>
						</div>
					</Col>
					<Col>
						{' '}
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('aNumber')}
								id='aNumber'
								type='text'
								placeholder='A-Number'
							/>
							<label htmlFor='aNumber'>A-Number</label>
						</div>
					</Col>
				</Row>

				<div className='form-floating mb-3'>
					<textarea
						className='form-control'
						{...register('note')}
						id='note'
						placeholder='Note'
						style={{ height: '10rem' }}
					></textarea>
					<label htmlFor='note'>Note</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
