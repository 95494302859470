import { useContext } from 'react';
// import { AppContext } from '../../contexts/AppContext';
import HearingForm from '../components/forms/events/Hearing-Form';
import FilingForm from '../components/forms/events/Filing-Form';
import FilingNDForm from '../components/forms/events/FilingND-Form';
import InterviewForm from '../components/forms/events/Interview-Form';
import AppointmentForm from '../components/forms/events/Appointment-Form';
import ReminderForm from '../components/forms/events/Reminder-Form';
import ReceivedDocumentForm from '../components/forms/events/Received-Document-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateEvent, getEvent, getClient, getCase } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateEventPage() {
	const { updateEvent, getEvent, getClient, getCase } = useContext(RestQueriesContext);
	// const { client, cCase } = useContext(AppContext);
	const history = useHistory();
	const location = useLocation();
	const { category, clientID, caseID } = location.state;
	const { eventID } = useParams();

	const { data: client } = useQuery(['getClient', clientID], () => getClient(clientID));

	const { data: cCase } = useQuery(['getCase', caseID], () => getCase(caseID));

	const {
		isLoading,
		isError,
		data: event,
		error,
	} = useQuery(['getEvent', eventID], () => getEvent(eventID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateEvent(eventID, formData), {
		onSuccess: () => {
			// history.push('/view-client/' + client.id);
			history.push(`/view-client/${client.id}/${cCase.id}`);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			{client && cCase && (
				<Container>
					<FormTitle client={client} cCase={cCase} caption={`Edit ${event.category} `} />

					{category === 'Filing Deadline' && (
						<FilingForm
							updateEventSubmitFunction={onSubmit}
							caseID={cCase.id}
							clientID={clientID}
							category={category}
							event={event}
						/>
					)}

					{category === 'Filing (no deadline)' && (
						<FilingNDForm
							updateEventSubmitFunction={onSubmit}
							caseID={cCase.id}
							clientID={clientID}
							category={category}
							event={event}
						/>
					)}

					{category === 'Hearing' && (
						<HearingForm
							updateEventSubmitFunction={onSubmit}
							caseID={cCase.id}
							category={category}
							event={event}
						/>
					)}

					{category === 'Interview' && (
						<InterviewForm
							updateEventSubmitFunction={onSubmit}
							caseID={cCase.id}
							category={category}
							event={event}
						/>
					)}

					{category === 'Appointment' && (
						<AppointmentForm
							updateEventSubmitFunction={onSubmit}
							caseID={cCase.id}
							category={category}
							event={event}
						/>
					)}

					{category === 'Reminder' && (
						<ReminderForm
							updateEventSubmitFunction={onSubmit}
							caseID={cCase.id}
							category={category}
							event={event}
						/>
					)}

					{category === 'Received Document' && (
						<ReceivedDocumentForm
							updateEventSubmitFunction={onSubmit}
							caseID={cCase.id}
							category={category}
							event={event}
						/>
					)}
				</Container>
			)}
		</>
	);
}
