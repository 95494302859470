import { Container, Navbar, Nav, Button } from 'react-bootstrap';
// import { NavDropdown} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import NavBarSearchForm from './NavBar-Search-Form';
import { AuthContext } from '../../contexts/AuthContext';
import { useContext } from 'react';
// import { useAuth } from '../auth/use-auth';

export default function NavBar() {
	const { user, signout } = useContext(AuthContext);
	// const auth = useAuth();
	const location = useLocation();
	return (
		<Navbar bg='light' variant='dark' expand='lg' className='mb-3'>
			{/* <Navbar bg='dark' variant='dark' expand='lg' className='mb-5'> */}
			<Container fluid>
				{/* <Navbar.Brand href='/'>Anzarouth Office</Navbar.Brand> */}
				<Link to='/' className='navbar-brand'>
					Anzarouth Office
				</Link>
				<Navbar.Toggle />
				<Navbar.Collapse id='navbarScroll'>
					<Nav className='me-auto my-2 my-lg-0' style={{ maxHeight: '100px' }} navbarScroll>
						<Link to='/' className='nav-link'>
							Dashboard
						</Link>
						<Link
							to={{ pathname: '/create-client', state: { prevURL: location.pathname } }}
							className='nav-link'
						>
							+ Create Client
						</Link>
						<Link to='/view-all-clients' className='nav-link'>
							All Clients
						</Link>

						<Link
							to={{ pathname: `/add-office-reminder`, state: { prevURL: location.pathname } }}
							className='nav-link'
						>
							+ Task/Reminder
						</Link>
						{/* <NavDropdown title='Link' id='navbarScrollingDropdown'>
							<Link to='/action-1' data-rr-ui-dropdown-item className='dropdown-item'>
								Acction 1
							</Link>
							<Link to='/action-2' data-rr-ui-dropdown-item className='dropdown-item'>
								Acction 2
							</Link>				
							<NavDropdown.Divider />
							<Link to='/action-3' data-rr-ui-dropdown-item className='dropdown-item'>
								Action 3
							</Link>
						</NavDropdown> */}
					</Nav>
					<NavBarSearchForm />
					{user && <Button onClick={() => signout()}>{user.username + ' | Logout'}</Button>}
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
