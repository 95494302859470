import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
// import NotSet from '../../../app/components/NotSet';
import { formatPhoneNum } from '../../../app/utils/helper-functions';

export default function ContactInformationView() {
	const { client } = useContext(AppContext);
	const location = useLocation();

	if (!client) {
		return <div className='text-center'>No client data</div>;
	}
	return (
		<>
			<ListGroup variant='flushx' className='mt-5' style={{ fontSize: '1.3rem' }}>
				<ListGroup.Item
					className='copy'
					onClick={() =>
						navigator.clipboard.writeText(
							client.phone_1 + (client.phone_2 ? ' - ' + client.phone_2 : '')
						)
					}
				>
					{client.phone_1 && formatPhoneNum(client.phone_1)}{' '}
					{(client.phone_2 && `- ${formatPhoneNum(client.phone_2)}`) || null}
				</ListGroup.Item>

				<ListGroup.Item
					className='copy'
					onClick={() =>
						navigator.clipboard.writeText(
							(client.email_1 ? client.email_1 : '') +
								(client.email_2 ? ' - ' + client.email_2 : '')
						)
					}
				>
					{client.email_1 || <i className='text-muted'>Email is not set</i>}{' '}
					{(client.email_2 && ' - ' + client.email_2) || null}{' '}
				</ListGroup.Item>

				<ListGroup.Item
					className='copy'
					onClick={() =>
						navigator.clipboard.writeText(client.mailingAddress ? client.mailingAddress : '')
					}
				>
					{client.mailingAddress || <i className='text-muted'>Mailing address is not set</i>}{' '}
					{client.mailingAddress && <i className='text-muted'>(Mailing)</i>}
				</ListGroup.Item>
				<ListGroup.Item
					className='copy'
					onClick={() =>
						navigator.clipboard.writeText(client.physicalAddress ? client.physicalAddress : '')
					}
				>
					{client.physicalAddress || <i className='text-muted'>Physical address is not set</i>}{' '}
					{client.physicalAddress && <i className='text-muted'>(Physical)</i>}
				</ListGroup.Item>
			</ListGroup>

			<div className='text-center'>
				<Link
					to={{
						pathname: `/update-client/${client.id}`,
						state: { prevURL: location.pathname },
					}}
					className='btn btn-primary d-grid mt-2'
				>
					Add/Edit Information
				</Link>
			</div>
		</>
	);
}
