import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { Table } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import NotSet from '../../../app/components/NotSet';
import { format } from 'date-fns';

export default function ImmigrationInformationView() {
	const { client } = useContext(AppContext);
	const location = useLocation();

	if (!client) {
		return <div>No client data</div>;
	}
	return (
		<>
			<Table bordered size='sm'>
				<tbody>
					<tr>
						<td style={{ width: '40%' }}>
							<span className='fw-bold'>A-Number:</span>
						</td>
						{/* <td>{client.aNumber || <NotSet />}</td> */}
						<td>
							{(client.aNumber &&
								client.aNumber.substr(0, 3) +
									'-' +
									client.aNumber.substr(3, 3) +
									'-' +
									client.aNumber.substr(6)) || <NotSet />}
						</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>U.S. Immigration Status:</span>
						</td>
						<td>{client.imm_status || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Status Issue Date:</span>
						</td>
						<td>
							{(client.imm_statusIssueDate &&
								format(new Date(client.imm_statusIssueDate), 'MM/dd/yyyy')) || <NotSet />}
						</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Status Expiry Date:</span>
						</td>
						<td>
							{(client.imm_statusExpiryDate &&
								format(new Date(client.imm_statusExpiryDate), 'MM/dd/yyyy')) || <NotSet />}
						</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Detained?</span>
						</td>
						<td>{client.imm_detained || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Custody Facility:</span>
						</td>
						<td>{client.imm_custodyFacility || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Date of Arrest:</span>
						</td>
						<td>
							{(client.imm_dateOfArrest &&
								format(new Date(client.imm_dateOfArrest), 'MM/dd/yyyy')) || <NotSet />}
						</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Date of Release:</span>
						</td>
						<td>
							{(client.imm_dateOfRelease &&
								format(new Date(client.imm_dateOfRelease), 'MM/dd/yyyy')) || <NotSet />}
						</td>
					</tr>
				</tbody>
			</Table>

			<div className='text-center'>
				<Link
					to={{
						pathname: `/update-immigration-information/${client.id}`,
						state: { prevURL: location.pathname },
					}}
				>
					Add/Edit Information
				</Link>
			</div>
		</>
	);
}
