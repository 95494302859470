import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SaveButton from '../../../../app/components/SaveButton';
import CancelButton from '../../../../app/components/CancelButton';
import { supportingDocumentTypesList } from '../../../../app/components/DataLists';
import { format } from 'date-fns';

export default function SupportingDocumentForm({
	addSupportingDocumentSubmitFunction,
	updateSupportingDocumentSubmitFunction,
	document,
	elementID,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			category: document?.category || null,
			name: document?.name || null,
			fromDate: document?.fromDate || null,
			toDate: document?.toDate || null,
		},
	});

	useEffect(() => {
		document &&
			reset({
				...document,
				fromDate: (document.fromDate && format(new Date(document.fromDate), 'yyyy-MM-dd')) || null,
				toDate: (document.toDate && format(new Date(document.toDate), 'yyyy-MM-dd')) || null,
			});
	}, [reset, document]);

	const onSubmit = (formData) => {
		if (!document) {
			addSupportingDocumentSubmitFunction({
				...formData,
				elements: [elementID],
				fromDate: (formData.fromDate && formData.fromDate + 'T00:00') || null,
				toDate: (formData.toDate && formData.toDate + 'T00:00') || null,
			});
		}
		if (document) {
			updateSupportingDocumentSubmitFunction({
				...formData,
				fromDate: (formData.fromDate && formData.fromDate + 'T00:00') || null,
				toDate: (formData.toDate && formData.toDate + 'T00:00') || null,
			});
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('category')}
						id='category'
						type='text'
						list='categories'
						placeholder='Category'
						required
					/>
					<label htmlFor='category'>Category</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='categories'>
						{supportingDocumentTypesList.map((category) => (
							<option key={category} value={category} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('name')}
						id='name'
						type='text'
						placeholder='Name'
						required
					/>
					<label htmlFor='name'>Name</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						max='2999-12-31'
						{...register('fromDate')}
						id='fromDate'
						type='date'
						placeholder='From Date'
					/>
					<label htmlFor='fromDate'>From Date</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						max='2999-12-31'
						{...register('toDate')}
						id='toDate'
						type='date'
						placeholder='To Date'
					/>
					<label htmlFor='toDate'>To Date</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
