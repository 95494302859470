import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import NoticeOfAppearanceForm from '../components/forms/Notice-Of-Appearance-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateNoticeOfAppearance, getNoticeOfAppearance } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateNoticeOfAppearancePage() {
	const { updateNoticeOfAppearance, getNoticeOfAppearance } = useContext(RestQueriesContext);
	const { client, cCase } = useContext(AppContext);
	const history = useHistory();
	const { noticeID } = useParams();

	const {
		isLoading,
		isError,
		data: notice,
		error,
	} = useQuery(['getNoticeOfAppearance', noticeID], () => getNoticeOfAppearance(noticeID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateNoticeOfAppearance(noticeID, formData), {
		onSuccess: () => {
			// history.push('/view-client/' + client.id);
			history.push(`/view-client/${client.id}/${cCase.id}`);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} cCase={cCase} caption='Edit Notice of Appearance' />
				<NoticeOfAppearanceForm
					updateNoticeOfAppearanceSubmitFunction={onSubmit}
					caseID={cCase.id}
					notice={notice}
				/>
			</Container>
		</>
	);
}
