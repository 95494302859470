import { GraphQLClient } from 'graphql-request';
import {
	queryGetClientById,
	queryGetClients,
	querySearchClients,
	queryGetCaseById,
	queryGetAllCases,
	queryGetEventById,
	queryGetActiveEvents,
	queryGetAllEvents,
	queryGetAllChecklistItems,
	queryGetTodaysEvents,
	queryGetPendingEvents,
	queryGetDeadlinesAndRemindersGQL,
} from './gqlQueryStrings';
import { createContext, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

export const GqlQueriesContext = createContext();

export default function ProvideGqlQueries({ children }) {
	const { jwt: token } = useContext(AuthContext);

	// const endPoint = 'http://localhost:1337/graphql';
	const endPoint = process.env.REACT_APP_GQL_ENDPOINT;
	const gqlClient = new GraphQLClient(endPoint, { headers: { authorization: `Bearer ${token}` } });

	// =========================== CLIENT QUIRIES ===========================
	const getClientGQL = async (id) => {
		const data = await gqlClient.request(queryGetClientById, { client_id: id });
		return data.client;
	};

	const getAllClientsGQL = async () => {
		const data = await gqlClient.request(queryGetClients);
		return data.clients;
	};

	const addClientGQL = async (formData) => {
		return null;
	};

	const updateClientGQL = async (id, formData) => {
		return null;
	};

	const searchClientsGQL = async (searchTerm) => {
		// First Name - Last Name - A-Number - Phone 1 - Phone 2 - Country of Birth - Religion - Gender
		const data = await gqlClient.request(querySearchClients, { searchTerm: searchTerm });
		return data.clients;
	};

	// =========================== CASE QUIRIES ===========================
	const addCaseGQL = async (formData) => {
		return null;
	};

	const getCaseGQL = async (id) => {
		const data = await gqlClient.request(queryGetCaseById, { case_id: id });
		return data.case;
	};

	const getAllCasesGQL = async () => {
		const data = await gqlClient.request(queryGetAllCases);
		return data.cases;
	};

	const updateCaseGQL = async (id, formData) => {
		return null;
	};

	const removeCaseGQL = async (id) => {
		return null;
	};

	// =========================== EVENT QUIRIES ===========================

	const addEventGQL = async (formData) => {
		return null;
	};

	const getEventGQL = async (id) => {
		const data = await gqlClient.request(queryGetEventById, { event_id: id });
		return data.event;
	};

	const getEventsGQL = async (thisMonth, activeEventsOnly) => {
		if (activeEventsOnly) {
			try {
				const data = await gqlClient.request(queryGetActiveEvents, { thisMonth: thisMonth });
				return data.events;
			} catch (error) {
				console.log(error);
			}
		}

		if (!activeEventsOnly) {
			try {
				const data = await gqlClient.request(queryGetAllEvents, { thisMonth: thisMonth });
				return data.events;
			} catch (error) {
				console.log(error);
			}
		}
	};

	const getDeadlinesAndRemindersGQL = async (thisMonth, activeEventsOnly) => {
		if (activeEventsOnly) {
			try {
				const data = await gqlClient.request(queryGetDeadlinesAndRemindersGQL, {
					thisMonth: thisMonth,
				});
				return data.events;
			} catch (error) {
				console.log(error);
			}
		}

		if (!activeEventsOnly) {
			try {
				const data = await gqlClient.request(queryGetAllEvents, { thisMonth: thisMonth });
				return data.events;
			} catch (error) {
				console.log(error);
			}
		}
	};

	const getPendingEventsGQL = async () => {
		try {
			const data = await gqlClient.request(queryGetPendingEvents);
			return data.events;
		} catch (error) {
			console.log(error);
		}
	};

	const getTodaysEventsGQL = async (todays_date) => {
		try {
			const data = await gqlClient.request(queryGetTodaysEvents, { todays_date: todays_date });
			return data.events;
		} catch (error) {
			console.log(error);
		}
	};

	const updateEventGQL = async (id, formData) => {
		return null;
	};

	const removeEventGQL = async (id) => {
		return null;
	};

	// ===========================  CHECKLIST ITEM QUIRIES ===========================
	const addChecklistItemGQL = async (formData) => {
		return null;
	};

	const getChecklistItemGQL = async (id) => {
		return null;
	};
	const getAllChecklistItemsGQL = async () => {
		const data = await gqlClient.request(queryGetAllChecklistItems);
		return data.prepChecklists;
	};

	const updateChecklistItemGQL = async (id, formData) => {
		return null;
	};

	const removeChecklistItemGQL = async (id) => {
		return null;
	};

	return (
		<GqlQueriesContext.Provider
			value={{
				getClientGQL,
				getAllClientsGQL,
				addClientGQL,
				updateClientGQL,
				searchClientsGQL,
				addCaseGQL,
				getCaseGQL,
				getAllCasesGQL,
				updateCaseGQL,
				removeCaseGQL,
				addEventGQL,
				getEventGQL,
				getEventsGQL,
				getTodaysEventsGQL,
				updateEventGQL,
				removeEventGQL,
				addChecklistItemGQL,
				getChecklistItemGQL,
				getAllChecklistItemsGQL,
				updateChecklistItemGQL,
				removeChecklistItemGQL,
				getPendingEventsGQL,
				getDeadlinesAndRemindersGQL,
			}}
		>
			{children}
		</GqlQueriesContext.Provider>
	);
}
