import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import ClientForm from '../../client/components/forms/Client-Form';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
// import { addClient } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function CreateClientPage() {
	const { addClient } = useContext(RestQueriesContext);
	const { selectClient } = useContext(AppContext);
	const history = useHistory();

	const { mutate, isError, error, isLoading } = useMutation((formData) => addClient(formData), {
		onSuccess: (data) => {
			selectClient(data);
			history.push({
				pathname: '/create-case',
				state: { prevURL: '/' },
			});
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;

	return (
		<>
			<Container>
				<h4>Create New Client</h4>

				<ClientForm createClientSubmitFunction={onSubmit} />
			</Container>
		</>
	);
}
