import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import LanguageForm from '../../client/components/forms/Language-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateLanguage, getLanguage } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateLanguagePage() {
	const { updateLanguage, getLanguage } = useContext(RestQueriesContext);
	const { client } = useContext(AppContext);
	const history = useHistory();
	const { langID } = useParams();

	const {
		isLoading,
		isError,
		data: language,
		error,
	} = useQuery(['getLanguage', langID], () => getLanguage(langID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateLanguage(langID, formData), {
		onSuccess: () => {
			history.push('/view-client/' + client.id);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} caption={`Language: [ ${language.language} ]`} />
				<LanguageForm updateLanguageSubmitFunction={onSubmit} language={language} />
			</Container>
		</>
	);
}
