import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { confDeleteFn } from '../../../app/utils/confirm-delete';
import { Button, Card } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
// import NotSet from '../../../app/components/NotSet';
// import { removeCaseUpdate } from '../../../api/restQueries';
import { RestQueriesContext } from '../../../api/restQueries';

export default function HistoryUpdatesView() {
	const { removeCaseUpdate } = useContext(RestQueriesContext);
	const { cCase, refetchCase: refetch } = useContext(AppContext);
	const location = useLocation();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeCaseUpdate(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing case history update...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return cCase?.case_history_updates?.length === 0 ? (
		<div className='text-center'>
			<Link
				to={{
					pathname: `/add-case-history-update/${cCase.id}`,
					state: { prevURL: location.pathname },
				}}
			>
				+ Update
			</Link>
		</div>
	) : (
		<>
			{cCase?.case_history_updates?.map((update) => (
				<Card key={update.id} className='mb-2 mx-auto' style={{ width: '80%' }}>
					<Card.Body>
						<Card.Title>
							{update.title || null}
							{' - '}
							{(update.date && format(new Date(update.date), 'MMMM dd, yyyy')) || null}
						</Card.Title>
						<Card.Text style={{ whiteSpace: 'pre-line' }}>
							{update.note || 'Nothing was entered'}
						</Card.Text>
						<Link
							to={{
								pathname: `/update-case-history-update/${update.id}`,
								state: { prevURL: location.pathname },
							}}
							className='me-5'
						>
							Edit
						</Link>
						<Button variant='link' onClick={() => confDeleteFn(update.id, mutate, 'Update')}>
							Remove
						</Button>
					</Card.Body>
				</Card>
			))}

			<div className='text-center'>
				<Link
					to={{
						pathname: `/add-case-history-update/${cCase?.id}`,
						state: { prevURL: location.pathname },
					}}
				>
					+ Update
				</Link>
			</div>
		</>
	);
}
