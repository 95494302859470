import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import FamilyMemberForm from '../../client/components/forms/Family-Member-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateFamilyMember, getFamilyMember } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateFamilyMemberPage() {
	const { updateFamilyMember, getFamilyMember } = useContext(RestQueriesContext);
	const { client } = useContext(AppContext);
	const history = useHistory();
	const { famMemberID } = useParams();

	const {
		isLoading,
		isError,
		data: familyMember,
		error,
	} = useQuery(['getFamilyMember', famMemberID], () => getFamilyMember(famMemberID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateFamilyMember(famMemberID, formData), {
		onSuccess: () => {
			history.push('/view-client/' + client.id);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle
					client={client}
					caption={`${familyMember.category}: [ ${familyMember.firstName} ${familyMember.lastName} ]`}
				/>
				<FamilyMemberForm updateFamilyMemberSubmitFunction={onSubmit} familyMember={familyMember} />
			</Container>
		</>
	);
}
