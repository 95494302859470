import { Card } from 'react-bootstrap';

export default function CardShell({ children, header, textAlignment, headerSize }) {
	return (
		// <Card className='shadow-sm mb-5'>
		<Card className='mb-4'>
			{/* <Card.Header as='h5'>{header}</Card.Header> */}
			<Card.Header as={headerSize || 'h5'} className='text-center'>
				{header}
			</Card.Header>
			<Card.Body className={textAlignment}>{children}</Card.Body>
		</Card>
	);
}
