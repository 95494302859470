import React, { useState, createContext } from 'react';

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
	const [client, setClient] = useState(null);
	const [cCase, setCase] = useState(null);
	const [event, setEvent] = useState(null);
	const [element, setElement] = useState(null);
	const [refetchClient, setRefetchClient] = useState(null);
	const [refetchCase, setRefetchCase] = useState(null);
	const [refetchEvent, setRefetchEvent] = useState(null);
	const [refetchElement, setRefetchElement] = useState(null);
	const [fetchingData, setFetchingData] = useState(false);

	// ==================== METHODS ============================
	const selectClient = (client) => {
		setClient(client);
	};

	const selectCase = (cCase) => {
		setCase(cCase);
	};

	const selectEvent = (event) => {
		setEvent(event);
	};

	const selectElement = (element) => {
		setElement(element);
	};

	const refetchClientFn = (refetchClient) => {
		setRefetchClient(() => refetchClient);
	};

	const refetchCaseFn = (refetchCase) => {
		setRefetchCase(() => refetchCase);
	};
	const refetchEventFn = (refetchEvent) => {
		setRefetchEvent(() => refetchEvent);
	};
	const refetchElementFn = (refetchElement) => {
		setRefetchElement(() => refetchElement);
	};

	const fetchingDataStatusFn = (isFetching) => {
		setFetchingData(isFetching);
	};

	// ==================== METHODS ============================

	return (
		<AppContext.Provider
			value={{
				client,
				cCase,
				event,
				element,
				refetchClient,
				refetchCase,
				refetchEvent,
				refetchElement,
				selectClient,
				selectCase,
				selectEvent,
				selectElement,
				fetchingData,
				refetchClientFn,
				refetchCaseFn,
				refetchEventFn,
				refetchElementFn,
				fetchingDataStatusFn,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContextProvider;
