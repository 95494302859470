import { Container, Row, Col } from 'react-bootstrap';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import CasesView from './Cases-View';
import CaseView from './Case-View';

// Nested Routes - List of Cases => choose one => Case details

// export default function ClientCasesView({ client }) {
export default function ClientCasesView() {
	const { path, url } = useRouteMatch();

	return (
		// <Container fluid>
		// 	<Row>
		// 		<Col lg='3'>
		// 			<CasesView client={client} url={url} />
		// 		</Col>
		// 		<Col>
		// 			<Switch>
		// 				<Route path={`${path}/:caseID`}>
		// 					<CaseView />
		// 				</Route>
		// 			</Switch>
		// 		</Col>
		// 	</Row>
		// </Container>

		<Container fluid>
			<Row>
				<Col>
					{/* <CasesView client={client} url={url} /> */}
					<CasesView url={url} />

					<Switch>
						<Route path={`${path}/:caseID`}>
							{/* <CaseView client={client} /> */}
							<CaseView />
						</Route>
					</Switch>
				</Col>
			</Row>
		</Container>
	);
}
