import { Table, ListGroup } from 'react-bootstrap';

export default function DashboardEventsFilters({
	filingDeadlines,
	filingNoDeadlines,
	hearings,
	interviews,
	appointments,
	reminders,
	filterDeadlines,
	filterHearings,
	filterInterviews,
	filterAppointments,
	filterReminders,
	allEvents,
	showAllEvents,
	removeInactiveEvents,
	inactiveEvents,
	toggleEvents,
}) {
	return (
		<>
			<div className='d-none d-md-block'>
				<Table id='dashboard-events-filter-table'>
					<tbody>
						<tr>
							<td className='table-primary' onClick={showAllEvents}>
								All Events ({allEvents?.length})
							</td>
							{(filingDeadlines?.length > 0 || filingNoDeadlines?.length > 0) && (
								<td className='table-warning' onClick={filterDeadlines}>
									Deadlines ({filingDeadlines?.length + filingNoDeadlines?.length})
								</td>
							)}
							{hearings?.length > 0 && (
								<td className='table-danger' onClick={filterHearings}>
									Hearings ({hearings?.length})
								</td>
							)}
							{interviews?.length > 0 && (
								<td className='table-info' onClick={filterInterviews}>
									Interviews ({interviews?.length})
								</td>
							)}

							{appointments?.length > 0 && (
								<td className='table-success' onClick={filterAppointments}>
									Appointments ({appointments?.length})
								</td>
							)}

							{reminders?.length > 0 && (
								<td className='table-light' onClick={filterReminders}>
									Reminders ({reminders?.length})
								</td>
							)}
						</tr>
					</tbody>
				</Table>
			</div>

			<div className='d-block d-md-none mb-2'>
				<ListGroup id='dashboard-events-filter-list'>
					<ListGroup.Item variant='primary' onClick={showAllEvents}>
						All Events ({allEvents?.length})
					</ListGroup.Item>

					{(filingDeadlines?.length > 0 || filingNoDeadlines?.length > 0) && (
						<ListGroup.Item variant='warning' onClick={filterDeadlines}>
							Deadlines ({filingDeadlines?.length + filingNoDeadlines?.length})
						</ListGroup.Item>
					)}

					{hearings?.length > 0 && (
						<ListGroup.Item variant='danger' onClick={filterHearings}>
							Hearings ({hearings?.length})
						</ListGroup.Item>
					)}

					{interviews?.length > 0 && (
						<ListGroup.Item variant='info' onClick={filterInterviews}>
							Interviews ({interviews?.length})
						</ListGroup.Item>
					)}

					{appointments?.length > 0 && (
						<ListGroup.Item variant='success' onClick={filterAppointments}>
							Appointments ({appointments?.length})
						</ListGroup.Item>
					)}

					{reminders?.length > 0 && (
						<ListGroup.Item variant='light' onClick={filterReminders}>
							Reminders ({reminders?.length})
						</ListGroup.Item>
					)}
				</ListGroup>
			</div>
		</>
	);
}
