import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';
import { format } from 'date-fns';

export default function EventStatusUpdateForm({
	addEventSubmitFunction,
	updateEventSubmitFunction,
	event,
	category,
	caseID,
}) {
	const [newEvent, setNewEvent] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			originalDateTime: null,
			updateDateTime: null,
			hearingStatus: null,
			interviewStatus: null,
			filingStatus: null,
			note: event?.note || null,
		},
	});

	useEffect(() => {
		event &&
			reset({ ...event, originalDateTime: null, updateDateTime: format(new Date(), 'yyyy-MM-dd') });
	}, [reset, event]);

	const onSubmit = (formData) => {
		if (newEvent) {
			addEventSubmitFunction({
				...event,
				originalDateTime: formData.originalDateTime,

				cases: [caseID],
				category: category,
				status: 'scheduled',
				note: 'This event has been rescheduled previously',
			});
			updateEventSubmitFunction({
				...event,
				status: formData.status,
				updateDateTime: formData.updateDateTime + 'T00:00',
				note: formData.note,
			});
		} else {
			updateEventSubmitFunction({
				...event,
				status: formData.status,
				updateDateTime: formData.updateDateTime + 'T00:00',
				note: formData.note,
			});
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				{category === 'Hearing' && (
					<div className='form-floating mb-3'>
						<select
							className='form-select'
							{...register('status')}
							id='hearingStatus'
							aria-label='Hearing Status'
							onChange={(e) =>
								e.target.value === 'continued' || e.target.value === 're-scheduled'
									? setNewEvent(true)
									: setNewEvent(false)
							}
							required
						>
							<option value=''></option>
							<option value='occurred'>occurred</option>
							<option value='continued'>continued</option>
							<option value='re-scheduled'>re-scheduled</option>
							<option value='off-calendar'>off-calendar</option>
							<option value='venue changed'>venue changed</option>
							<option value='vacated'>vacated</option>
							<option value='canceled'>canceled</option>
						</select>
						<label htmlFor='hearingStatus'>Select Hearing Status</label>
					</div>
				)}

				{category === 'Interview' && (
					<div className='form-floating mb-3'>
						<select
							className='form-select'
							{...register('status')}
							id='interviewStatus'
							aria-label='Interview Status'
							onChange={(e) =>
								e.target.value === 'rescheduled' ? setNewEvent(true) : setNewEvent(false)
							}
							required
						>
							<option value=''></option>
							<option value='occurred'>occurred</option>
							<option value='rescheduled'>rescheduled</option>
							<option value='canceled'>canceled</option>
						</select>
						<label htmlFor='interviewStatus'>Select Interview Status</label>
					</div>
				)}

				{(category === 'Filing Deadline' || category === 'Filing (no deadline)') && (
					<div className='form-floating mb-3'>
						<select
							className='form-select'
							{...register('status')}
							id='filingStatus'
							aria-label='Filing Status'
							onChange={(e) =>
								e.target.value === 'rescheduled' ? setNewEvent(true) : setNewEvent(false)
							}
							required
						>
							<option value=''></option>
							<option value='filed'>filed</option>
							<option value='canceled'>canceled</option>
						</select>
						<label htmlFor='filingStatus'>Select Filing Status</label>
					</div>
				)}

				{category === 'Appointment' && (
					<div className='form-floating mb-3'>
						<select
							className='form-select'
							{...register('status')}
							id='appointmentStatus'
							aria-label='Appointment Status'
							onChange={(e) =>
								e.target.value === 'rescheduled' ? setNewEvent(true) : setNewEvent(false)
							}
							required
						>
							<option value=''></option>
							<option value='occurred'>occurred</option>
							<option value='rescheduled'>rescheduled</option>
							<option value='canceled'>canceled</option>
						</select>
						<label htmlFor='appointmentStatus'>Select Appointment Status</label>
					</div>
				)}

				<>
					{newEvent && (
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('originalDateTime')}
								max='2999-12-31T00:00'
								id='dateTime'
								type='datetime-local'
								placeholder='Date & Time'
								required
							/>
							<label htmlFor='originalDateTime'> New Date & Time</label>
						</div>
					)}

					<div className='form-floating mb-3'>
						<input
							className='form-control'
							{...register('updateDateTime')}
							max='2999-12-31'
							id='updateDateTime'
							type='date'
							placeholder='Date & Time'
							required
						/>
						<label htmlFor='updateDateTime'> (this update) Date</label>
					</div>

					<div className='form-floating mb-3'>
						<textarea
							className='form-control'
							{...register('note')}
							id='note'
							type='text'
							placeholder='Note'
							style={{ height: '10rem' }}
						></textarea>
						<label htmlFor='note'>Note</label>
					</div>
				</>
				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
