import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { useEffect } from 'react';
import CaseForm from '../../case/components/forms/Case-Form';
import { Container } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
// import * as api from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function CreateCasePage() {
	const history = useHistory();
	const { client } = useContext(AppContext);
	const { addCase } = useContext(RestQueriesContext);

	useEffect(() => {
		if (!client) {
			history.push('/');
		}
	}, [client, history]);

	const { mutate, isError, error, isLoading } = useMutation((formData) => addCase(formData), {
		onSuccess: (cCase) => {
			history.push({ pathname: `/add-event/${cCase.id}`, state: { category: 'Filing Deadline' } });
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;

	if (isError) <div>An error occurred: {error.message}</div>;

	return (
		<>
			<Container>
				<h4>
					Create a new case for: {client?.firstName} {client?.lastName}
				</h4>
				<CaseForm clientID={client?.id} createCasesubmitFunction={onSubmit} />
			</Container>
		</>
	);
}
