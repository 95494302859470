import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SaveButton from '../../../../app/components/SaveButton';
import CancelButton from '../../../../app/components/CancelButton';
import { elementTypesList } from '../../../../app/components/DataLists';

export default function FilingElementForm({
	addFilingElementSubmitFunction,
	updateFilingElementSubmitFunction,
	eventID,
	element,
}) {
	const location = useLocation();
	const history = useHistory();
	const cancelButtonHandler = () => history.push(location.state.prevURL);

	// const cancelButtonHandler = () =>
	// 	history.push({
	// 		pathname: prevURL,
	// 		state: { client: client, cCase: cCase, event, prevURL: prevURL },
	// 	});

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			type: element?.type || null,
			number: element?.number || null,
			name: element?.name || null,
			description: element?.description || null,
		},
	});

	useEffect(() => {
		element && reset(element);
	}, [reset, element]);

	const onSubmit = (formData) => {
		if (!element) {
			addFilingElementSubmitFunction({
				...formData,
				events: [eventID],
			});
		}
		if (element) {
			updateFilingElementSubmitFunction(formData);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('type')}
						id='type'
						type='text'
						list='elementTypes'
						placeholder='Type'
						required
					/>
					<label htmlFor='type'>Type</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='elementTypes'>
						{elementTypesList.map((type) => (
							<option key={type} value={type} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('number')}
						id='number'
						type='text'
						placeholder='Number'
					/>
					<label htmlFor='number'>Number</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('name')}
						id='name'
						type='text'
						placeholder='Name'
						required
					/>
					<label htmlFor='name'>Name</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('description')}
						id='description'
						type='text'
						placeholder='Description'
					/>
					<label htmlFor='description'>Description</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
