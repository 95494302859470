import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SaveButton from '../../../../app/components/SaveButton';
import CancelButton from '../../../../app/components/CancelButton';
import {
	agenciesList,
	interviewTypesList,
	interviewLocationsList,
} from '../../../../app/components/DataLists';

export default function InterviewForm({
	addEventSubmitFunction,
	updateEventSubmitFunction,
	event,
	category,
	caseID,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			originalDateTime: event?.originalDateTime || null,
			angecy: event?.agency || null,
			type: event?.type || null,
			immigrationJudge: event?.immigrationJudge || null,
			location: event?.location || null,
			regarding: event?.regarding || null,
			note: event?.note || null,
		},
	});

	useEffect(() => {
		event && reset(event);
	}, [reset, event]);

	const onSubmit = (formData) => {
		if (!event) {
			addEventSubmitFunction({
				...formData,
				cases: [caseID],
				category: category,
				status: 'scheduled',
			});
		}
		if (event) {
			updateEventSubmitFunction(formData);
		}
	};

	return (
		<>
			{/* <h3>Interview</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('originalDateTime')}
						max='2999-12-31T00:00'
						id='dateTime'
						type='datetime-local'
						placeholder='Date & Time'
						required
					/>
					<label htmlFor='date'>Date & Time</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						id='agency'
						{...register('agency')}
						list='agencies'
						type='text'
						placeholder='Agency'
					/>
					<label htmlFor='agency'>Agency</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='agencies'>
						{agenciesList.map((agency) => (
							<option key={agency} value={agency} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('type')}
						id='interviewType'
						list='interviewTypes'
						type='text'
						placeholder='Type'
						required
					/>
					<label htmlFor='interviewType'>Type</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='interviewTypes'>
						{interviewTypesList.map((interview) => (
							<option key={interview} value={interview} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('location')}
						id='location'
						list='interviewLocations'
						type='text'
						placeholder='Location'
					/>
					<label htmlFor='location'>Location</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='interviewLocations'>
						{interviewLocationsList.map((location) => (
							<option key={location.city} value={location.city}>
								{location.type}
							</option>
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('regarding')}
						id='regarding'
						type='text'
						placeholder='Regarding'
					/>
					<label htmlFor='regarding'>Regarding</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('note')}
						id='note'
						type='text'
						placeholder='Note'
					/>
					<label htmlFor='note'>Note</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
