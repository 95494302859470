import { useContext, useEffect } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { Row, Col, Card } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import CardShell from '../../app/components/CardShell';
import { useQuery } from 'react-query';
import FilingElementsView from '../components/views/events/Filing-Elements-View';
import FilingPrepChecklistView from '../components/views/events/Filing-Prep-Checklist-View';
import FormTitle from '../../app/components/FormTitle';
// import { getEventGQL } from '../../api/gqlQueries';
import { GqlQueriesContext } from '../../api/gqlQueries';
import { format } from 'date-fns';

export default function FilingPage() {
	const { getEventGQL } = useContext(GqlQueriesContext);
	const { client, cCase, selectEvent, refetchEventFn } = useContext(AppContext);
	const { eventID } = useParams();

	const {
		isLoading,
		isError,
		data: event,
		error,
		refetch: refetchEvent,
	} = useQuery(['getEventGQL', eventID], () => getEventGQL(eventID));

	useEffect(() => {
		if (event) {
			selectEvent(event);
			refetchEventFn(refetchEvent);
		}
		// eslint-disable-next-line
	}, [event, refetchEvent]);

	if (isLoading) return <div>Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;

	return (
		event && (
			<>
				{client && <FormTitle client={client} cCase={cCase} />}
				<Card>
					<Card.Body>
						<h4>Filing Details:</h4>
						<div className='text-center mt-4 mb-5'>
							<h4>{event?.filingTitle}</h4>
							<h4>
								{event?.filingMethod}
								{(event.updateDateTime &&
									' - ' + format(new Date(event.updateDateTime), 'MMM dd, yyyy')) ||
									null}
							</h4>
						</div>
						<Row>
							<Col>
								<CardShell header='Filing Elements' textAlignment='text-center'>
									<FilingElementsView />
								</CardShell>
							</Col>
							<Col>
								<CardShell header='Filing Checklist' textAlignment='text-center'>
									<FilingPrepChecklistView />
								</CardShell>
							</Col>
						</Row>
						<Row>
							<Col>
								{client && cCase && (
									<Link to={`/view-client/${client.id}/${cCase.id}`}>Back to Case</Link>
								)}
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</>
		)
	);
}
