import { useEffect, useContext, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useQuery } from 'react-query';
import { useParams, useLocation, Link } from 'react-router-dom';
import EventHistoryView from './Event-History-View';
import ServicesView from './Services-View';
import NoticeOfAppearanceView from './Notice-Of-Appearance-View';
import QuickNotesView from './Quick-Notes-View';
import HistoryUpdatesView from './History-Updates-View';
import AppointmentsView from './events/Appointments-View';
import ReceivedDocumentsView from './events/Received-Documents-View';
import RemindersView from './events/Reminders-View';
import FilingsView from './events/Filings-View';
import HearingsView from './events/Hearings-View';
import InterviewsView from './events/Interviews-View';
import CardShell from '../../../app/components/CardShell';
import { Row, Col, Table } from 'react-bootstrap';
import { GqlQueriesContext } from '../../../api/gqlQueries';

export default function CaseView() {
	const { getCaseGQL } = useContext(GqlQueriesContext);
	const { selectCase, refetchCaseFn } = useContext(AppContext);
	const [events, setEvents] = useState(null);
	const [filingDeadlines, setFilingDeadlines] = useState();
	const [filingNoDeadlines, setFilingNoDeadlines] = useState();
	const [hearings, setHearings] = useState();
	const [interviews, setInterviews] = useState();
	const [appointments, setAppointments] = useState();
	const [reminders, setReminders] = useState();
	const [receivedDocuments, setReceivedDocuments] = useState();
	const location = useLocation();
	const { caseID } = useParams();

	const {
		isLoading,
		isError,
		data: cCase,
		error,
		refetch: refetchCase,
	} = useQuery(['getCase', caseID], () => getCaseGQL(caseID));

	useEffect(() => {
		if (cCase) {
			selectCase(cCase);
			refetchCaseFn(refetchCase);
			setEvents(cCase.events);
		}
		// eslint-disable-next-line
	}, [cCase, refetchCase]);

	useEffect(() => {
		if (events) {
			setFilingDeadlines(
				events.filter(
					(event) => event.category === 'Filing Deadline' && event.status === 'in-progress'
				)
			);
			setFilingNoDeadlines(
				events.filter(
					(event) => event.category === 'Filing (no deadline)' && event.status === 'in-progress'
				)
			);
			setHearings(
				events.filter((event) => event.category === 'Hearing' && event.status === 'scheduled')
			);
			setInterviews(
				events.filter((event) => event.category === 'Interview' && event.status === 'scheduled')
			);
			setAppointments(events.filter((event) => event.category === 'Appointment'));
			setReminders(
				events.filter((event) => event.category === 'Reminder' && event.status === 'unconfirmed')
			);
			setReceivedDocuments(events.filter((event) => event.category === 'Received Document'));
		}
	}, [events]);

	if (isLoading) return <p>Loading...</p>;

	if (isError) return <p>Error: {error.message}</p>;

	return (
		<>
			<Row className='mt-3 text-center'>
				<Col>
					<Table bordered className='text-center mx-auto'>
						<tbody>
							<tr>
								<td className='table-warning' style={{ width: '16.6%' }}>
									<Link
										to={{
											pathname: `/add-event/${cCase.id}`,
											state: {
												prevURL: location.pathname,
												category: 'Filing Deadline',
												showModal: true,
											},
										}}
									>
										<div className='fw-bold'>Filing Deadlines ({filingDeadlines?.length})</div>+ New
									</Link>
								</td>
								<td className='table-warning' style={{ width: '16.6%' }}>
									<Link
										to={{
											pathname: `/add-event/${cCase.id}`,
											state: { prevURL: location.pathname, category: 'Filing (no deadline)' },
										}}
									>
										<div className='fw-bold'>
											Filing (no deadline) ({filingNoDeadlines?.length})
										</div>
										+ New
									</Link>
								</td>
								<td className='table-danger' style={{ width: '16.6%' }}>
									<Link
										to={{
											pathname: `/add-event/${cCase.id}`,
											state: { prevURL: location.pathname, category: 'Hearing' },
										}}
									>
										<div className='fw-bold'>Hearings ({hearings?.length})</div>+ New
									</Link>
								</td>
								<td className='table-info' style={{ width: '16.6%' }}>
									<Link
										to={{
											pathname: `/add-event/${cCase.id}`,
											state: { prevURL: location.pathname, category: 'Interview' },
										}}
									>
										<div className='fw-bold'>Interviews ({interviews?.length})</div>+ New
									</Link>
								</td>
								<td className='table-success' style={{ width: '16.6%' }}>
									<Link
										to={{
											pathname: `/add-event/${cCase.id}`,
											state: { prevURL: location.pathname, category: 'Appointment' },
										}}
									>
										<div className='fw-bold'>Appointments ({appointments?.length})</div>+ New
									</Link>
								</td>
								<td className='table-light' style={{ width: '16.6%' }}>
									<Link
										to={{
											pathname: `/add-event/${cCase.id}`,
											state: { prevURL: location.pathname, category: 'Reminder' },
										}}
									>
										<div className='fw-bold'>Reminders ({reminders?.length})</div>+ New
									</Link>
								</td>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>

			<Row className='text-center'>
				<Col>
					<Table bordered className='text-center mx-auto'>
						<tbody>
							<tr>
								<td className='table-light' style={{ width: '25%' }}>
									<Link
										to={{
											pathname: `/add-service/${cCase?.id}`,
											state: { prevURL: location.pathname },
										}}
									>
										<div className='fw-bold'>Services ({cCase?.services.length})</div>+ New
									</Link>
								</td>
								<td className='table-light' style={{ width: '25%' }}>
									<Link
										to={{
											pathname: `/add-notice-of-appearance/${cCase.id}`,
											state: { prevURL: location.pathname },
										}}
									>
										<div className='fw-bold'>
											Notice of Appearance ({cCase?.notice_of_appearances.length})
										</div>
										+ New
									</Link>
								</td>
								{/* <td className='table-light' style={{ width: '20%' }}>
									<Link
										to={{
											pathname: `/add-case-quick-note/${cCase.id}`,
											state: { prevURL: location.pathname },
										}}
									>
										<div className='fw-bold'>
											Quick Notes ({cCase?.case_quick_access_notes.length})
										</div>
										+ New
									</Link>
								</td> */}
								<td className='table-light' style={{ width: '25%' }}>
									<Link
										to={{
											pathname: `/add-case-history-update/${cCase.id}`,
											state: { prevURL: location.pathname },
										}}
									>
										<div className='fw-bold'>
											Case Updates ({cCase?.case_history_updates.length})
										</div>
										+ New
									</Link>
								</td>
								<td className='table-light' style={{ width: '25%' }}>
									<Link
										to={{
											pathname: `/add-event/${cCase.id}`,
											state: { prevURL: location.pathname, category: 'Received Document' },
										}}
									>
										<div className='fw-bold'>Received Documents ({receivedDocuments?.length})</div>+
										New
									</Link>
								</td>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>

			<Row className='row-styled'>
				<Col>
					{(filingDeadlines?.length > 0 || filingNoDeadlines?.length > 0) && (
						<CardShell header='Filings' textAlignment='text-center'>
							<FilingsView />
						</CardShell>
					)}

					{hearings?.length > 0 && (
						<CardShell header='Hearings' textAlignment='text-center'>
							<HearingsView />
						</CardShell>
					)}

					{interviews?.length > 0 && (
						<CardShell header='Interviews' textAlignment='text-center'>
							<InterviewsView />
						</CardShell>
					)}

					{appointments?.length > 0 && (
						<CardShell header='Appointments' textAlignment='text-centerx'>
							<AppointmentsView />
						</CardShell>
					)}

					{reminders?.length > 0 && (
						<CardShell header='Reminders' textAlignment='text-center'>
							<RemindersView />
						</CardShell>
					)}

					{cCase?.services.length > 0 && (
						<CardShell header='Services' textAlignment='text-center'>
							<ServicesView />
						</CardShell>
					)}

					{cCase?.notice_of_appearances.length > 0 && (
						<CardShell header='Notice of Appearance' textAlignment='text-center'>
							<NoticeOfAppearanceView />
						</CardShell>
					)}

					{cCase?.case_quick_access_notes.length > 0 && (
						<CardShell header='Quick Notes' textAlignment='text-center'>
							<QuickNotesView />
						</CardShell>
					)}

					{receivedDocuments?.length > 0 && (
						<CardShell header='Received Documents' textAlignment='text-center'>
							<ReceivedDocumentsView />
						</CardShell>
					)}

					{cCase?.case_history_updates.length > 0 && (
						<CardShell header='Case History / Updates' textAlignment=''>
							<HistoryUpdatesView />
						</CardShell>
					)}

					<CardShell header='Events History' textAlignment='text-center'>
						<EventHistoryView />
					</CardShell>
				</Col>
			</Row>
		</>
	);
}
