import { useContext } from 'react';
import { confDeleteFn } from '../../utils/confirm-delete';
import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { format } from 'date-fns';
import { orderBy } from 'lodash';
import CardShell from '../CardShell';
import { RestQueriesContext } from '../../../api/restQueries';

export default function DashboardRemindersView() {
	const { getOfficeReminders, removeOfficeReminder } = useContext(RestQueriesContext);
	const location = useLocation();
	const [orderByDateReminders, setOrderByDateReminders] = useState(null);

	const {
		isLoading,
		isError,
		data: reminders,
		error,
		refetch: refetchReminders,
	} = useQuery(['getOfficeReminders'], () => getOfficeReminders());

	useEffect(() => {
		if (reminders) setOrderByDateReminders(orderBy(reminders, ['date'], ['desc']));
	}, [reminders]);

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeOfficeReminder(id), {
		onSuccess: () => {
			refetchReminders();
		},
	});

	if (isLoading)
		return (
			<CardShell header='Tasks/Reminders' headerSize='h4' textAlignment='text-center'>
				<h5 className='text-center'>Loading ...</h5>
			</CardShell>
		);

	if (isMutationLoading)
		return (
			<CardShell header='Tasks/Reminders' headerSize='h4' textAlignment='text-center'>
				<h5 className='text-center'>Removing event...</h5>
			</CardShell>
		);

	if (isError)
		return (
			<CardShell header='Tasks/Reminders' headerSize='h4' textAlignment='text-center'>
				<h5 className='text-center'>Error loading - {error.message}</h5>
			</CardShell>
		);

	if (isMutationError)
		return (
			<CardShell header='Tasks/Reminders' headerSize='h4' textAlignment='text-center'>
				<h5 className='text-center'>Removing item failed - {mutationError.message}</h5>
			</CardShell>
		);

	return orderByDateReminders?.length === 0 ? (
		<CardShell
			header={`Tasks/Reminders (${orderByDateReminders?.length})`}
			headerSize='h4'
			textAlignment='text-center'
		>
			<Link
				to={{
					pathname: `/add-office-reminder`,
					state: { prevURL: location.pathname },
				}}
			>
				+ Task/Reminder
			</Link>
		</CardShell>
	) : (
		<CardShell
			header={`Tasks/Reminders (${orderByDateReminders?.length})`}
			headerSize='h4'
			textAlignment='text-center'
		>
			<div>
				<Link
					to={{
						pathname: `/add-office-reminder`,
						state: { prevURL: location.pathname },
					}}
				>
					+ Task/Reminder
				</Link>
			</div>

			{orderByDateReminders?.map((reminder) => {
				return (
					<Card key={reminder.id} className='mb-2 mx-auto text-start' style={{ width: '80%' }}>
						<Card.Body>
							<Card.Title>
								{(reminder.date && format(new Date(reminder.date), 'MMMM dd, yyyy')) || null}
							</Card.Title>
							<Card.Text style={{ whiteSpace: 'pre-line' }}>
								{reminder.note || 'Nothing was entered'}
							</Card.Text>
							<Link
								to={{
									pathname: `/update-office-reminder/${reminder.id}`,
									state: { prevURL: location.pathname },
								}}
								className='me-5'
							>
								Edit
							</Link>
							<Button variant='link' onClick={() => confDeleteFn(reminder.id, mutate, 'Reminder')}>
								Remove
							</Button>
						</Card.Body>
					</Card>
				);
			})}

			<div>
				<Link
					to={{
						pathname: `/add-office-reminder`,
						state: { prevURL: location.pathname },
					}}
				>
					+ Task/Reminder
				</Link>
			</div>
		</CardShell>
	);
}
