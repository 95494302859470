import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import NotSet from '../../../../app/components/NotSet';
import { formatANum, formatPhoneNum, confDeleteFn } from '../../../../app/utils/helper-functions';

export default function SiblingsView({ siblings, mutate }) {
	const location = useLocation();
	return siblings?.length === 0 ? null : (
		<>
			<h5>Siblings</h5>
			<Table bordered size='sm' className='text-center'>
				<thead>
					<tr>
						<th>Name</th>
						<th>Phone</th>
						<th>Email</th>
						{/* <th>Address</th> */}
						<th>Relation</th>
						<th>Date of Birth</th>
						<th>Immigration Status</th>
						{/* <th>Status Expiry Date</th> */}
						<th>A-Number</th>
						<th>Role</th>
						{/* <th>Note</th> */}
						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{siblings?.map((sibling) => (
						<tr key={sibling.id}>
							<td>
								{sibling.firstName} {sibling.lastName}
							</td>
							<td>{(sibling.phone && formatPhoneNum(sibling.phone)) || <NotSet />}</td>
							<td>{sibling.email || <NotSet />}</td>
							{/* <td>{sibling.address || <NotSet />}</td> */}
							<td>{sibling.relationToClient || <NotSet />}</td>
							<td>
								{(sibling.dateOfBirth && format(new Date(sibling.dateOfBirth), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td>
							<td>{sibling.immigrationStatus || <NotSet />}</td>
							{/* <td>
								{(sibling.immigrationStatusExpiryDate &&
									format(new Date(sibling.immigrationStatusExpiryDate), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td> */}
							<td>{(sibling.aNumber && formatANum(sibling.aNumber)) || <NotSet />}</td>
							<td>{sibling.role || <NotSet />}</td>
							{/* <td>{sibling.note || <NotSet />}</td> */}
							<td>
								<Link
									to={{
										pathname: `/update-family-member/${sibling.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td>
								{/* <Button variant='link' onClick={() => mutate(sibling.id)}> */}
								<Button variant='link' onClick={() => confDeleteFn(sibling.id, mutate, 'Sibling')}>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
}
