import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { confDeleteFn } from '../../../../app/utils/confirm-delete';
import { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { format, formatDistanceToNow } from 'date-fns';
import { orderBy } from 'lodash';
import NotSet from '../../../../app/components/NotSet';
// import { removeEvent } from '../../../../api/restQueries';
import { RestQueriesContext } from '../../../../api/restQueries';

export default function FilingsView() {
	const { removeEvent } = useContext(RestQueriesContext);
	const { client, cCase, refetchCase: refetch } = useContext(AppContext);
	const [orderByDateEvents, setOrderByDateEvents] = useState(null);
	const [filteredOrderedEvents, setFilteredOrderedEvents] = useState(null);
	const location = useLocation();
	const events = cCase?.events;

	useEffect(() => {
		if (events) setOrderByDateEvents(orderBy(events, ['originalDateTime'], ['asc']));
	}, [events]);

	useEffect(() => {
		if (orderByDateEvents)
			setFilteredOrderedEvents(
				orderByDateEvents.filter(
					(event) =>
						(event.category === 'Filing Deadline' || event.category === 'Filing (no deadline)') &&
						event.updateDateTime === null
				)
			);
	}, [orderByDateEvents]);

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeEvent(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing event...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return filteredOrderedEvents?.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-event/${cCase.id}`,
					state: { prevURL: location.pathname, category: 'Filing Deadline' },
				}}
			>
				+ Filing Deadline
			</Link>
			<span className='mx-3'>|</span>
			<Link
				to={{
					pathname: `/add-event/${cCase.id}`,
					state: { prevURL: location.pathname, category: 'Filing (no deadline)' },
				}}
			>
				+ Filing No Deadline
			</Link>
		</div>
	) : (
		cCase && (
			<>
				<Table bordered size='sm'>
					<thead>
						<tr>
							<th>Count Down</th>
							<th>Date</th>
							<th>Type</th>
							<th>Agency</th>
							<th>Title</th>
							<th>Method</th>
							<th>Elements</th>
							<th>Checklist</th>
							<th>Status</th>
							<th colSpan='2'>Action</th>
						</tr>
					</thead>
					<tbody>
						{filteredOrderedEvents?.map((event) => {
							return (
								<tr key={event.id}>
									<td>
										{formatDistanceToNow(new Date(event.originalDateTime), { addSuffix: true })}
									</td>
									<td>{format(new Date(event.originalDateTime), 'E - MMM dd, yyyy')}</td>
									<td>
										{/* {event.type || null} {event.category || <NotSet />} */}
										{event.category === 'Filing Deadline' ? 'Deadline' : 'No deadline'}
									</td>
									<td>
										{event.agency || <NotSet />} - {event.location || null}
									</td>
									<td>{event.filingTitle || <NotSet />}</td>
									<td>{event.filingMethod || <NotSet />}</td>
									<td>
										{event.elements?.length !== 0 ? (
											<Link
												to={{
													pathname: `/filing-page/${event.id}`,
													state: {
														prevURL: location.pathname,
													},
												}}
											>
												{/* {element.type} - {element.number} */}
												{`View (${event.elements?.length})`}
											</Link>
										) : (
											<Link
												to={{
													pathname: `/add-filing-element/${event.id}`,
													state: {
														prevURL: location.pathname,
													},
												}}
											>
												+ Element
											</Link>
										)}
									</td>
									<td>
										{event.prep_checklists?.length !== 0 ? (
											<Link
												to={{
													pathname: `/filing-page/${event.id}`,
													state: {
														prevURL: location.pathname,
													},
												}}
											>
												{/* {element.type} - {element.number} */}
												{`View (${event.prep_checklists?.length})`}
											</Link>
										) : (
											<Link
												to={{
													pathname: `/add-checklist-item/${event.id}`,
													state: {
														prevURL: location.pathname,
													},
												}}
											>
												+ Item
											</Link>
										)}
									</td>
									<td>
										<Link
											to={{
												pathname: `/update-event-status/${event.id}`,
												state: {
													prevURL: location.pathname,
													category: event.category,
												},
											}}
										>
											{event.status}
										</Link>
									</td>

									<td>
										<Link
											to={{
												pathname: `/update-event/${event.id}`,
												state: {
													prevURL: location.pathname,
													category: event.category,
													caseID: cCase.id,
													clientID: client.id,
												},
											}}
										>
											Edit
										</Link>
									</td>
									<td>
										{/* <Button variant='link' onClick={() => mutate(event.id)}> */}
										<Button
											variant='link'
											onClick={() => confDeleteFn(event.id, mutate, event.category)}
										>
											Remove
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
				<div>
					<Link
						to={{
							pathname: `/add-event/${cCase.id}`,
							state: { prevURL: location.pathname, category: 'Filing Deadline' },
						}}
					>
						+ Filing Deadline
					</Link>
					<span className='mx-3'>|</span>
					<Link
						to={{
							pathname: `/add-event/${cCase.id}`,
							state: { prevURL: location.pathname, category: 'Filing (no deadline)' },
						}}
					>
						+ Filing No Deadline
					</Link>
				</div>
			</>
		)
	);
}
