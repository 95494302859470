import { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { Table, ListGroup, Spinner, Row, Col } from 'react-bootstrap';
import { format, formatDistanceToNow, add } from 'date-fns';
// import NotSet from '../NotSet';
import CardShell from '../CardShell';
import DashbaordEventsButtons from '../Dashbaord-Events-Buttons';
import { GqlQueriesContext } from '../../../api/gqlQueries';
import DashboardEventsFilters from './Dashboard-Events-Filters';
// import { formatANum } from '../../utils/helper-functions';

export default function DashboardDeadlinesRemindersView() {
	const { getDeadlinesAndRemindersGQL } = useContext(GqlQueriesContext);
	const location = useLocation();
	const [activeEvents, setActiveEvents] = useState(null);
	const [allEvents, setAllEvents] = useState(null);
	const [filingDeadlines, setFilingDeadlines] = useState();
	const [filingNoDeadlines, setFilingNoDeadlines] = useState();
	// const [hearings, setHearings] = useState();
	// const [interviews, setInterviews] = useState();
	// const [appointments, setAppointments] = useState();
	const [reminders, setReminders] = useState();
	const [groupedEvents, setGroupedEvents] = useState();
	const [currentDate, setCurrentDate] = useState(new Date());
	const [thisMonth, setThisMonth] = useState(format(new Date(), 'yyyy-MM'));
	const headerSize = 'h4';
	const [activeEventsOnly, setActiveEventsOnly] = useState(true);
	const [toggleButtonText, setToggleButtonText] = useState('Show completed events');

	const {
		isLoading,
		isError,
		data: events,
		error,
		isFetching,
		refetch,
	} = useQuery(['getDeadlinesAndRemindersGQL', thisMonth], () =>
		getDeadlinesAndRemindersGQL(thisMonth, activeEventsOnly)
	);

	useEffect(() => {
		if (events) {
			// set AllEvents & activeEvents = events
			setAllEvents(events);
			setActiveEvents(events);
		}
	}, [events]);

	useEffect(() => {
		if (activeEvents) {
			// Group events by event date in a NEw Array (groupedEvents)
			//**************************************************** */
			const groups = activeEvents.reduce((groups, event) => {
				const date = event.originalDateTime.split('T')[0].concat('T08:00');
				if (!groups[date]) {
					groups[date] = [];
				}
				groups[date].push(event);
				return groups;
			}, {});

			setGroupedEvents(
				Object.keys(groups).map((date) => {
					return {
						date,
						events: groups[date],
					};
				})
			);
			//**************************************************** */

			// Create a new group of events based on CATEGORY
			setFilingDeadlines(
				activeEvents.filter(
					(event) => event.category === 'Filing Deadline' && event.status === 'in-progress'
				)
			);
			setFilingNoDeadlines(
				activeEvents.filter((event) => event.category === 'Filing (no deadline)')
			);
			// setHearings(
			// 	activeEvents.filter((event) => event.category === 'Hearing' && event.status === 'scheduled')
			// );
			// setInterviews(
			// 	activeEvents.filter(
			// 		(event) => event.category === 'Interview' && event.status === 'scheduled'
			// 	)
			// );
			// setAppointments(
			// 	activeEvents.filter(
			// 		(event) => event.category === 'Appointment' && event.status === 'scheduled'
			// 	)
			// );
			setReminders(
				activeEvents.filter(
					(event) => event.category === 'Reminder' && event.status === 'unconfirmed'
				)
			);
		}
	}, [activeEvents]);

	useEffect(() => {
		refetch();

		// eslint-disable-next-line
	}, [activeEventsOnly]);

	const addMonth = () => {
		setThisMonth(format(add(currentDate, { months: 1 }), 'yyyy-MM'));
		setCurrentDate((currentDate) => add(currentDate, { months: 1 }));
	};

	const subMonth = () => {
		setThisMonth(format(add(currentDate, { months: -1 }), 'yyyy-MM'));
		setCurrentDate((currentDate) => add(currentDate, { months: -1 }));
	};

	const currentMonth = () => {
		setThisMonth(format(new Date(), 'yyyy-MM'));
		setCurrentDate(new Date());
	};

	const toggleEvents = () => {
		setActiveEventsOnly(!activeEventsOnly);
		setToggleButtonText(
			toggleButtonText === 'Show completed events'
				? 'Hide completed events'
				: 'Show completed events'
		);
	};

	const filterDeadlines = () => {
		setActiveEvents(
			events.filter(
				(event) => event.category === 'Filing Deadline' || event.category === 'Filing (no deadline)'
			)
		);
	};

	// const filterHearings = () => {
	// 	setActiveEvents(events.filter((event) => event.category === 'Hearing'));
	// };

	// const filterInterviews = () => {
	// 	setActiveEvents(events.filter((event) => event.category === 'Interview'));
	// };

	// const filterAppointments = () => {
	// 	setActiveEvents(events.filter((event) => event.category === 'Appointment'));
	// };

	const filterReminders = () => {
		setActiveEvents(events.filter((event) => event.category === 'Reminder'));
	};

	const showAllEvents = () => {
		setActiveEvents(allEvents);
	};

	// if (isLoading)
	// 	return (
	// 		<CardShell header={format(currentDate, 'MMMM yyyy')} headerSize={headerSize}>
	// 			<h5 className='text-center'>Loading a month's events ...</h5>
	// 		</CardShell>
	// 	);

	if (isError)
		return (
			<CardShell header={format(currentDate, 'MMMM yyyy')} headerSize={headerSize}>
				<h5 className='text-center'>Loading events failed - {error.message}</h5>
			</CardShell>
		);

	return (
		<CardShell
			// header={`${format(currentDate, 'MMMM yyyy')} - [${events?.length}]`}
			header={format(currentDate, 'MMMM yyyy')}
			headerSize={headerSize}
		>
			<Row>
				<Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }}>
					<DashbaordEventsButtons
						currentDate={currentDate}
						addMonth={addMonth}
						subMonth={subMonth}
						currentMonth={currentMonth}
						toggleEvents={toggleEvents}
						toggleButtonText={toggleButtonText}
					/>
				</Col>
				<Col>{isFetching || isLoading ? <Spinner animation='grow' variant='primary' /> : ''}</Col>
			</Row>

			{activeEvents?.length === 0 ? (
				<div className='text-center'>
					<h5>No Events Found</h5>
				</div>
			) : (
				<>
					<DashboardEventsFilters
						filingDeadlines={filingDeadlines}
						filingNoDeadlines={filingNoDeadlines}
						// hearings={hearings}
						// interviews={interviews}
						// appointments={appointments}
						reminders={reminders}
						filterDeadlines={filterDeadlines}
						// filterHearings={filterHearings}
						// filterInterviews={filterInterviews}
						// filterAppointments={filterAppointments}
						filterReminders={filterReminders}
						events={events}
						allEvents={allEvents}
						showAllEvents={showAllEvents}
						toggleEvents={toggleEvents}
						isFetching={isFetching}
						isLoading={isLoading}
					/>

					<div className='d-block d-md-none mobile-dashboard-events-view'>
						{groupedEvents?.map((group, i) => (
							<div key={i}>
								<h5 className='text-start mb-3 mt-5'>
									{format(new Date(group.date), 'EEEE - MMM dd')}
									<small className='text-muted fst-italic'>
										{' ('}
										{formatDistanceToNow(new Date(group.date), { addSuffix: true })}
										{')'}
									</small>
								</h5>

								<ListGroup>
									{group.events?.map((event) => (
										<ListGroup.Item
											key={event.id}
											variant={
												(event.category === 'Filing Deadline' && event.status === 'in-progress'
													? 'warning'
													: undefined) ||
												(event.category === 'Filing (no deadline)' && event.status === 'in-progress'
													? 'warning'
													: undefined) ||
												// (event.category === 'Hearing' && event.status === 'scheduled'
												// 	? 'danger'
												// 	: undefined) ||
												// (event.category === 'Interview' && event.status === 'scheduled'
												// 	? 'info'
												// 	: undefined) ||
												// (event.category === 'Appointment' && event.status === 'scheduled'
												// 	? 'success'
												// 	: undefined) ||
												(event.category === 'Reminder' && event.status === 'unconfirmed'
													? 'light'
													: undefined) ||
												'secondary'
											}
										>
											<div style={{ fontWeight: 'bold' }}>
												{event.category === 'Filing Deadline' ||
												event.category === 'Filing (no deadline)' ||
												event.category === 'Reminder'
													? null
													: format(new Date(event.originalDateTime), 'hh:mm a')}
											</div>
											{/* <span
												className={
													(event.type === 'Individual' ? 'individual' : undefined) ||
													(event.type === 'Master' ? 'master' : undefined) ||
													(event.type === 'Criminal' ? 'criminal' : undefined) ||
													''
												}
											> */}{' '}
											{event.type}
											{/* </span>{' '} */}
											{event.category} {'-'}{' '}
											<Link to={`/view-client/${event.cases[0].clients[0].id}`}>
												{event.cases[0].clients[0].lastName}, {event.cases[0].clients[0].firstName}
											</Link>
											{/* {(event.immigrationJudge && ' - IJ: ' + event.immigrationJudge) || null} */}
											{((event.category === 'Filing Deadline' || event.category === 'Reminder') &&
												' - ' + event.regarding) ||
												null}
											{(event.location && ' - ' + event.location) || null}
										</ListGroup.Item>
									))}
								</ListGroup>
							</div>
						))}
					</div>

					<div className='d-none d-md-block'>
						{groupedEvents?.map((group, i) => (
							<div key={i}>
								<h3 className='text-start mb-3 mt-5'>
									{format(new Date(group.date), 'EEEE - MMM dd')}
									<small className='text-muted fst-italic'>
										{' ('}
										{formatDistanceToNow(new Date(group.date), { addSuffix: true })}
										{')'}
									</small>
								</h3>

								<Table bordered id='dashboard-events-table'>
									{/* <thead>
								<tr>
									<th>Time</th>
									<th>Event</th>
									<th>Client Name</th>
									<th>A#</th>
									<th>Phone</th>
									<th>Regarding</th>
									<th>Location</th>
									<th>Immigration Judge</th>
									<th>Status</th>
									<th>Action</th>
								</tr>
							</thead> */}
									<tbody>
										{group.events?.map((event) => (
											<tr
												key={event.id}
												className={
													(event.category === 'Filing Deadline' && event.status === 'in-progress'
														? 'table-warning'
														: undefined) ||
													(event.category === 'Filing (no deadline)' &&
													event.status === 'in-progress'
														? 'table-warning'
														: undefined) ||
													// (event.category === 'Hearing' && event.status === 'scheduled'
													// 	? 'table-danger'
													// 	: undefined) ||
													// (event.category === 'Interview' && event.status === 'scheduled'
													// 	? 'table-info'
													// 	: undefined) ||
													// (event.category === 'Appointment' && event.status === 'scheduled'
													// 	? 'table-success'
													// 	: undefined) ||
													(event.category === 'Reminder' && event.status === 'unconfirmed'
														? 'table-light'
														: undefined) ||
													'table-secondary'
												}
											>
												{/* <td style={{ width: '5%', fontWeight: 'bold', fontSize: '1.1rem' }}>
													{event.category === 'Filing Deadline' ||
													event.category === 'Filing (no deadline)'
														? 'n/a'
														: format(new Date(event.originalDateTime), 'hh:mm a')}
												</td> */}
												<td style={{ width: '3%' }}>
													{/* <span
														className={
															(event.type === 'Individual' ? 'individual' : undefined) ||
															(event.type === 'Master' ? 'master' : undefined) ||
															(event.type === 'Criminal' ? 'criminal' : undefined) ||
															''
														}
													> */}
													{/* {event.type} */}
													{/* </span>{' '} */}
													{event.category === 'Filing Deadline' ? 'Deadline' : event.category}
												</td>
												<td
													className='copy'
													onClick={() =>
														navigator.clipboard.writeText(
															event.cases[0].clients[0].lastName +
																', ' +
																event.cases[0].clients[0].firstName
														)
													}
													style={{ width: '13%' }}
												>
													<Link to={`/view-client/${event.cases[0].clients[0].id}`}>
														{event.cases[0].clients[0].lastName},{' '}
														{event.cases[0].clients[0].firstName}
													</Link>
												</td>
												{/* <td
													className='copy'
													onClick={() =>
														navigator.clipboard.writeText(event.cases[0].clients[0].aNumber)
													}
													style={{ width: '7%' }}
												>
													{(event.cases[0].clients[0].aNumber &&
														'A' + formatANum(event.cases[0].clients[0].aNumber)) || (
														<NotSet a='A# ' />
													)}
												</td> */}
												{/* <td
													className='copy'
													onClick={() =>
														navigator.clipboard.writeText(event.cases[0].clients[0].phone_1)
													}
													style={{ width: '7%' }}
												>
													{event.cases[0].clients[0].phone_1 &&
														formatPhoneNum(event.cases[0].clients[0].phone_1)}
												</td> */}

												{/* <td style={{ width: '28%' }}>
											{event.status === 'in-progress' ||
											event.status === 'scheduled' ||
											event.status === 'unconfirmed'
												? event.regarding || (
														<span className='text-muted fst-italic'>(Regarding)</span>
												  )
												: event.regarding
												? event.regarding + ' - UPDATE: ' + event.note || (
														<span className='text-muted fst-italic'>
															(No case update available)
														</span>
												  )
												: 'UPDATE: ' + event.note || (
														<span className='text-muted fst-italic'>
															(No case update available)
														</span>
												  )}
										</td> */}

												<td style={{ width: '28%' }}>
													{event.status === 'in-progress' ||
													event.status === 'scheduled' ||
													event.status === 'unconfirmed' ? (
														<>
															<div>
																{event.regarding || (
																	<span className='text-muted fst-italic'>(Regarding)</span>
																)}
															</div>

															<div>
																{(event.filingMethod && (
																	<i>*Filing method: {event.filingMethod}*</i>
																)) ||
																	''}
															</div>
														</>
													) : event.regarding && event.note ? (
														event.regarding + ' - UPDATE: ' + event.note
													) : event.regarding && event.note === null ? (
														event.regarding + ' - (no case update)'
													) : event.note && event.regarding === null ? (
														'UPDATE: ' + event.note
													) : (
														<span className='text-muted fst-italic'>
															(No case update available)
														</span>
													)}
												</td>

												{/* <td style={{ width: '10%' }}>
													{event.location || (
														<span className='text-muted fst-italic'>(Location)</span>
													)}
												</td> */}
												{/* <td style={{ width: '10%' }}>
													{(event.immigrationJudge && 'IJ: ' + event.immigrationJudge) || (
														<span className='text-muted fst-italic'>(IJ)</span>
													)}
												</td> */}
												{/* <td className='text-center' style={{ width: '5%' }}>
													{event.status}
												</td> */}
												<td className='text-center' style={{ width: '3%' }}>
													<Link
														to={{
															pathname: `/update-event/${event.id}`,
															state: {
																prevURL: location.pathname,
																category: event.category,
																clientID: event.cases[0].clients[0].id,
																caseID: event.cases[0].id,
															},
														}}
													>
														Edit
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						))}
					</div>
				</>
			)}
		</CardShell>
	);
}
