import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import ServiceForm from '../components/forms/Service-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { addService, getCase } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function AddServicePage() {
	const { addService, getCase } = useContext(RestQueriesContext);
	const { client } = useContext(AppContext);
	const history = useHistory();
	const { caseID } = useParams();

	const {
		isLoading,
		isError,
		data: cCase,
		error,
	} = useQuery(['getCase', caseID], () => getCase(caseID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => addService(formData), {
		onSuccess: (data) => {
			history.push({
				// pathname: '/view-client/' + client.id,
				pathname: `/view-client/${client.id}/${cCase.id}`,
				state: data,
			});
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;
	return (
		<Container>
			<FormTitle client={client} cCase={cCase} caption='Add Service' />
			<ServiceForm addServiceSubmitFunction={onSubmit} caseID={cCase.id} />
		</Container>
	);
}
