import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SaveButton from '../../../../app/components/SaveButton';
import CancelButton from '../../../../app/components/CancelButton';
import { appointmentTypesList } from '../../../../app/components/DataLists';

export default function AppointmentForm({
	addEventSubmitFunction,
	updateEventSubmitFunction,
	event,
	category,
	caseID,
}) {
	// Must receive [category] and add it to the formData
	// Must add [status] to formData before submitting the form
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			originalDateTime: event?.originalDateTime || null,
			type: event?.type || 'Office',
			note: event?.note || null,
			regarding: event?.regarding || null,
		},
	});

	useEffect(() => {
		event && reset(event);
	}, [reset, event]);

	const onSubmit = (formData) => {
		if (!event) {
			addEventSubmitFunction({
				...formData,
				cases: [caseID],
				category: category,
				status: 'scheduled',
			});
		}
		if (event) {
			updateEventSubmitFunction(formData);
		}
	};

	return (
		<>
			{event && <p>Status: {event.status}</p>}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col>
						{' '}
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('originalDateTime')}
								id='originalDateTime'
								type='datetime-local'
								max='2999-12-31T00:00'
								placeholder='Date & Time'
								required
							/>
							<label htmlFor='originalDateTime'>Date & Time</label>
						</div>
					</Col>
					<Col>
						{' '}
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('type')}
								id='type'
								type='text'
								list='appointmentsTypes'
								placeholder='Type'
							/>
							<label htmlFor='type'>Type</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='appointmentsTypes'>
								{appointmentTypesList.map((apptType) => (
									<option key={apptType} value={apptType} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
				</Row>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('regarding')}
						id='regarding'
						type='text'
						placeholder='Regarding'
					/>
					<label htmlFor='regarding'>Regarding</label>
				</div>

				<div className='form-floating mb-3'>
					<textarea
						className='form-control'
						{...register('note')}
						id='note'
						type='text'
						placeholder='Note'
						style={{ height: '10rem' }}
					></textarea>
					<label htmlFor='note'>Note</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
