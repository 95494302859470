import { useContext } from 'react';
import DashbordReminderForm from '../components/forms/Dashboard-Reminder-Form';
import { Container } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
// import { addOfficeReminder } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function AddOfficeReminderPage() {
	const { addOfficeReminder } = useContext(RestQueriesContext);
	const history = useHistory();

	const { mutate, isError, error, isLoading } = useMutation(
		(formData) => addOfficeReminder(formData),
		{
			onSuccess: () => {
				history.push('/');
			},
		}
	);

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;

	return (
		<>
			<Container>
				<h4>Add Task/Reminder</h4>

				<DashbordReminderForm addReminderSubmitFunction={onSubmit} />
			</Container>
		</>
	);
}
