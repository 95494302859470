// import { format } from 'date-fns';
import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';

export default function DashbaordEventsButtons({
	addMonth,
	subMonth,
	currentMonth,
	toggleEvents,
	toggleButtonText,
}) {
	return (
		<>
			<Row className=''>
				<Col className='text-center'>
					<ButtonGroup>
						<Button variant='outline-primary' onClick={subMonth}>
							Prev
						</Button>
						<Button variant='outline-primary' onClick={currentMonth}>
							This Month
						</Button>
						<Button variant='outline-primary' onClick={addMonth}>
							Next
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
			<Row className='my-3'>
				<Col className='text-center'>
					<Button variant='outline-primary' onClick={toggleEvents}>
						{toggleButtonText}
					</Button>
				</Col>
			</Row>
		</>
	);
}
