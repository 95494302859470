import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SaveButton from '../../../../app/components/SaveButton';
import CancelButton from '../../../../app/components/CancelButton';

export default function ReminderForm({
	addEventSubmitFunction,
	updateEventSubmitFunction,
	event,
	category,
	caseID,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			originalDateTime: event?.originalDateTime || null,
			regarding: event?.regarding || null,
			note: event?.note || null,
		},
	});

	useEffect(() => {
		event && reset(event);
	}, [reset, event]);

	const onSubmit = (formData) => {
		if (!event) {
			addEventSubmitFunction({
				...formData,
				cases: [caseID],
				category: category,
				status: 'unconfirmed',
			});
		}
		if (event) {
			updateEventSubmitFunction(formData);
		}
	};

	return (
		<>
			{/* <h3>Reminder</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('originalDateTime')}
								max='2999-12-31T00:00'
								id='date'
								type='dateTime-local'
								placeholder='Date'
							/>
							<label htmlFor='date'>Date & Time</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('regarding')}
								id='regarding'
								type='text'
								placeholder='Regarding'
								required
							/>
							<label htmlFor='regarding'>Regarding</label>
						</div>
					</Col>
				</Row>

				<div className='form-floating mb-3'>
					<textarea
						className='form-control'
						{...register('body')}
						id='body'
						placeholder='Note'
						style={{ height: '10rem' }}
					></textarea>
					<label htmlFor='body'>Note</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
