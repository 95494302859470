import { Container } from 'react-bootstrap';
import NavBar from './app/components/NavBar';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './app/pages/Dashboard';
import CreateClientPage from './client/pages/Create-Client-Page';
// import ViewClientPage from './client/pages/View-Client-Profile-Page';
import UpdateClientPage from './client/pages/Update-Client-Page';
import ViewAllClientsPage from './client/pages/View-All-Clients-Page';
import AddLanguagePage from './client/pages/Add-Language-Page';
import UpdateLanguagePage from './client/pages/Update-Language-Page';
import UpdatePersonalInformationPage from './client/pages/Update-Personal-Information-Page';
import UpdateImmigrationInformationPage from './client/pages/Update-Immigration-Information-Page';
import AddTravelRecordPage from './client/pages/Add-Travel-Record-Page';
import UpdateTravelRecordPage from './client/pages/Update-Travel-Record-Page';
import AddPreviousAddressPage from './client/pages/Add-Previous-Address-Page';
import UpdatePreviousAddressPage from './client/pages/Update-Previous-Address-Page';
import AddPassportPage from './client/pages/Add-Passport-Page';
import UpdatePassportPage from './client/pages/Update-Passport-Page';
import AddClientNotePage from './client/pages/Add-Client-Note-Page';
import UpdateClientNotePage from './client/pages/Update-Client-Note-Page';
import AddFamilyMemberPage from './client/pages/Add-Family-Member-Page';
import UpdateFamilyMemberPage from './client/pages/Update-Family-Member-Page';
import MainClientPage from './client/pages/Main-Client-Page';
import CreateCasePage from './case/pages/Create-Case-Page';
import UpdateCasePage from './case/pages/Update-Case-Page';
import AddEventPage from './case/pages/Add-Event-Page';
import UpdateEventPage from './case/pages/Update-Event-Page';
import UpdateEventStatusPage from './case/pages/Update-Event-Status-Page';
import AddHistoryUpdatePage from './case/pages/Add-History-Update-Page';
import UpdateHistoryUpdatePage from './case/pages/Update-History-Update-Page';
import AddNoticeOfAppearancePage from './case/pages/Add-Notice-Of-Appearance-Page';
import UpdateNoticeOfAppearancePage from './case/pages/Update-Notice-Of-Appearance-Page';
import AddServicePage from './case/pages/Add-Service-Page';
import UpdateServicePage from './case/pages/Update-Service-Page';
import AddQuickNotePage from './case/pages/Add-Quick-Note-Page';
import UpdateQuickNotePage from './case/pages/Update-Quick-Note-Page';
import AddFilingElementPage from './case/pages/Add-Filing-Element-Page';
import UpdateFilingElementPage from './case/pages/Update-Filing-Element-Page';
import AddChecklistItemPage from './case/pages/Add-Checklist-Item-Page';
import UpdateChecklistItemPage from './case/pages/Update-Checklist-Item-Page';
import AddSupportingDocumentPage from './case/pages/Add-Supporting-Document-Page';
import UpdateSupportingDocumentPage from './case/pages/Update-Supporting-Document-Page';
import FilingPage from './case/pages/Filing-Page';
import SupportingDocumentsPage from './case/pages/Supporting-Documents-Page';
import SearchResultesPage from './app/pages/Search-Resultes-Page';
import AddOfficeReminderPage from './app/pages/Add-Office-Reminder-Page';
import UpdateOfficeReminderPage from './app/pages/Update-Office-Reminder-Page';
import LoginPage from './app/pages/Login-Page';
import PrivateRoute from './app/auth/Private-Route';
// import { useAuth } from './app/auth/use-auth';
import { AuthContext } from './contexts/AuthContext';
// import { AppContext } from './contexts/AppContext';
import { useContext } from 'react';

function App() {
	// const auth = useAuth();
	const { user } = useContext(AuthContext);
	// const { fetchingData } = useContext(AppContext);

	return (
		<>
			{/* <Router> */}
			{user && <NavBar />}
			<Container fluid className='pt-3'>
				{/* {fetchingData ? (
					<>
						<Spinner animation='grow' variant='primary' size='sm' />
						<Spinner animation='grow' variant='primary' size='sm' />
						<Spinner animation='grow' variant='primary' size='sm' />
					</>
				) : (
					''
				)} */}
				<Switch>
					{/* <Route path='/' exact>
							<Dashboard />
						</Route> */}
					<PrivateRoute path='/' exact>
						<Dashboard />
					</PrivateRoute>
					<PrivateRoute path='/create-client'>
						<CreateClientPage />
					</PrivateRoute>
					<PrivateRoute path='/view-client/:clientID'>
						<MainClientPage />
					</PrivateRoute>
					<PrivateRoute path='/update-client/:clientID'>
						<UpdateClientPage />
					</PrivateRoute>
					<PrivateRoute path='/view-all-clients'>
						<ViewAllClientsPage />
					</PrivateRoute>

					<PrivateRoute path='/update-personal-information/:clientID'>
						<UpdatePersonalInformationPage />
					</PrivateRoute>
					<PrivateRoute path='/update-immigration-information/:clientID'>
						<UpdateImmigrationInformationPage />
					</PrivateRoute>

					<PrivateRoute path='/add-language/:clientID'>
						<AddLanguagePage />
					</PrivateRoute>
					<PrivateRoute path='/update-language/:langID'>
						<UpdateLanguagePage />
					</PrivateRoute>

					<PrivateRoute path='/add-travel-record/:clientID'>
						<AddTravelRecordPage />
					</PrivateRoute>
					<PrivateRoute path='/update-travel-record/:travRecID'>
						<UpdateTravelRecordPage />
					</PrivateRoute>

					<PrivateRoute path='/add-previous-address/:clientID'>
						<AddPreviousAddressPage />
					</PrivateRoute>
					<PrivateRoute path='/update-previous-address/:prevAddID'>
						<UpdatePreviousAddressPage />
					</PrivateRoute>

					<PrivateRoute path='/add-passport/:clientID'>
						<AddPassportPage />
					</PrivateRoute>
					<PrivateRoute path='/update-passport/:passportID'>
						<UpdatePassportPage />
					</PrivateRoute>

					<PrivateRoute path='/add-client-note/:clientID'>
						<AddClientNotePage />
					</PrivateRoute>
					<PrivateRoute path='/update-client-note/:noteID'>
						<UpdateClientNotePage />
					</PrivateRoute>

					<PrivateRoute path='/add-family-member/:clientID'>
						<AddFamilyMemberPage />
					</PrivateRoute>
					<PrivateRoute path='/update-family-member/:famMemberID'>
						<UpdateFamilyMemberPage />
					</PrivateRoute>

					{/* ==============================CASE ROUTS============================ */}

					<PrivateRoute path='/create-case'>
						<CreateCasePage />
					</PrivateRoute>

					<PrivateRoute path='/update-case/:caseID'>
						<UpdateCasePage />
					</PrivateRoute>

					<PrivateRoute path='/add-event/:caseID'>
						<AddEventPage />
					</PrivateRoute>
					<PrivateRoute path='/update-event/:eventID'>
						<UpdateEventPage />
					</PrivateRoute>
					<PrivateRoute path='/update-event-status/:eventID'>
						<UpdateEventStatusPage />
					</PrivateRoute>

					<PrivateRoute path='/add-case-history-update/:caseID'>
						<AddHistoryUpdatePage />
					</PrivateRoute>
					<PrivateRoute path='/update-case-history-update/:updateID'>
						<UpdateHistoryUpdatePage />
					</PrivateRoute>

					<PrivateRoute path='/add-notice-of-appearance/:caseID'>
						<AddNoticeOfAppearancePage />
					</PrivateRoute>
					<PrivateRoute path='/update-notice-of-appearance/:noticeID'>
						<UpdateNoticeOfAppearancePage />
					</PrivateRoute>

					<PrivateRoute path='/add-service/:caseID'>
						<AddServicePage />
					</PrivateRoute>
					<PrivateRoute path='/update-service/:serviceID'>
						<UpdateServicePage />
					</PrivateRoute>

					<PrivateRoute path='/add-case-quick-note/:caseID'>
						<AddQuickNotePage />
					</PrivateRoute>
					<PrivateRoute path='/update-case-quick-note/:quickNoteID'>
						<UpdateQuickNotePage />
					</PrivateRoute>

					<PrivateRoute path='/add-filing-element/:eventID'>
						<AddFilingElementPage />
					</PrivateRoute>
					<PrivateRoute path='/update-filing-element/:elementID'>
						<UpdateFilingElementPage />
					</PrivateRoute>

					<PrivateRoute path='/add-checklist-item/:eventID'>
						<AddChecklistItemPage />
					</PrivateRoute>
					<PrivateRoute path='/update-checklist-item/:itemID'>
						<UpdateChecklistItemPage />
					</PrivateRoute>

					<PrivateRoute path='/view-supporting-documents/:elementID'>
						<SupportingDocumentsPage />
					</PrivateRoute>
					<PrivateRoute path='/add-supporting-document/:elementID'>
						<AddSupportingDocumentPage />
					</PrivateRoute>
					<PrivateRoute path='/update-supporting-document/:documentID'>
						<UpdateSupportingDocumentPage />
					</PrivateRoute>

					<PrivateRoute path='/filing-page/:eventID'>
						<FilingPage />
					</PrivateRoute>

					<PrivateRoute path='/search-results/:searchTerm'>
						<SearchResultesPage />
					</PrivateRoute>

					<PrivateRoute path='/add-office-reminder'>
						<AddOfficeReminderPage />
					</PrivateRoute>
					<PrivateRoute path='/update-office-reminder/:reminderID'>
						<UpdateOfficeReminderPage />
					</PrivateRoute>

					<Route path='/login'>
						<LoginPage />
					</Route>
				</Switch>
			</Container>
			{/* </Router> */}
		</>
	);
}

export default App;
