import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function ClientForm({
	createClientSubmitFunction,
	updateClientSubmitFunction,
	client,
}) {
	const location = useLocation();
	const history = useHistory();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit } = useForm({
		defaultValues: {
			firstName: client?.firstName || null,
			middleName: client?.middleName || null,
			lastName: client?.lastName || null,
			aNumber: client?.aNumber || null,
			phone_1: client?.phone_1 || null,
			phone_2: client?.phone_2 || null,
			email_1: client?.email_1 || null,
			email_2: client?.email_2 || null,
			mailingAddress: client?.mailingAddress || null,
			physicalAddress: client?.physicalAddress || null,
		},
	});

	const onSubmit = (formData) => {
		if (!client) createClientSubmitFunction(formData);
		if (client) updateClientSubmitFunction(formData);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				{/* ==================== NAME ========================= */}

				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('firstName')}
								type='text'
								placeholder='First Name'
								required
							/>
							<label htmlFor='firstName'>First Name</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('middleName')}
								type='text'
								placeholder='Middle Name'
							/>
							<label htmlFor='middleName'>Middle Name</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('lastName')}
								type='text'
								placeholder='Last Name'
								required
							/>
							<label htmlFor='lastName'>Last Name</label>
						</div>
					</Col>
					{/* ======== A NUMBER ======== */}
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('aNumber')}
								type='text'
								placeholder='A-Number'
							/>
							<label htmlFor='aNumber'>A-Number</label>
						</div>
					</Col>
				</Row>

				{/* ==================== PHONE  ========================= */}

				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('phone_1')}
								type='text'
								placeholder='Phone 1'
								required
							/>
							<label htmlFor='phone1'>Phone 1</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('phone_2')}
								type='text'
								placeholder='Phone 2'
							/>
							<label htmlFor='phone_2'>Phone 2</label>
						</div>
					</Col>

					{/* ==================== EMAIL ========================= */}

					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('email_1')}
								id='email_1'
								type='text'
								placeholder='Email 1'
							/>
							<label htmlFor='email_1'>Email 1</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('email_2')}
								type='text'
								placeholder='Email 2'
							/>
							<label htmlFor='email_2'>Email 2</label>
						</div>
					</Col>
				</Row>

				{/* ==================== ADDRESS ========================= */}

				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('mailingAddress')}
								type='text'
								placeholder='Mailing Address'
							/>
							<label htmlFor='mailingAddress'>Mailing Address</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('physicalAddress')}
								type='text'
								placeholder='Physical Address'
							/>
							<label htmlFor='physicalAddress'>Physical Address</label>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
