import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import NotSet from '../NotSet';

export default function SearchResultsTableView({ clients }) {
	return (
		<div>
			<Table bordered>
				<thead>
					<tr className='text-center'>
						<th>Client</th>
						<th>A#</th>
						<th>Phone</th>
						<th>Email </th>
						<th>Address</th>
						<th>Gender</th>
						<th>Country of Birth</th>
						<th>Best Language</th>
						<th>Ethnicity</th>
						<th>Religion</th>
						<th>Sect</th>
					</tr>
				</thead>
				<tbody>
					{clients?.map((client) => (
						<tr key={client.id} className='text-center'>
							<td className='text-start'>
								<Link to={`/view-client/${client.id}`}>
									{client.lastName}, {client.firstName}
								</Link>
							</td>

							<td>
								{client.aNumber &&
									client.aNumber.substr(0, 3) +
										'-' +
										client.aNumber.substr(3, 3) +
										'-' +
										client.aNumber.substr(6)}
							</td>
							<td>
								{client.phone_1 &&
									'(' +
										client.phone_1.substr(0, 3) +
										') ' +
										client.phone_1.substr(3, 3) +
										'-' +
										client.phone_1.substr(6)}
								<br />{' '}
								{(client.phone_2 &&
									'(' +
										client.phone_2.substr(0, 3) +
										') ' +
										client.phone_2.substr(3, 3) +
										'-' +
										client.phone_2.substr(6)) ||
									null}
							</td>
							<td>
								{client.email_1 || <NotSet />} <br /> {client.email_2 || null}
							</td>
							<td>{client.mailingAddress || <NotSet />}</td>
							<td>{client.gender || <NotSet />}</td>
							<td>{client.countryOfBirth || <NotSet />}</td>
							<td>{client.bestLanguage || <NotSet />}</td>
							<td>{client.ethnicity || <NotSet />}</td>
							<td>{client.religion || <NotSet />}</td>
							<td>{client.sect || <NotSet />}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
}
