import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import FilingElementForm from '../components/forms/events/Filing-Element-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation } from 'react-query';
// import { addFilingElement } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function AddFilingElementPage() {
	const { addFilingElement } = useContext(RestQueriesContext);
	const { client, cCase } = useContext(AppContext);
	const history = useHistory();
	const { eventID } = useParams();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => addFilingElement(formData), {
		onSuccess: (data) => {
			history.push({
				pathname: '/filing-page/' + eventID,
			});
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isMutationLoading) return <div>Loading...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle
					client={client ? client : 'not set'}
					cCase={cCase ? cCase : 'not set'}
					caption='Add Filing Element'
				/>
				<FilingElementForm addFilingElementSubmitFunction={onSubmit} eventID={eventID} />
			</Container>
		</>
	);
}
