import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';
import { format } from 'date-fns';
import {
	clientRolesList,
	agenciesList,
	immCaseStatusesList,
	caseTypesList,
} from '../../../app/components/DataLists';

export default function CaseForm({
	clientID,
	cCase,
	createCasesubmitFunction,
	updateCaseSubmitFunction,
}) {
	const history = useHistory();
	const location = useLocation();
	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			dateOpened: cCase?.dateOpened || null,
			clientRole: cCase?.clientRole || null,
			agency: cCase?.agency || null,
			type: cCase?.type || null,
			immigrationCaseStatus: cCase?.immigrationCaseStatus || null,
			officeCaseStatus: cCase?.officeCaseStatus || 'open',
		},
	});

	useEffect(() => {
		cCase &&
			reset({
				...cCase,
				dateOpened: (cCase.dateOpened && format(new Date(cCase.dateOpened), 'yyyy-MM-dd')) || null,
			});
	}, [reset, cCase]);

	const onSubmit = (formData) => {
		if (!cCase)
			createCasesubmitFunction({
				...formData,
				clients: [clientID],
				casePrepStatus: 'pending',
				dateOpened: formData.dateOpened + 'T00:00',
			});

		if (cCase)
			updateCaseSubmitFunction({
				...formData,
				dateOpened: formData.dateOpened + 'T00:00',
			});
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						max='2999-12-31'
						id='dateOpened'
						{...register('dateOpened')}
						type='date'
						placeholder='Date Opened'
						required
					/>
					<label htmlFor='dateOpened'>Date Opened</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						id='type'
						{...register('type')}
						type='text'
						placeholder='Type'
						list='caseTyps'
						required
					/>
					<label htmlFor='type'>Type</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='caseTyps'>
						{caseTypesList.map((type) => (
							<option key={type} value={type} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						id='agency'
						{...register('agency')}
						type='text'
						list='agencies'
						placeholder='Agency'
					/>
					<label htmlFor='agency'>Agency</label>

					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='agencies'>
						{agenciesList.map((agency) => (
							<option key={agency} value={agency} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						id='clientRole'
						{...register('clientRole')}
						type='text'
						list='clientRoles'
						placeholder='Client Role'
					/>
					<label htmlFor='clientRole'>Client Role</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='clientRoles'>
						{clientRolesList.map((role) => (
							<option key={role} value={role} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						id='immigrationCaseStatus'
						{...register('immigrationCaseStatus')}
						type='text'
						list='immCaseStatuses'
						placeholder='Case Status (immigration)'
					/>
					<label htmlFor='immigrationCaseStatus'>Case Status (immigration)</label>

					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='immCaseStatuses'>
						{immCaseStatusesList.map((status) => (
							<option key={status} value={status} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<select className='form-select' {...register('officeCaseStatus')} id='officeCaseStatus'>
						<option value='open'>open</option>
						<option value='consultation'>consultation</option>
						{cCase && <option value='closed'>closed</option>}
					</select>
					<label htmlFor='officeCaseStatus'>Office Case Status</label>
				</div>

				<div className='form-floating mb-3'>
					<select className='form-select' {...register('casePrepStatus')} id='casePrepStatus'>
						<option value='pending'>pending</option>
						<option value='completed'>completed</option>
					</select>
					<label htmlFor='officeCaseStatus'>Case Prep Status</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
