import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { Table } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import NotSet from '../../../app/components/NotSet';
import { format } from 'date-fns';

export default function PersonalInformationView() {
	const { client } = useContext(AppContext);
	const location = useLocation();

	if (!client) {
		return <div className='text-center'>No client data</div>;
	}
	return (
		<>
			<Table bordered size='sm'>
				<tbody>
					<tr>
						<td style={{ width: '40%' }}>
							<span className='fw-bold'>Other Names Used:</span>
						</td>
						<td>{client.otherNamesUsed || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Date of Birth:</span>
						</td>
						<td>
							{(client.dateOfBirth && format(new Date(client.dateOfBirth), 'MM/dd/yyyy')) || (
								<NotSet />
							)}
						</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Country Of Birth:</span>
						</td>
						<td>{client.countryOfBirth || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Country Of Residence:</span>
						</td>
						<td> {client.countryOfResidence || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Nationality at Birth:</span>
						</td>
						<td> {client.nationalityAtBirth || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Current Nationality:</span>
						</td>
						<td>{client.currentNationality || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Gender:</span>
						</td>
						<td> {client.gender || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Marital Status:</span>
						</td>
						<td> {client.maritalStatus || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Number of Children:</span>
						</td>
						<td> {client.numberOfChildren || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Religion:</span>
						</td>
						<td> {client.religion || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Sect:</span>
						</td>
						<td> {client.sect || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Ethnicity:</span>
						</td>
						<td> {client.ethnicity || <NotSet />}</td>
					</tr>
					<tr>
						<td>
							<span className='fw-bold'>Best Language:</span>
						</td>
						<td> {client.bestLanguage || <NotSet />}</td>
					</tr>
				</tbody>
			</Table>

			<div className='text-center'>
				<Link
					to={{
						pathname: `/update-personal-information/${client.id}`,
						state: { prevURL: location.pathname },
					}}
				>
					Add/Edit Information
				</Link>
			</div>
		</>
	);
}
