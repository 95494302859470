import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import NotSet from '../../../../app/components/NotSet';
import { formatANum, formatPhoneNum, confDeleteFn } from '../../../../app/utils/helper-functions';

export default function ContactsView({ contacts, mutate }) {
	const location = useLocation();
	return contacts?.length === 0 ? null : (
		<>
			<h5>Contacts</h5>
			<Table bordered size='sm' className='text-center'>
				<thead>
					<tr>
						<th>Name</th>
						<th>Phone</th>
						<th>Email</th>
						{/* <th>Address</th> */}
						<th>Relation</th>
						<th>Date of Birth</th>
						<th>Immigration Status</th>
						{/* <th>Status Expiry Date</th> */}
						<th>A-Number</th>
						<th>Role</th>
						{/* <th>Note</th> */}
						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{contacts?.map((contact) => (
						<tr key={contact.id}>
							<td>
								{contact.firstName} {contact.lastName}
							</td>
							<td>{(contact.phone && formatPhoneNum(contact.phone)) || <NotSet />}</td>
							<td>{contact.email || <NotSet />}</td>
							{/* <td>{contact.address || <NotSet />}</td> */}
							<td>{contact.relationToClient || <NotSet />}</td>
							<td>
								{(contact.dateOfBirth && format(new Date(contact.dateOfBirth), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td>
							<td>{contact.immigrationStatus || <NotSet />}</td>
							<td>
								{/* {(contact.immigrationStatusExpiryDate &&
									format(new Date(contact.immigrationStatusExpiryDate), 'MM/dd/yyyy')) || (
									<NotSet />
								)} */}
							</td>
							<td>{(contact.aNumber && formatANum(contact.aNumber)) || <NotSet />}</td>
							<td>{contact.role || <NotSet />}</td>
							{/* <td>{contact.note || <NotSet />}</td> */}
							<td>
								<Link
									to={{
										pathname: `/update-family-member/${contact.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td>
								<Button variant='link' onClick={() => confDeleteFn(contact.id, mutate, 'Contact')}>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
}
