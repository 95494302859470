import { useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import CardShell from '../CardShell';
import { GqlQueriesContext } from '../../../api/gqlQueries';
import { format, add } from 'date-fns';
import NotSet from '../NotSet';
import { Link, useLocation } from 'react-router-dom';
import { Table, Button, ButtonGroup, Row, Col, ListGroup, Spinner } from 'react-bootstrap';
import { formatANum, formatPhoneNum } from '../../utils/helper-functions';
import { AppContext } from '../../../contexts/AppContext';

export default function DashboardEventsDayView() {
	const location = useLocation();
	const { getTodaysEventsGQL } = useContext(GqlQueriesContext);
	const { fetchingDataStatusFn } = useContext(AppContext);
	const [theDate, setTheDate] = useState(format(new Date(), 'yyyy-MM-dd'));
	const [currentDay, setCurrentDay] = useState(new Date());
	const [todaysEvents, setTodaysEvents] = useState(null);

	const headerSize = 'h4';

	const {
		isLoading,
		isError,
		data: events,
		error,
		isFetching,
	} = useQuery(['getTodaysEventsGQL', theDate], () => getTodaysEventsGQL(theDate));

	useEffect(() => {
		if (events) setTodaysEvents(events);
	}, [events]);

	useEffect(() => {
		fetchingDataStatusFn(isFetching);
		// eslint-disable-next-line
	}, [isFetching]);

	const addDay = () => {
		setTheDate(format(add(new Date(currentDay), { days: 1 }), 'yyyy-MM-dd'));
		setCurrentDay((currentDay) => add(currentDay, { days: 1 }));
	};

	const subDay = () => {
		setTheDate(format(add(new Date(currentDay), { days: -1 }), 'yyyy-MM-dd'));
		setCurrentDay((currentDay) => add(currentDay, { days: -1 }));
	};

	const today = () => {
		setTheDate(format(new Date(), 'yyyy-MM-dd'));
		setCurrentDay(new Date());
	};

	// if (isLoading)
	// 	return (
	// 		<CardShell header={titleDate} headerSize={headerSize}>
	// 			<h5 className='text-center'>Loading Today's events...</h5>
	// 		</CardShell>
	// 	);

	if (isError)
		return (
			<CardShell header={format(currentDay, 'EEEE - MMMM dd, yyyy')} headerSize={headerSize}>
				<h5>Loading Today's events failed - {error.message}</h5>
			</CardShell>
		);

	return (
		// <CardShell header={` ${titleDate} -  [${events?.length}]`} headerSize={headerSize}>
		<CardShell header={format(currentDay, 'EEEE - MMMM dd, yyyy')} headerSize={headerSize}>
			<Row className='mb-4'>
				<Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }} className='text-center'>
					<ButtonGroup>
						<Button variant='outline-primary' onClick={subDay}>
							Prev
						</Button>

						<Button variant='outline-primary' onClick={today}>
							Today
						</Button>

						<Button variant='outline-primary' onClick={addDay}>
							Next
						</Button>
					</ButtonGroup>
				</Col>

				<Col>{isFetching || isLoading ? <Spinner animation='grow' variant='primary' /> : ''}</Col>
			</Row>

			{events?.length === 0 ? (
				<div className='text-center'>
					<h5>No Events Found</h5>
				</div>
			) : (
				<>
					<div className='d-block d-md-none mobile-dashboard-events-view'>
						<ListGroup>
							{todaysEvents?.map((event) => (
								<ListGroup.Item
									key={event.id}
									variant={
										(event.category === 'Filing Deadline' && event.status === 'in-progress'
											? 'warning'
											: undefined) ||
										(event.category === 'Filing (no deadline)' && event.status === 'in-progress'
											? 'warning'
											: undefined) ||
										(event.category === 'Hearing' && event.status === 'scheduled'
											? 'danger'
											: undefined) ||
										(event.category === 'Interview' && event.status === 'scheduled'
											? 'info'
											: undefined) ||
										(event.category === 'Appointment' && event.status === 'scheduled'
											? 'success'
											: undefined) ||
										(event.category === 'Reminder' && event.status === 'unconfirmed'
											? 'light'
											: undefined) ||
										'secondary'
									}
								>
									<div style={{ fontWeight: 'bold' }}>
										{event.category === 'Filing Deadline' ||
										event.category === 'Filing (no deadline)' ||
										event.category === 'Reminder'
											? null
											: format(new Date(event.originalDateTime), 'hh:mm a')}
									</div>
									<span
										className={
											(event.type === 'Individual' ? 'individual' : undefined) ||
											(event.type === 'Master' ? 'master' : undefined) ||
											(event.type === 'Criminal' ? 'criminal' : undefined) ||
											''
										}
									>
										{' '}
										{event.type}
									</span>{' '}
									{event.category} {'-'}{' '}
									<Link to={`/view-client/${event.cases[0].clients[0].id}`}>
										{event.cases[0].clients[0].lastName}, {event.cases[0].clients[0].firstName}
									</Link>
									{(event.immigrationJudge && ' - IJ: ' + event.immigrationJudge) || null}
									{((event.category === 'Filing Deadline' || event.category === 'Reminder') &&
										' - ' + event.regarding) ||
										null}
									{(event.location && ' - ' + event.location) || null}
								</ListGroup.Item>
							))}
						</ListGroup>
					</div>

					<div className='d-none d-md-block'>
						<Table bordered id='dashboard-events-table'>
							{/* <thead>
						<tr>
            <th>Time</th>
            <th>Event</th>
            <th>Client Name</th>
            <th>A#</th>
            <th>Phone</th>
            <th>Regarding</th>
            <th>Immigration Judge</th>
            <th>Location</th>
            
            <th>Action</th>
						</tr>
					</thead> */}

							<tbody>
								{todaysEvents?.map((event) => (
									<tr
										key={event.id}
										className={
											(event.category === 'Filing Deadline' && event.status === 'in-progress'
												? 'table-warning'
												: undefined) ||
											(event.category === 'Filing (no deadline)' && event.status === 'in-progress'
												? 'table-warning'
												: undefined) ||
											(event.category === 'Hearing' && event.status === 'scheduled'
												? 'table-danger'
												: undefined) ||
											(event.category === 'Interview' && event.status === 'scheduled'
												? 'table-info'
												: undefined) ||
											(event.category === 'Appointment' && event.status === 'scheduled'
												? 'table-success'
												: undefined) ||
											(event.category === 'Reminder' && event.status === 'unconfirmed'
												? 'table-light'
												: undefined) ||
											'table-secondary'
										}
									>
										<td style={{ width: '5%', fontWeight: 'bold', fontSize: '1.1rem' }}>
											{event.category === 'Filing Deadline' ||
											event.category === 'Filing (no deadline)'
												? 'n/a'
												: format(new Date(event.originalDateTime), 'hh:mm a')}
										</td>
										<td style={{ width: '10%' }}>
											<span
												className={
													(event.type === 'Individual' ? 'individual' : undefined) ||
													(event.type === 'Master' ? 'master' : undefined) ||
													(event.type === 'Criminal' ? 'criminal' : undefined) ||
													''
												}
											>
												{' '}
												{event.type}
											</span>{' '}
											{event.category}
										</td>
										<td
											className='copy'
											onClick={() =>
												navigator.clipboard.writeText(
													event.cases[0].clients[0].lastName +
														', ' +
														event.cases[0].clients[0].firstName
												)
											}
											style={{ width: '13%' }}
										>
											<Link to={`/view-client/${event.cases[0].clients[0].id}`}>
												{event.cases[0].clients[0].lastName}, {event.cases[0].clients[0].firstName}
											</Link>
										</td>
										<td
											className='copy'
											onClick={() =>
												navigator.clipboard.writeText(event.cases[0].clients[0].aNumber)
											}
											style={{ width: '7%' }}
										>
											{(event.cases[0].clients[0].aNumber &&
												'A' + formatANum(event.cases[0].clients[0].aNumber)) || <NotSet a='A# ' />}
										</td>
										<td
											className='copy'
											onClick={() =>
												navigator.clipboard.writeText(event.cases[0].clients[0].phone_1)
											}
											style={{ width: '7%' }}
										>
											{event.cases[0].clients[0].phone_1 &&
												formatPhoneNum(event.cases[0].clients[0].phone_1)}
										</td>
										<td style={{ width: '28%' }}>
											{event.regarding || (
												<span className='text-muted fst-italic'>(Regarding)</span>
											)}
										</td>
										<td style={{ width: '10%' }}>
											{event.location || <span className='text-muted fst-italic'>(Location)</span>}
										</td>
										<td style={{ width: '10%' }}>
											{(event.immigrationJudge && 'IJ: ' + event.immigrationJudge) || (
												<span className='text-muted fst-italic'>(IJ)</span>
											)}
										</td>
										<td className='text-center' style={{ width: '5%' }}>
											{event.status}
										</td>
										<td className='text-center' style={{ width: '5%' }}>
											<Link
												to={{
													pathname: `/update-event/${event.id}`,
													state: {
														prevURL: location.pathname,
														category: event.category,
														clientID: event.cases[0].clients[0].id,
														caseID: event.cases[0].id,
													},
												}}
											>
												Edit
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				</>
			)}
		</CardShell>
	);
}
