import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { confDeleteFn } from '../../../app/utils/confirm-delete';
import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import NotSet from '../../../app/components/NotSet';
// import { removeNoticeOfAppearance } from '../../../api/restQueries';
import { RestQueriesContext } from '../../../api/restQueries';

export default function NoticeOfAppearanceView() {
	const { removeNoticeOfAppearance } = useContext(RestQueriesContext);
	const { cCase, refetchCase: refetch } = useContext(AppContext);
	const location = useLocation();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeNoticeOfAppearance(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing notice of appearance...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return cCase?.notice_of_appearances?.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-notice-of-appearance/${cCase.id}`,
					state: { prevURL: location.pathname },
				}}
			>
				+ Notice of Appearance
			</Link>
		</div>
	) : (
		<>
			<Table bordered size='sm'>
				<thead>
					<tr>
						<th>Type</th>
						<th>Status</th>
						<th>Date Filed</th>

						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{cCase?.notice_of_appearances?.map((notice) => (
						<tr key={notice.id}>
							<td>{notice.type || <NotSet />}</td>
							<td>{notice.status || <NotSet />}</td>
							<td>
								{(notice.dateOfFiling && format(new Date(notice.dateOfFiling), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td>

							<td>
								<Link
									to={{
										pathname: `/update-notice-of-appearance/${notice.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td>
								{/* <Button variant='link' onClick={() => mutate(notice.id)}> */}
								<Button variant='link' onClick={() => confDeleteFn(notice.id, mutate, 'Notice')}>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<div className='text-center'>
				<Link
					to={{
						pathname: `/add-notice-of-appearance/${cCase?.id}`,
						state: { prevURL: location.pathname },
					}}
				>
					+ Notice of Appearance
				</Link>
			</div>
		</>
	);
}
