import { useContext, useEffect } from 'react';
import { AppContext } from '../../contexts/AppContext';
import SupportingDocumentsView from '../components/views/events/Supporting-Documents-View';
import { Row, Col } from 'react-bootstrap';
import CardShell from '../../app/components/CardShell';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
// import { getFilingElement } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function SupportingDocumentsPage() {
	const { getFilingElement } = useContext(RestQueriesContext);
	const { event, selectElement, refetchElementFn } = useContext(AppContext);
	const { elementID } = useParams();
	// const location = useLocation();
	// const { prevURL } = location.state;

	const {
		isLoading,
		isError,
		data: element,
		error,
		refetch: refetchElement,
	} = useQuery(['getFilingElement', elementID], () => getFilingElement(elementID));

	useEffect(() => {
		if (element) {
			selectElement(element);
			refetchElementFn(refetchElement);
		}
		// eslint-disable-next-line
	}, [element, refetchElement]);

	if (isLoading) return <div>Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;

	return (
		<>
			<h4>
				{element.number} {element.name} {element.decription}
			</h4>
			<Row>
				<Col>
					<CardShell header={` Supporting Documents`} textAlignment='text-center'>
						<SupportingDocumentsView element={element} />
					</CardShell>
				</Col>
			</Row>
			<Row>
				<Col>
					<Link
						to={`/filing-page/${event.id}`}
						// to={{
						// 	pathname: `/filing-page/${event.id}`,
						// 	state: {
						// 		prevURL: location.pathname,
						// 	},
						// }}
					>
						Back to Filing Elements
					</Link>
				</Col>
			</Row>
		</>
	);
}
