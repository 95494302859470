import { useState, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { orderBy } from 'lodash';
import NotSet from '../NotSet';
import { useLocation, Link } from 'react-router-dom';
import CardShell from '../CardShell';
import AccordionShell from '../AccordionShell';
import { GqlQueriesContext } from '../../../api/gqlQueries';
import { AppContext } from '../../../contexts/AppContext';

export default function PendingCasesView2() {
	const { getPendingEventsGQL } = useContext(GqlQueriesContext);
	const { selectClient, selectCase } = useContext(AppContext);
	// const [orderByDateEvents, setOrderByDateEvents] = useState(null);
	const [activeEvents, setActiveEvents] = useState(null);
	// const [filingDeadline, setFilingDeadline] = useState(null);
	const [filingNoDeadline, setFilingNoDeadline] = useState(null);

	const location = useLocation();
	const {
		isLoading,
		isError,
		data: events,
		error,
		// isFetching,
	} = useQuery('getPendingEventsGQL', getPendingEventsGQL);

	useEffect(() => {
		if (events) setActiveEvents(orderBy(events, ['originalDateTime'], ['asc']));
	}, [events]);

	useEffect(() => {
		if (activeEvents) {
			// setFilingDeadline(activeEvents.filter((event) => event.category === 'Filing Deadline'));
			setFilingNoDeadline(
				activeEvents.filter((event) => event.category === 'Filing (no deadline)')
			);
		}
	}, [activeEvents]);

	if (isLoading)
		return (
			<CardShell header='Pending Cases' headerSize='h4'>
				<h5 className='text-center'>Loading pending cases...</h5>
			</CardShell>
		);

	if (isError)
		return (
			<CardShell header='Pending Cases' headerSize='h4'>
				<h5 className='text-center'>Error loading - {error.message}</h5>
			</CardShell>
		);

	return activeEvents?.length === 0 ? (
		<CardShell header={`Pending Cases (${activeEvents?.length})`} headerSize='h4'>
			<h5>No Pending Cases !</h5>
		</CardShell>
	) : (
		<CardShell header={`Pending Cases (${activeEvents?.length})`} headerSize='h4'>
			{/* <Row className='mt-3 mb-4'>
				<h4>Filing Deadlines</h4>
				{filingDeadline?.map((event) => (
					<Col key={event.id} md={4} style={{ marginBottom: '10px' }}>
						<AccordionShell
							header={
								event.cases[0].clients[0].lastName +
								', ' +
								event.cases[0].clients[0].firstName +
								' - (' +
								format(new Date(event.originalDateTime), 'MM/dd/yyyy') +
								')'
							}
						>
							<Card>
								<Card.Body>
									<Card.Title className='text-center'>
										<Link to={`view-client/${event.cases[0].clients[0].id}`}>
											{event.cases[0].clients[0].lastName}, {event.cases[0].clients[0].firstName} -{' '}
											{'A'}
											{(event.cases[0].clients[0].aNumber &&
												event.cases[0].clients[0].aNumber.substr(0, 3) +
													'-' +
													event.cases[0].clients[0].aNumber.substr(3, 3) +
													'-' +
													event.cases[0].clients[0].aNumber.substr(6)) || <NotSet />}
										</Link>
									</Card.Title>
									<p className='lead text-center'>{event.cases[0].type}</p>

									<div>
										<Link to={`view-client/${event.cases[0].clients[0].id}`}>
											<h5>
												{' '}
												{event.filingTitle}
												{' - '}
												<small className='text-muted'>
													({format(new Date(event.originalDateTime), 'MMM dd, yyyy')})
												</small>
											</h5>
										</Link>
										{event.prep_checklists.length > 0 ? (
											<>
												<Table bordered className='text-center'>
													<thead>
														<tr>
															<th>Item</th>
															<th>Status</th>
															<th>Provided by</th>
														</tr>
													</thead>
													<tbody>
														{event.prep_checklists.map((item) => (
															<tr key={item.id}>
																<td className='text-start'>{item.item}</td>
																<td>{item.status}</td>
																<td>{item.providedBy}</td>
															</tr>
														))}
													</tbody>
												</Table>
												<Link
													to={`filing-page/${event.id}`}
													onClick={() => {
														selectClient(event?.cases[0]?.clients[0]);
														selectCase(event?.cases[0]);
													}}
												>
													Add/Edit/Remove Items
												</Link>
											</>
										) : (
											<Link
												to={{
													pathname: `/add-checklist-item/${event.id}`,
													state: { prevURL: location.pathname },
												}}
												onClick={() => {
													selectClient(event?.cases[0]?.clients[0]);
													selectCase(event?.cases[0]);
												}}
											>
												Add Filing Checklist-Item
											</Link>
										)}
									</div>
								</Card.Body>
							</Card>
						</AccordionShell>
					</Col>
				))}
			</Row> */}

			<Row>
				<h4 className='text-center'>Filing (No Deadline)</h4>
				{filingNoDeadline?.map((event) => (
					<Col key={event.id} md={4} style={{ marginBottom: '10px' }}>
						<AccordionShell
							header={
								event.cases[0].clients[0].lastName + ', ' + event.cases[0].clients[0].firstName
							}
						>
							<Card>
								<Card.Body>
									<Card.Title className='text-center'>
										<Link to={`view-client/${event.cases[0].clients[0].id}`}>
											{event.cases[0].clients[0].lastName}, {event.cases[0].clients[0].firstName} -{' '}
											{'A'}
											{(event.cases[0].clients[0].aNumber &&
												event.cases[0].clients[0].aNumber.substr(0, 3) +
													'-' +
													event.cases[0].clients[0].aNumber.substr(3, 3) +
													'-' +
													event.cases[0].clients[0].aNumber.substr(6)) || <NotSet />}
										</Link>
									</Card.Title>
									<p className='lead text-center'>{event.cases[0].type}</p>

									<div>
										<Link to={`view-client/${event.cases[0].clients[0].id}`}>
											<h5>
												{' '}
												{event.filingTitle}
												{' - '}
												<small className='text-muted'>
													({format(new Date(event.originalDateTime), 'MMM dd, yyyy')})
												</small>
											</h5>
										</Link>
										{event.prep_checklists.length > 0 ? (
											<>
												<Table bordered className='text-center'>
													<thead>
														<tr>
															<th>Item</th>
															<th>Status</th>
															<th>Provided by</th>
														</tr>
													</thead>
													<tbody>
														{event.prep_checklists.map((item) => (
															<tr key={item.id}>
																<td className='text-start'>{item.item}</td>
																<td>{item.status}</td>
																<td>{item.providedBy}</td>
															</tr>
														))}
													</tbody>
												</Table>
												<Link
													to={`filing-page/${event.id}`}
													onClick={() => {
														selectClient(event?.cases[0]?.clients[0]);
														selectCase(event?.cases[0]);
													}}
												>
													Add/Edit/Remove Items
												</Link>
											</>
										) : (
											<Link
												to={{
													pathname: `/add-checklist-item/${event.id}`,
													state: { prevURL: location.pathname },
												}}
												onClick={() => {
													selectClient(event?.cases[0]?.clients[0]);
													selectCase(event?.cases[0]);
												}}
											>
												Add Filing Checklist-Item
											</Link>
										)}
									</div>
								</Card.Body>
							</Card>
						</AccordionShell>
					</Col>
				))}
			</Row>
		</CardShell>
	);
}
