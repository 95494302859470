import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';
import { format } from 'date-fns';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function PreviousAddressForm({
	addPreviousAddressSubmitFunction,
	updatePreviousAddressSubmitFunction,
	previousAddress,
	clientID,
}) {
	const location = useLocation();
	const history = useHistory();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			address: previousAddress?.address || null,
			fromDate: previousAddress?.fromDate || null,
			toDate: previousAddress?.toDate || null,
		},
	});

	useEffect(() => {
		previousAddress &&
			reset({
				...previousAddress,
				fromDate:
					(previousAddress.fromDate && format(new Date(previousAddress.fromDate), 'yyyy-MM-dd')) ||
					null,
				toDate:
					(previousAddress.toDate && format(new Date(previousAddress.toDate), 'yyyy-MM-dd')) ||
					null,
			});
	}, [reset, previousAddress]);

	const onSubmit = (formData) => {
		if (!previousAddress)
			addPreviousAddressSubmitFunction({
				...formData,
				clients: [clientID],
				fromDate: (formData.fromDate && formData.fromDate + 'T00:00') || null,
				toDate: (formData.toDate && formData.toDate + 'T00:00') || null,
			});
		if (previousAddress) updatePreviousAddressSubmitFunction(formData);
	};
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('address')}
						id='address'
						type='text'
						placeholder='Address'
						required
					/>
					<label htmlFor='address'>Address</label>
				</div>

				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('fromDate')}
								max='2999-12-31'
								id='fromDate'
								type='date'
								placeholder='From Date'
							/>
							<label htmlFor='fromDate'>From Date</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('toDate')}
								max='2999-12-31'
								id='toDate'
								type='date'
								placeholder='To Date'
							/>
							<label htmlFor='toDate'>To Date</label>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
