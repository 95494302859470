import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import SupportingDocumentForm from '../components/forms/events/Supporting-Document-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateSupportingDocument, getSupportingDocument } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateSupportingDocumentPage() {
	const { client, cCase, element } = useContext(AppContext);
	const { updateSupportingDocument, getSupportingDocument } = useContext(RestQueriesContext);
	const { documentID } = useParams();
	const history = useHistory();
	const location = useLocation();

	const {
		isLoading,
		isError,
		data: document,
		error,
	} = useQuery(['getSupportingDocument', documentID], () => getSupportingDocument(documentID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateSupportingDocument(documentID, formData), {
		onSuccess: (data) => {
			history.push({
				pathname: `/view-supporting-documents/${element.id}`,
				state: data,
			});
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	console.log('Update Supporting Documents Page: ', location.state);

	return (
		<>
			<Container>
				<FormTitle client={client} cCase={cCase} caption='Edit Supporting Document' />
				<SupportingDocumentForm
					updateSupportingDocumentSubmitFunction={onSubmit}
					document={document}
				/>
			</Container>
		</>
	);
}
