import { useContext } from 'react';
import DashbordReminderForm from '../components/forms/Dashboard-Reminder-Form';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateOfficeReminder, getOfficeReminder } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateOfficeReminderPage() {
	const { updateOfficeReminder, getOfficeReminder } = useContext(RestQueriesContext);
	const history = useHistory();
	const { reminderID } = useParams();

	const {
		isLoading,
		isError,
		data: reminder,
		error,
	} = useQuery(['getOfficeReminder', reminderID], () => getOfficeReminder(reminderID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateOfficeReminder(reminderID, formData), {
		onSuccess: () => {
			history.push('/');
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<h4>Office Reminder</h4>
				<DashbordReminderForm updateReminderSubmitFunction={onSubmit} reminder={reminder} />
			</Container>
		</>
	);
}
