import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { confDeleteFn } from '../../../app/utils/confirm-delete';
import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import NotSet from '../../../app/components/NotSet';
// import { removeTravelRecord } from '../../../api/restQueries';
import { RestQueriesContext } from '../../../api/restQueries';

export default function TravelRecordsView() {
	const { removeTravelRecord } = useContext(RestQueriesContext);
	const { client, refetchClient: refetch } = useContext(AppContext);
	const location = useLocation();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeTravelRecord(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing travel record...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return client?.travel_records?.length === 0 ? (
		<div>
			<Link
				to={{ pathname: `/add-travel-record/${client.id}`, state: { prevURL: location.pathname } }}
			>
				+ Travel Record
			</Link>
		</div>
	) : (
		<>
			<Table bordered size='sm'>
				<thead>
					<tr>
						<th>Date of Trip</th>
						<th>Origin Country</th>
						<th>Destination Country</th>
						<th>Travel Method</th>
						<th>Travel-Through Countries</th>
						<th>Date of Arrival</th>
						<th>Port of Entry</th>
						<th>Immigration Status</th>
						<th>Type of Entry</th>
						<th>Purpose of Trip</th>
						<th>Duration of Trip</th>
						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{client?.travel_records?.map((record) => (
						<tr key={record.id}>
							<td>
								{(record.dateOfTrip && format(new Date(record.dateOfTrip), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td>
							<td>{record.originCountry || <NotSet />}</td>
							<td>{record.destinationCountry || <NotSet />}</td>
							<td>{record.travelMethod || <NotSet />}</td>
							<td>{record.travelThroughCountries || <NotSet />}</td>
							<td>
								{(record.dateOfArrival && format(new Date(record.dateOfArrival), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td>
							<td>{record.portOfEntry || <NotSet />}</td>
							<td>{record.immigrationStatus || <NotSet />}</td>
							<td>{record.typeOfEntry || <NotSet />}</td>
							<td>{record.purposeOfTrip || <NotSet />}</td>
							<td>{record.durationOfTrip || <NotSet />}</td>
							<td>
								<Link
									to={{
										pathname: `/update-travel-record/${record.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td>
								{/* <Button variant='link' onClick={() => mutate(record.id)}> */}
								<Button
									variant='link'
									onClick={() => confDeleteFn(record.id, mutate, 'Travel Record')}
								>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<div>
				<Link
					to={{
						pathname: `/add-travel-record/${client.id}`,
						state: { prevURL: location.pathname },
					}}
				>
					+ Travel Record
				</Link>
			</div>
		</>
	);
}
