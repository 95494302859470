import { gql } from 'graphql-request';

export const queryGetClients = gql`
	query GetClients {
		clients {
			id
			firstName
			middleName
			lastName
			cases {
				id
				type
			}
		}
	}
`;

export const queryGetClientById = gql`
	query GetClientById($client_id: ID!) {
		client(id: $client_id) {
			id
			firstName
			middleName
			lastName
			phone_1
			phone_2
			email_1
			email_2
			mailingAddress
			physicalAddress
			otherNamesUsed
			countryOfBirth
			countryOfResidence
			nationalityAtBirth
			currentNationality
			gender
			maritalStatus
			numberOfChildren
			religion
			sect
			ethnicity
			race
			tribalGroup
			bestLanguage
			imm_status
			aNumber
			I_94_Number
			imm_detained
			imm_custodyFacility
			dateOfBirth
			imm_statusIssueDate
			imm_statusExpiryDate
			imm_dateOfArrest
			imm_dateOfRelease

			cases {
				id
				clientRole
				agency
				type
				officeCaseStatus
				immigrationCaseStatus
				casePrepStatus
				dateOpened

				events(
					sort: "originalDateTime:asc"
					where: {
						_or: [{ status: "scheduled" }, { status: "in-progress" }, { status: "unconfirmed" }]
					}
				) {
					id
					originalDateTime
					updateDateTime
					agency
					category
					type
					immigrationJudge
					location
					scannedDocuments
					scannedNotice
					documentReceived
					receiptNumber
					regarding
					filingTitle
					filingMethod
					note
					status
				}
			}

			languages {
				id
				language
				fluency
			}

			travel_records {
				id
				travelMethod
				originCountry
				destinationCountry
				travelThroughCountries
				portOfEntry
				immigrationStatus
				typeOfEntry
				purposeOfTrip
				durationOfTrip
				dateOfTrip
				dateOfArrival
			}

			passports {
				id
				number
				country
				withClient
				issueDate
				expiryDate
			}

			previous_addresses {
				id
				address
				fromDate
				toDate
			}

			family_members {
				id
				category
				firstName
				lastName
				phone
				email
				address
				relationToClient
				countryOfBirth
				nationality
				religion
				sect
				ethnicity
				maritalStatus
				immigrationStatus
				aNumber
				role
				note
				dateOfBirth
				immigrationStatusExpiryDate
			}

			client_notes {
				id
				title
				body
				date
			}
		}
	}
`;

export const querySearchClients = gql`
	query SearchClients($searchTerm: String!) {
		clients(
			where: {
				_or: [
					{ firstName_contains: $searchTerm }
					{ middleName_contains: $searchTerm }
					{ lastName_contains: $searchTerm }
					{ aNumber: $searchTerm }
					{ phone_1: $searchTerm }
					{ phone_2: $searchTerm }
					{ countryOfBirth_contains: $searchTerm }
					{ religion_contains: $searchTerm }
					{ bestLanguage_contains: $searchTerm }
					{ gender: $searchTerm }
				]
			}
			sort: "lastName:asc"
		) {
			id
			firstName
			middleName
			lastName
			phone_1
			phone_2
			email_1
			email_2
			mailingAddress
			physicalAddress
			countryOfBirth
			gender
			religion
			sect
			ethnicity
			bestLanguage
			aNumber

			cases {
				id
				clientRole
				agency
				type
				officeCaseStatus
				immigrationCaseStatus
				casePrepStatus
				dateOpened

				events(
					sort: "originalDateTime:asc"
					where: {
						_or: [{ status: "scheduled" }, { status: "in-progress" }, { status: "unconfirmed" }]
					}
				) {
					id
					originalDateTime
					updateDateTime
					agency
					category
					type
					immigrationJudge
					location
					scannedDocuments
					scannedNotice
					documentReceived
					receiptNumber
					regarding
					filingTitle
					filingMethod
					note
					status
				}
			}
		}
	}
`;

export const queryGetCaseById = gql`
	query GetCaseById($case_id: ID!) {
		case(id: $case_id) {
			id
			clientRole
			agency
			type
			officeCaseStatus
			immigrationCaseStatus
			casePrepStatus
			dateOpened
			events {
				id
				originalDateTime
				updateDateTime
				agency
				category
				type
				immigrationJudge
				location
				scannedDocuments
				scannedNotice
				documentReceived
				receiptNumber
				regarding
				filingTitle
				filingMethod
				note
				status
				elements {
					id
					type
					number
					name
					description
					supporting_documents {
						id
						category
						name
						fromDate
						toDate
					}
				}
				prep_checklists {
					id
					item
					status
					providedBy
					note
				}
			}
			case_quick_access_notes {
				id
				application
				receiptNumber
			}
			services {
				id
				type
				description
				agency
				date
			}
			notice_of_appearances {
				id
				type
				status
				dateOfFiling
			}
			case_history_updates {
				id
				date
				title
				note
			}
		}
	}
`;

export const queryGetAllCases = gql`
	query GetAllCases {
		cases {
			id
			clientRole
			agency
			type
			officeCaseStatus
			immigrationCaseStatus
			casePrepStatus
			dateOpened
			clients {
				id
				firstName
				lastName
				aNumber
			}
			events {
				id
				type
				category
				originalDateTime
				filingTitle
				status
				prep_checklists {
					id
					item
					status
					providedBy
					note
				}
			}
		}
	}
`;

export const queryGetEventById = gql`
	query GetEventById($event_id: ID!) {
		event(id: $event_id) {
			id
			regarding
			filingTitle
			filingMethod
			originalDateTime
			updateDateTime
			prep_checklists {
				id
				item
				status
				providedBy
				note
			}
			elements {
				id
				type
				number
				name
				description
				supporting_documents {
					id
					category
					name
					fromDate
					toDate
				}
			}
		}
	}
`;

export const queryGetTodaysEvents = gql`
	query GetTodaysEvents($todays_date: String) {
		events(
			sort: "originalDateTime:asc"
			where: {
				originalDateTime_contains: $todays_date
				_or: [
					{ category: "Hearing" }
					{ category: "Interview" }
					{ category: "Appointment" }
					{ category: "Filing Deadline" }
					{ category: "Reminder" }
				]
				_and: {
					_or: [{ status: "in-progress" }, { status: "scheduled" }, { status: "unconfirmed" }]
				}
			}
		) {
			id
			originalDateTime
			updateDateTime
			agency
			category
			type
			immigrationJudge
			location
			scannedDocuments
			scannedNotice
			documentReceived
			regarding
			filingTitle
			filingMethod
			note
			status
			cases {
				id
				clientRole
				agency
				type
				officeCaseStatus
				immigrationCaseStatus
				casePrepStatus
				dateOpened
				clients {
					id
					firstName
					lastName
					aNumber
					phone_1
				}
			}
			prep_checklists {
				id
				item
				status
				providedBy
				note
			}
		}
	}
`;

export const queryGetActiveEvents = gql`
	query GetActiveEvents($thisMonth: String) {
		events(
			sort: "originalDateTime:asc"
			where: {
				originalDateTime_contains: $thisMonth
				_or: [{ category: "Hearing" }, { category: "Interview" }, { category: "Appointment" }]
				_and: { _or: [{ status: "scheduled" }] }
			}
		) {
			id
			originalDateTime
			updateDateTime
			agency
			category
			type
			immigrationJudge
			location
			scannedDocuments
			scannedNotice
			documentReceived
			regarding
			filingTitle
			filingMethod
			note
			status
			cases {
				id
				clientRole
				agency
				type
				officeCaseStatus
				immigrationCaseStatus
				casePrepStatus
				dateOpened
				clients {
					id
					firstName
					lastName
					aNumber
					phone_1
				}
			}
			prep_checklists {
				id
				item
				status
				providedBy
				note
			}
		}
	}
`;

export const queryGetDeadlinesAndRemindersGQL = gql`
	query GetDeadlinesAndReminders($thisMonth: String) {
		events(
			sort: "originalDateTime:asc"
			where: {
				originalDateTime_contains: $thisMonth
				_or: [{ category: "Filing Deadline" }, { category: "Reminder" }]
				_and: { _or: [{ status: "in-progress" }, { status: "unconfirmed" }] }
			}
		) {
			id
			originalDateTime
			updateDateTime
			agency
			category
			type
			immigrationJudge
			location
			scannedDocuments
			scannedNotice
			documentReceived
			regarding
			filingTitle
			filingMethod
			note
			status
			cases {
				id
				clientRole
				agency
				type
				officeCaseStatus
				immigrationCaseStatus
				casePrepStatus
				dateOpened
				clients {
					id
					firstName
					lastName
					aNumber
					phone_1
				}
			}
			prep_checklists {
				id
				item
				status
				providedBy
				note
			}
		}
	}
`;

export const queryGetAllEvents = gql`
	query GetAllEvents($thisMonth: String) {
		events(
			sort: "originalDateTime:asc"
			where: {
				originalDateTime_contains: $thisMonth
				_or: [
					{ category: "Hearing" }
					{ category: "Interview" }
					{ category: "Appointment" }
					{ category: "Filing Deadline" }
					{ category: "Reminder" }
				]
			}
		) {
			id
			originalDateTime
			updateDateTime
			agency
			category
			type
			immigrationJudge
			location
			scannedDocuments
			scannedNotice
			documentReceived
			regarding
			filingTitle
			filingMethod
			note
			status
			cases {
				id
				clientRole
				agency
				type
				officeCaseStatus
				immigrationCaseStatus
				casePrepStatus
				dateOpened
				clients {
					id
					firstName
					lastName
					aNumber
					phone_1
				}
			}
			prep_checklists {
				id
				item
				status
				providedBy
				note
			}
		}
	}
`;

export const queryGetAllChecklistItems = gql`
	query GetChecklistItems {
		prepChecklists {
			id
			item
			status
			providedBy
			note
			events {
				id
				originalDateTime
				type
				category
				filingTitle
				filingMethod
				cases {
					id
					clientRole
					agency
					type
					officeCaseStatus
					immigrationCaseStatus
					casePrepStatus
					dateOpened
					clients {
						id
						firstName
						lastName
						aNumber
					}
				}
			}
		}
	}
`;

export const queryGetPendingEvents = gql`
	query GetPendingEvents {
		events(where: { status: "in-progress" }) {
			id
			originalDateTime
			agency
			filingTitle
			status
			category
			cases {
				id
				type
				officeCaseStatus
				casePrepStatus
				dateOpened
				clients {
					id
					firstName
					lastName
					aNumber
				}
			}
			prep_checklists {
				id
				item
				status
				providedBy
				note
			}
		}
	}
`;
