import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import FilingPrepChecklistForm from '../components/forms/events/Filing-Prep-Checklist-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateChecklistItem, getChecklistItem } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateChecklistItemPage() {
	const { updateChecklistItem, getChecklistItem } = useContext(RestQueriesContext);
	const { client, cCase, event } = useContext(AppContext);
	const history = useHistory();
	const { itemID } = useParams();

	const {
		isLoading,
		isError,
		data: item,
		error,
	} = useQuery(['getChecklistItem', itemID], () => getChecklistItem(itemID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateChecklistItem(itemID, formData), {
		onSuccess: () => {
			history.push('/filing-page/' + event.id);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} cCase={cCase} caption='Edit Checklist Item' />
				<FilingPrepChecklistForm updateFilingChecklistItemSubmitFunction={onSubmit} item={item} />
			</Container>
		</>
	);
}
