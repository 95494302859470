import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { Row, Col } from 'react-bootstrap';
// import ContactInformationView from './Contact-Information-view';
import ImmigrationInformationView from './Immigration-Information-View';
import LanguagesView from './Languages-View';
import PassportsView from './Passports-View';
import PersonalInformationView from './Personal-Information-View';
import TravelRecordsView from './Travel-Records-View';
import PreviousAddressesView from './Pervious-Addresses-View';
import ClientNotesView from './Client-Notes-View';
import FamilyMembersView from './Family-Members-View';
import CardShell from '../../../app/components/CardShell';

export default function ClientProfileView() {
	const { client } = useContext(AppContext);

	return client ? (
		<>
			<section className='mt-5'>
				<Row>
					<Col md={6}>
						{/* <CardShell header='Contact Information' textAlignment=''>
							<ContactInformationView />
						</CardShell> */}
						<CardShell header='Personal Information' textAlignment=''>
							<PersonalInformationView />
						</CardShell>
						<CardShell header='Notes' textAlignment='text-center'>
							<ClientNotesView />
						</CardShell>
					</Col>
					<Col md={6}>
						<CardShell header='Immigration Information' textAlignment=''>
							<ImmigrationInformationView />
						</CardShell>
						<CardShell header='Languages' textAlignment='text-center'>
							<LanguagesView />
						</CardShell>
						<CardShell header='Passports' textAlignment='text-center'>
							<PassportsView />
						</CardShell>
						<CardShell header='Previous Addresses' textAlignment='text-center'>
							<PreviousAddressesView />
						</CardShell>
					</Col>
				</Row>

				<CardShell header='Travel Records' textAlignment='text-center'>
					<TravelRecordsView />
				</CardShell>

				<CardShell header='Family Members' textAlignment='text-center'>
					<FamilyMembersView />
				</CardShell>
			</section>
		</>
	) : (
		'no client'
	);
}
