import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import ClientForm from '../../client/components/forms/Client-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
// import { updateClient } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateClientPage() {
	const { updateClient } = useContext(RestQueriesContext);
	const { client } = useContext(AppContext);
	const history = useHistory();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateClient(client.id, formData), {
		onSuccess: (data) => {
			history.push({
				pathname: '/view-client/' + client.id,
				state: data,
			});
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} caption='Basic Information' />
				<ClientForm updateClientSubmitFunction={onSubmit} client={client} />
			</Container>
		</>
	);
}
