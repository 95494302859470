// ================================== CLIENT DataLists =================================

export const countriesList = [
	'Afghanistan',
	'Albania',
	'Algeria',
	'Andorra',
	'Angola',
	'Anguilla',
	'Antigua & Barbuda',
	'Argentina',
	'Armenia',
	'Aruba',
	'Australia',
	'Austria',
	'Azerbaijan',
	'Bahamas',
	'Bahrain',
	'Bangladesh',
	'Barbados',
	'Belarus',
	'Belgium',
	'Belize',
	'Benin',
	'Bermuda',
	'Bhutan',
	'Bolivia',
	'Bosnia & Herzegovina',
	'Botswana',
	'Brazil',
	'British Virgin Islands',
	'Brunei',
	'Bulgaria',
	'Burkina Faso',
	'Burundi',
	'Cambodia',
	'Cameroon',
	'Cape Verde',
	'Cayman Islands',
	'Chad',
	'Chile',
	'China',
	'Colombia',
	'Congo',
	'Cook Islands',
	'Costa Rica',
	'Cote D Ivoire',
	'Croatia',
	'Cruise Ship',
	'Cuba',
	'Cyprus',
	'Czech Republic',
	'Denmark',
	'Djibouti',
	'Dominica',
	'Dominican Republic',
	'Ecuador',
	'Egypt',
	'El Salvador',
	'Equatorial Guinea',
	'Estonia',
	'Ethiopia',
	'Falkland Islands',
	'Faroe Islands',
	'Fiji',
	'Finland',
	'France',
	'French Polynesia',
	'French West Indies',
	'Gabon',
	'Gambia',
	'Georgia',
	'Germany',
	'Ghana',
	'Gibraltar',
	'Greece',
	'Greenland',
	'Grenada',
	'Guam',
	'Guatemala',
	'Guernsey',
	'Guinea',
	'Guinea Bissau',
	'Guyana',
	'Haiti',
	'Honduras',
	'Hong Kong',
	'Hungary',
	'Iceland',
	'India',
	'Indonesia',
	'Iran',
	'Iraq',
	'Ireland',
	'Isle of Man',
	'Israel',
	'Italy',
	'Jamaica',
	'Japan',
	'Jersey',
	'Jordan',
	'Kazakhstan',
	'Kenya',
	'Kuwait',
	'Kyrgyz Republic',
	'Laos',
	'Latvia',
	'Lebanon',
	'Lesotho',
	'Liberia',
	'Libya',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Macau',
	'Macedonia',
	'Madagascar',
	'Malawi',
	'Malaysia',
	'Maldives',
	'Mali',
	'Malta',
	'Mauritania',
	'Mauritius',
	'Mexico',
	'Moldova',
	'Monaco',
	'Mongolia',
	'Montenegro',
	'Montserrat',
	'Morocco',
	'Mozambique',
	'Namibia',
	'Nepal',
	'Netherlands',
	'Netherlands Antilles',
	'New Caledonia',
	'New Zealand',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'Norway',
	'Oman',
	'Pakistan',
	'Palestine',
	'Panama',
	'Papua New Guinea',
	'Paraguay',
	'Peru',
	'Philippines',
	'Poland',
	'Portugal',
	'Puerto Rico',
	'Qatar',
	'Reunion',
	'Romania',
	'Russia',
	'Rwanda',
	'Saint Pierre & Miquelon',
	'Samoa',
	'San Marino',
	'Satellite',
	'Saudi Arabia',
	'Senegal',
	'Serbia',
	'Seychelles',
	'Sierra Leone',
	'Singapore',
	'Slovakia',
	'Slovenia',
	'South Africa',
	'South Korea',
	'Spain',
	'Sri Lanka',
	'St Kitts & Nevis',
	'St Lucia',
	'St Vincent',
	'St. Lucia',
	'Sudan',
	'Suriname',
	'Swaziland',
	'Sweden',
	'Switzerland',
	'Syria',
	'Taiwan',
	'Tajikistan',
	'Tanzania',
	'Thailand',
	"Timor L'Este",
	'Togo',
	'Tonga',
	'Trinidad & Tobago',
	'Tunisia',
	'Turkey',
	'Turkmenistan',
	'Turks & Caicos',
	'Uganda',
	'Ukraine',
	'United Arab Emirates',
	'United Kingdom',
	'United States',
	'Uruguay',
	'Uzbekistan',
	'Venezuela',
	'Vietnam',
	'Virgin Islands (US)',
	'Yemen',
	'Zambia',
	'Zimbabwe',
];

export const immStatusList = [
	'Undocumented',
	'Non-Immigrant',
	'LPR (2 years)',
	'LPR (10 years)',
	'U.S. Citizen',
];

export const custodyFacilitiesList = [
	'Adelanto ICE Processing Center - Adelanto, CA',
	'Golden State Annex - McFarland, CA',
	'Imperial Regional Detention Facility - Calexico, CA',
	'James A. Musick Facility - Irvine, CA',
	'Mesa Verde ICE Processing Facility - Bakersfield, CA',
	'Otay Mesa Detention Center (San Diego CDF) - San Diego, CA',
	'Santa Ana City Jail - Santa Ana, CA',
	'Yuba County Jail - Marysville, CA',
];

export const languagesList = [
	'Arabic',
	'Spanish',
	'English',
	'Russian',
	'Kanjobal',
	'Mandarin Chinese',
	'Hindi/Urdu',
	'Bengali',
	'Portuguese',
	'Japanese',
	'German',
	'Javanese',
	'Punjabi',
	'Wu',
	'French',
	'Telugu',
	'Vietnamese',
	'Marathi',
	'Korean',
	'Tamil',
	'Italian',
	'Turkish',
	'Cantonese/Yue',
];

export const religionsList = [
	'Christian',
	'Cathloic',
	'Jehovas Witness',
	'Jewish',
	'Muslim',
	'Atheist',
	'Agnostic',
	'MORE TO BE ADDED',
];

export const sectsList = ['Sunni', 'Shia', 'MORE TO BE ADDED'];

export const ethnicitiesList = [
	'Arab',
	'Chaldean',
	'Kurdish',
	'Assyrian',
	'Kanjobal',
	'MORE TO BE ADDED',
];

export const fluencyList = [
	'Native',
	'100%',
	'90%',
	'80%',
	'70%',
	'60%',
	'50%',
	'40%',
	'30%',
	'20%',
	'10%',
];

export const travelMethodsList = [
	'Plane',
	'Boat / Ship',
	'Train',
	'Bus',
	'Taxi',
	'Car',
	'Walking / on foot',
];

export const portOfEntriesList = [];

export const relationsToClientList = [
	'Husband',
	'Wife',
	'Son',
	'Daughter',
	'Stepson',
	'Stepdaughter',
	'Father',
	'Mother',
	'Stepfather',
	'Stepmother',
	'Brother',
	'Sister',
	'Stepbrother',
	'Stepsister',
	'Friend',
	'Employer',
	'Co-worker',
	'Landlord',
	'None',
];

export const rolesInCaseList = [
	'None',
	'Petitioner',
	'Sponsor',
	'Joint-Sponsor',
	'Qualifying Relative',
	'Derivative',
];

// ================================== CASE DataLists =================================

export const clientRolesList = [
	'Applicant',
	'Petitioner',
	'Beneficiary',
	'Requestor',
	'Respondent',
	'Defendant',
];

export const agenciesList = [
	'Immigration Court',
	'EOIR',
	'USCIS',
	'USCIS Asylum Office',
	'USCIS Field Office',
	'OPLA',
	'ICE',
	'CBP',
	'BIA',
	'DOS',
	'NVC',
	'3rd Circuit',
	'6th Circuit',
	'9th Circuit',
	'District Court SD',
	'District Court LA',
	'District Court SF',
	'Superior State Court',
];

export const caseTypesList = [
	'Asylum (Affirmative)',
	'Removal Proceedings (Detained)',
	'Removal Proceedings (Non-Detained)',
	'Custody and Bond proceedings',
	'Citizenship & Naturalization',
	'Adjustment of Status (Family-based)',
	'Adjustment of Status (Marriage-based)',
	'Adjustment of Status (Employment-based)',
	'Adjustment of Status (Investment-based)',
	'Adjustment of Status (Asylum-based) ',
	'Adjustment of Status (Cancellation-of-Removal-based)',
	'Petition for Alien Relative',
	'Fiance visa',
	'Petition to Remove Conditions on Residence',
	'Waiver (inadmissible to the U.S.)',
	'DACA (Deferred Action for Childhood Arrivals)',
	'Appeal from a Decision of an Immigration Judge (Detained) ',
	'Appeal from a Decision of an Immigration Judge (Non-Detained) ',
	'Petition for review',
];

export const serviceTypesList = [
	'I-589',
	'EOIR-42A',
	'EOIR-42B',
	'I-130',
	'I-485',
	'I-864',
	'I-765',
	'I-751',
	'DS-160',
	'DS-260',
	'NVC & Consular processing',
	'Notice of appeal',
	'Motion to reopen',
	'Motion to reconsider',
	'Petition for review',
	'Motion for stay of removal',
	'Writ of Mandamus filed in the U.S. District Court',
	'INA 336(b) – Complaint pursuant to USC Sec. 1447(b)',
];

export const immCaseStatusesList = [
	'Open',
	'Pending',
	'Approved',
	'Granted',
	'Administratively Closed',
	'Terminated',
	'Terminated on PD',
	'Reopened',
	'Re-Calendared',
	'Remanded',
	'Referred to Immigration Judge',
	'Denied',
	'Dismissed',
];

export const hearingTypesList = ['Master', 'Individual', 'Bond'];

export const immigrationCourtsList = [
	'San Diego, CA',
	'Adelanto, CA',
	'Imperial, CA',
	'North Los Angeles St., Los Angeles, CA',
	'Olive St., Los Angeles, CA',
	'Van Nuys Blvd, Los Angeles, CA',
	'Otay Mesa, CA',
	'Sacramento, CA',
	'San Francisco, CA',
	'Phoenix, AZ',
	'Chicago, IL',
];

export const immigrationJudgesList = [
	{ name: 'Attia, Olga', court: 'San Diego, CA' },
	{ name: 'Bartolomei, Rico J.', court: 'San Diego, CA' },
	{ name: 'DeVitto, James', court: 'San Diego, CA' },
	{ name: 'Grande, Guy G.', court: 'San Diego, CA' },
	{ name: 'Law, Philip S.', court: 'San Diego, CA' },
	{ name: "O'Connor,  Lee", court: 'San Diego, CA' },
	{ name: 'Romig, Jeffrey L.', court: 'San Diego, CA' },
	{ name: 'Simpson, Scott', court: 'San Diego, CA' },
	{ name: 'Viray, Glenda', court: 'San Diego, CA' },
	{ name: '--------------------------', court: '' },
	{ name: 'DePowell, Bryan E.', court: 'Adelanto, CA' },
	{ name: 'Halperin, Ravit R.', court: 'Adelanto, CA' },
	{ name: 'Marcus, Steven', court: 'Adelanto, CA' },
	{ name: 'Walker, Nathaniel B.', court: 'Adelanto, CA' },
	{ name: 'White, Curtis G.', court: 'Adelanto, CA' },
	{ name: 'Roberts, Valerie (Acting)', court: 'Adelanto, CA' },
	{ name: '--------------------------', court: '' },
	{ name: 'Muñoz, Jeffrey V.', court: 'Imperial, CA' },
	{ name: 'Pressman, Christian', court: 'Imperial, CA' },
	{ name: 'M. Robinson, Eugene H.', court: 'Imperial, CA' },
	{ name: 'Gray, Gracie', court: 'Imperial, CA' },
	{ name: '--------------------------', court: '' },
	{ name: 'Aina, Nathan N.', court: 'North Los Angeles St., Los Angeles, CA' },
	{ name: 'Hsu, Lily C.', court: 'North Los Angeles St., Los Angeles, CA' },
	{ name: 'Left, James M.', court: 'North Los Angeles St., Los Angeles, CA' },
	{ name: 'Piepmeier, Kristin', court: 'North Los Angeles St., Los Angeles, CA' },
	{ name: 'Travieso, Frank', court: 'North Los Angeles St., Los Angeles, CA' },
	{ name: 'Patron, Jeanette', court: 'North Los Angeles St., Los Angeles, CA' },
	{ name: '--------------------------', court: '' },
	{ name: 'Allen, Janette L.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Bakke Varzandeh, Joyce', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Bank, Ira', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Behne, Audra', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Chon, Hye Y.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Costa, Philip', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Desai, Jankhana', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Everett, Timothy', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Francis, Leon J.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Hong, Andrea H.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Huddleston, Natalie B.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Jasso, Jaime', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Juelle, Carlos R.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Latimore, Jan D.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Lee, Edward F.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Lee, Wilbur', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Malvin, Daniel H.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Miller, Nancy E.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Park, Jeannette Lim', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Patti, Sebastian T.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Ruane, Rachel Ann', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Simons, Anita L.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Stancill, Christine E.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Vahid-Tehrani, Gita', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Villegas, Veronica S.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Virchis, Bridget', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Waterloo, Jason R.', court: 'Olive St., Los Angeles, CA' },
	{ name: 'Palacio-Garcia, Sabrina', court: 'Olive St., Los Angeles, CA' },
	{ name: '--------------------------', court: '' },
	{ name: 'Burke, Brian H.', court: 'Van Nuys Blvd, Los Angeles, CA' },
	{ name: 'Burke, David', court: 'Van Nuys Blvd, Los Angeles, CA' },
	{ name: 'Dorfman, Arlene', court: 'Van Nuys Blvd, Los Angeles, CA' },
	{ name: 'Maury, Carlos', court: 'Van Nuys Blvd, Los Angeles, CA' },
	{ name: 'Santos-Garcia, Sandra', court: 'Van Nuys Blvd, Los Angeles, CA' },
	{ name: 'Torres, Hilma', court: 'Van Nuys Blvd, Los Angeles, CA' },
	{ name: '--------------------------', court: '' },
	{ name: 'Anderson, Amelia C.', court: 'Otay Mesa, CA' },
	{ name: 'Begovich, Samantha L.', court: 'Otay Mesa, CA' },
	{ name: 'Burch, Valerie A.', court: 'Otay Mesa, CA' },
	{ name: 'Halliday-Roberts, Catherine E.', court: 'Otay Mesa, CA' },
	{ name: 'Partida, Ana L.', court: 'Otay Mesa, CA' },
	{ name: 'Preciado, Sergio', court: 'Otay Mesa, CA' },
	{ name: '--------------------------', court: '' },
	{ name: 'Geisse, Loreto', court: 'Sacramento, CA' },
	{ name: 'Maggard, Print', court: 'Sacramento, CA' },
	{ name: 'Neumeister, William', court: 'Sacramento, CA' },
	{ name: 'Phan, Christopher V.', court: 'Sacramento, CA' },
	{ name: 'Terrazas, Gilda M.', court: 'Sacramento, CA' },
	{ name: 'Rodriguez, Nichole', court: 'Sacramento, CA' },
	{ name: 'Butler, Jeremy J.', court: 'San Francisco, CA' },
	{ name: 'Caborn, Andrew J.', court: 'San Francisco, CA' },
	{ name: '--------------------------', court: '' },
	{ name: 'Deiss, Ila C.', court: 'San Francisco, CA' },
	{ name: 'Figueroa, Laura C.', court: 'San Francisco, CA' },
	{ name: 'Gambill, Scott D.', court: 'San Francisco, CA' },
	{ name: 'George, Amber D.', court: 'San Francisco, CA' },
	{ name: 'Greene, Charles S., III', court: 'San Francisco, CA' },
	{ name: 'Jackson, Jacqueline J.', court: 'San Francisco, CA' },
	{ name: 'Johnson, Jeremiah', court: 'San Francisco, CA' },
	{ name: 'Kim, Samuel Y.', court: 'San Francisco, CA' },
	{ name: 'Lake, Kelly B.', court: 'San Francisco, CA' },
	{ name: 'Marks, Dana Leigh', court: 'San Francisco, CA' },
	{ name: 'Masterson, Jason R.', court: 'San Francisco, CA' },
	{ name: 'McCleskey, Loi L.', court: 'San Francisco, CA' },
	{ name: 'Nelson, Julie L.', court: 'San Francisco, CA' },
	{ name: 'O’Brien, Patrick S.', court: 'San Francisco, CA' },
	{ name: 'Park, Joseph Y.', court: 'San Francisco, CA' },
	{ name: 'Phan, Susan', court: 'San Francisco, CA' },
	{ name: 'Picton, Cory M.', court: 'San Francisco, CA' },
	{ name: 'Price, Justin M.', court: 'San Francisco, CA' },
	{ name: 'Savage, Patrick S.', court: 'San Francisco, CA' },
	{ name: 'Simmons, Gregory L.', court: 'San Francisco, CA' },
	{ name: 'Star, Shadee M.', court: 'San Francisco, CA' },
	{ name: 'Swink, Arwen A.', court: 'San Francisco, CA' },
	{ name: 'McDaniel, Scott (Acting)', court: 'San Francisco, CA' },
];

export const interviewLocationsList = [
	{ city: 'Arlington, VA', type: 'Asylum Office' },
	{ city: 'Bethpage, NY', type: 'Asylum Office' },
	{ city: 'Boston, MA', type: 'Asylum Office' },
	{ city: 'Chicago, IL', type: 'Asylum Office' },
	{ city: 'Houston, TX', type: 'Asylum Office' },
	{ city: 'Metairie, LA', type: 'Asylum Office' },
	{ city: 'Miami, FL', type: 'Asylum Office' },
	{ city: 'Newark, NJ', type: 'Asylum Office' },
	{ city: 'New York, NY', type: 'Asylum Office' },
	{ city: 'San Francisco, CA', type: 'Asylum Office' },
	{ city: 'Tampa, FL', type: 'Asylum Office' },
	{ city: '--------------------------', type: '' },
	{ city: 'Tustin, CA ', type: 'Asylum Office' },
	{ city: 'San Diego, CA', type: 'Field Office' },
	{ city: 'Santa Ana, CA', type: 'Field Office' },
	{ city: 'San Bernardino, CA', type: 'Field Office' },
	{ city: 'Los Angeles, CA', type: 'Field Office' },
];

export const interviewTypesList = [
	'Asylum',
	'Naturalization',
	'Adjustment of Status',
	'Remove Conditions on Residence',
	'ICE Call-In Letter',
	'Credible Fear',
	'Reasonable Fear',
];

export const appointmentTypesList = [
	'Office',
	'Tele-Video',
	'Zoom',
	'FaceTime',
	'Skype',
	'Telephone',
	'Detention Center',
];

export const filingMethodsList = [
	'Paper filing with Immigration Court',
	'Paper filing with Immigration Judge',
	'E-File',
	'by USPS',
	'by FedEx',
	'E-mail',
];

export const elementTypesList = [
	'Application',
	'Supplemental',
	'Motion',
	'Appeal',
	'Brief',
	'Petition',
	'Response',
	'Request',
];

export const supportingDocumentTypesList = [
	'Identity Documents',
	'Physical Presence',
	'Good Moral Character',
	'Financial Hardship',
	'Health Hardship',
	'Psychological Hardship',
	'Medical Records',
	'Psychological Evaluation',
	'Country Condition ',
	'Criminal Records',
	'Sworn Statements / Declarations',
	'Good Faith Marriage',
	'Joint Financial Responsibility',
	'Joint Residence',
	'Sponsor / Joint Sponsor',
	'Photographs',
];
