import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { confDeleteFn } from '../../../../app/utils/confirm-delete';
import { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { format, formatDistanceToNow } from 'date-fns';
import NotSet from '../../../../app/components/NotSet';
import { orderBy } from 'lodash';
// import { removeEvent } from '../../../../api/restQueries';
import { RestQueriesContext } from '../../../../api/restQueries';

export default function AppointmentsView() {
	const { removeEvent } = useContext(RestQueriesContext);
	const { client, cCase, refetchCase: refetch } = useContext(AppContext);
	const [orderByDateEvents, setOrderByDateEvents] = useState(null);
	const [filteredOrderedEvents, setFilteredOrderedEvents] = useState(null);
	const location = useLocation();
	const events = cCase?.events;

	useEffect(() => {
		if (events) setOrderByDateEvents(orderBy(events, ['originalDateTime'], ['asc']));
	}, [events]);

	useEffect(() => {
		if (orderByDateEvents)
			setFilteredOrderedEvents(
				orderByDateEvents.filter((event) => event.category === 'Appointment')
			);
	}, [orderByDateEvents]);

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeEvent(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing event...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return filteredOrderedEvents?.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-event/${cCase.id}`,
					state: { prevURL: location.pathname, category: 'Appointment' },
				}}
			>
				+ Appointment
			</Link>
		</div>
	) : (
		cCase && (
			<>
				<Table bordered size='sm'>
					<thead>
						<tr>
							<th style={{ width: '8%' }}>Count Down</th>
							<th style={{ width: '8%' }}>Date</th>
							<th style={{ width: '5%' }}>Time</th>
							<th style={{ width: '20%' }}>Regarding</th>
							<th style={{ width: '46%' }}>Note</th>
							<th style={{ width: '5%' }}>Status</th>
							<th colSpan='2' style={{ width: '8%' }}>
								Action
							</th>
						</tr>
					</thead>
					<tbody>
						{filteredOrderedEvents?.map((event) => {
							return (
								<tr key={event.id}>
									<td>
										{formatDistanceToNow(new Date(event.originalDateTime), { addSuffix: true })}
									</td>

									<td>{format(new Date(event.originalDateTime), 'E - MMM dd, yyyy')}</td>
									<td>
										{event.category === 'Filing Deadline' ||
										event.category === 'Filing (no deadline)'
											? 'n/a'
											: format(new Date(event.originalDateTime), 'hh:mm a')}
									</td>
									<td className='text-start'>{event.regarding || <NotSet />}</td>
									<td className='text-start' style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
										{event.note || <NotSet />}
									</td>
									<td>
										{event.status === 'scheduled' ? (
											<Link
												to={{
													pathname: `/update-event-status/${event.id}`,
													state: {
														prevURL: location.pathname,
														category: event.category,
													},
												}}
											>
												{event.status !== 'scheduled' ? null : event.status}
											</Link>
										) : (
											event.status
										)}
									</td>

									<td>
										<Link
											to={{
												pathname: `/update-event/${event.id}`,
												state: {
													prevURL: location.pathname,
													category: event.category,
													caseID: cCase.id,
													clientID: client.id,
												},
											}}
										>
											Edit
										</Link>
									</td>
									<td>
										{/* <Button variant='link' onClick={() => mutate(event.id)}> */}
										<Button
											variant='link'
											onClick={() => confDeleteFn(event.id, mutate, 'Appointment')}
										>
											Remove
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
				<div>
					<Link
						to={{
							pathname: `/add-event/${cCase.id}`,
							state: { prevURL: location.pathname, category: 'Appointment' },
						}}
					>
						+ Appointment
					</Link>
				</div>
			</>
		)
	);
}
