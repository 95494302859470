import axios from 'axios';
import { createContext, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

export const RestQueriesContext = createContext();

export default function ProvideRestQueries({ children }) {
	const { jwt: token } = useContext(AuthContext);
	// const api = axios.create({ baseURL: 'http://localhost:1337/' });
	const api = axios.create({
		baseURL: process.env.REACT_APP_REST_ENDPOINT,
		headers: { Authorization: `Bearer ${token}` },
	});

	// =========================== CLIENT QUIRIES ===========================
	const getClient = async (id) => {
		return await (
			await api.get('clients/' + id)
		).data;
	};

	const getAllClients = async (start, limit) => {
		return await (
			await api.get(`clients?_sort=lastName:asc&_start=${start}&_limit=${limit}`)
		).data;
	};

	const countClients = async () => {
		return await (
			await api.get('clients/count')
		).data;
	};

	const addClient = async (formData) => {
		return await (
			await api.post('clients', formData)
		).data;
	};

	const updateClient = async (id, formData) => {
		return await (
			await api.put('clients/' + id, formData)
		).data;
	};

	const searchClients = async (searchTerm) => {
		// First Name - Last Name - A-Number - Phone 1 - Phone 2 - Country of Birth - Religion - Gender
		return await (
			await api.get(
				`/clients?_where[_or][0][firstName_contains]=${searchTerm}&_where[_or][1][lastName_contains]=${searchTerm}&_where[_or][2][aNumber_eq]=${searchTerm}&_where[_or][3][phone_1_eq]=${searchTerm}&_where[_or][4][phone_2_eq]=${searchTerm}&_where[_or][5][countryOfBirth_contains]=${searchTerm}&_where[_or][6][religion_contains]=${searchTerm}&_where[_or][7][bestLanguage_contains]=${searchTerm}&_where[_or][8][gender_contains]=${searchTerm}`
			)
		).data;
	};

	// =========================== PERSONAL INFORMATION QUIRIES ===========================

	const updatePersonalInformation = async (id, formData) => {
		return await (
			await api.put('clients/' + id, formData)
		).data;
	};

	// =========================== IMMIGRATION INFORMATION QUIRIES ===========================
	const updateImmigrationInformation = async (id, formData) => {
		return await (
			await api.put('clients/' + id, formData)
		).data;
	};

	// =========================== LANGUAGE QUIRIES ===========================
	const getLanguage = async (id) => {
		return await (
			await api.get('languages/' + id)
		).data;
	};

	const addLanguage = async (formData) => {
		return await (
			await api.post('languages', formData)
		).data;
	};

	const updateLanguage = async (id, formData) => {
		return await (
			await api.put('languages/' + id, formData)
		).data;
	};

	const removeLanguage = async (id) => {
		return await (
			await api.delete('languages/' + id)
		).data;
	};

	// =========================== TRAVEL RECORDS QUIRIES ===========================
	const getTravelRecord = async (id) => {
		return await (
			await api.get('travel-records/' + id)
		).data;
	};

	const addTravelRecord = async (formData) => {
		return await (
			await api.post('travel-records', formData)
		).data;
	};

	const updateTravelRecord = async (id, formData) => {
		return await (
			await api.put('travel-records/' + id, formData)
		).data;
	};

	const removeTravelRecord = async (id) => {
		return await (
			await api.delete('travel-records/' + id)
		).data;
	};

	// =========================== PASSPORTS QUIRIES ===========================
	const getPassport = async (id) => {
		return await (
			await api.get('passports/' + id)
		).data;
	};

	const addPassport = async (formData) => {
		return await (
			await api.post('passports', formData)
		).data;
	};

	const updatePassport = async (id, formData) => {
		return await (
			await api.put('passports/' + id, formData)
		).data;
	};

	const removePassport = async (id) => {
		return await (
			await api.delete('passports/' + id)
		).data;
	};

	// =========================== PREVIOUS ADDRESSES QUIRIES ===========================
	const getPreviousAddress = async (id) => {
		return await (
			await api.get('previous-addresses/' + id)
		).data;
	};

	const addPreviousAddress = async (formData) => {
		return await (
			await api.post('previous-addresses', formData)
		).data;
	};

	const updatePreviousAddress = async (id, formData) => {
		return await (
			await api.put('previous-addresses/' + id, formData)
		).data;
	};

	const removePreviousAddress = async (id) => {
		return await (
			await api.delete('previous-addresses/' + id)
		).data;
	};

	// =========================== FAMILY MEMBERS QUIRIES ===========================
	const getFamilyMember = async (id) => {
		return await (
			await api.get('family-members/' + id)
		).data;
	};

	const addFamilyMember = async (formData) => {
		return await (
			await api.post('family-members', formData)
		).data;
	};

	const updateFamilyMember = async (id, formData) => {
		return await (
			await api.put('family-members/' + id, formData)
		).data;
	};

	const removeFamilyMember = async (id) => {
		return await (
			await api.delete('family-members/' + id)
		).data;
	};

	// =========================== CLIENT NOTES QUIRIES ===========================
	const getClientNote = async (id) => {
		return await (
			await api.get('client-notes/' + id)
		).data;
	};

	const addClientNote = async (formData) => {
		return await (
			await api.post('client-notes', formData)
		).data;
	};

	const updateClientNote = async (id, formData) => {
		return await (
			await api.put('client-notes/' + id, formData)
		).data;
	};

	const removeClientNote = async (id) => {
		return await (
			await api.delete('client-notes/' + id)
		).data;
	};

	// =========================== CASE QUIRIES ===========================
	const addCase = async (formData) => {
		return await (
			await api.post('cases', formData)
		).data;
	};

	const getCase = async (id) => {
		return await (
			await api.get('cases/' + id)
		).data;
	};

	const updateCase = async (id, formData) => {
		return await (
			await api.put('cases/' + id, formData)
		).data;
	};

	const removeCase = async (id) => {
		return await (
			await api.delete('cases/' + id)
		).data;
	};

	// =========================== EVENT QUIRIES ===========================

	const addEvent = async (formData) => {
		return await (
			await api.post('events', formData)
		).data;
	};

	const getEvent = async (id) => {
		return await (
			await api.get('events/' + id)
		).data;
	};

	const getEvents = async () => {
		return await (
			await api.get('events?_sort=originalDateTime:ASC')
		).data;
	};

	const updateEvent = async (id, formData) => {
		return await (
			await api.put('events/' + id, formData)
		).data;
	};

	const removeEvent = async (id) => {
		return await (
			await api.delete('events/' + id)
		).data;
	};

	// =========================== CASE HISTORY/UPDATE  QUIRIES ===========================
	const addCaseUpdate = async (formData) => {
		return await (
			await api.post('case-history-updates', formData)
		).data;
	};

	const getCaseUpdate = async (id) => {
		return await (
			await api.get('case-history-updates/' + id)
		).data;
	};

	const updateCaseUpdate = async (id, formData) => {
		return await (
			await api.put('case-history-updates/' + id, formData)
		).data;
	};

	const removeCaseUpdate = async (id) => {
		return await (
			await api.delete('case-history-updates/' + id)
		).data;
	};

	// =========================== NOTICE OF APPEARANCE QUIRIES ===========================
	const addNoticeOfAppearance = async (formData) => {
		return await (
			await api.post('notice-of-appearances', formData)
		).data;
	};

	const getNoticeOfAppearance = async (id) => {
		return await (
			await api.get('notice-of-appearances/' + id)
		).data;
	};

	const updateNoticeOfAppearance = async (id, formData) => {
		return await (
			await api.put('notice-of-appearances/' + id, formData)
		).data;
	};

	const removeNoticeOfAppearance = async (id) => {
		return await (
			await api.delete('notice-of-appearances/' + id)
		).data;
	};

	// =========================== QUICK NOTE QUIRIES ===========================
	const addQuickNote = async (formData) => {
		return await (
			await api.post('case-quick-access-notes', formData)
		).data;
	};

	const getQuickNote = async (id) => {
		return await (
			await api.get('case-quick-access-notes/' + id)
		).data;
	};

	const updateQuickNote = async (id, formData) => {
		return await (
			await api.put('case-quick-access-notes/' + id, formData)
		).data;
	};

	const removeQuickNote = async (id) => {
		return await (
			await api.delete('case-quick-access-notes/' + id)
		).data;
	};

	// =========================== SERVICE QUIRIES ===========================
	const addService = async (formData) => {
		return await (
			await api.post('services', formData)
		).data;
	};

	const getService = async (id) => {
		return await (
			await api.get('services/' + id)
		).data;
	};

	const updateService = async (id, formData) => {
		return await (
			await api.put('services/' + id, formData)
		).data;
	};

	const removeService = async (id) => {
		return await (
			await api.delete('services/' + id)
		).data;
	};

	// ===========================  FILING ELEMENT QUIRIES ===========================
	const addFilingElement = async (formData) => {
		return await (
			await api.post('elements', formData)
		).data;
	};

	const getFilingElement = async (id) => {
		return await (
			await api.get('elements/' + id)
		).data;
	};

	const getFilingElements = async () => {
		return await (
			await api.get('elements')
		).data;
	};

	const updateFilingElement = async (id, formData) => {
		return await (
			await api.put('elements/' + id, formData)
		).data;
	};

	const removeFilingElement = async (id) => {
		return await (
			await api.delete('elements/' + id)
		).data;
	};

	// ===========================  CHECKLIST ITEM QUIRIES ===========================
	const addChecklistItem = async (formData) => {
		return await (
			await api.post('prep-checklists', formData)
		).data;
	};

	const getChecklistItem = async (id) => {
		return await (
			await api.get('prep-checklists/' + id)
		).data;
	};

	const updateChecklistItem = async (id, formData) => {
		return await (
			await api.put('prep-checklists/' + id, formData)
		).data;
	};

	const removeChecklistItem = async (id) => {
		return await (
			await api.delete('prep-checklists/' + id)
		).data;
	};

	// ===========================  SUPPORTING DOCUMENT QUIRIES ===========================
	const addSupportingDocument = async (formData) => {
		return await (
			await api.post('supporting-documents', formData)
		).data;
	};

	const getSupportingDocument = async (id) => {
		return await (
			await api.get('supporting-documents/' + id)
		).data;
	};

	const updateSupportingDocument = async (id, formData) => {
		return await (
			await api.put('supporting-documents/' + id, formData)
		).data;
	};

	const removeSupportingDocument = async (id) => {
		return await (
			await api.delete('supporting-documents/' + id)
		).data;
	};

	// ===========================  OFFICE REMINDERS QUIRIES ===========================
	const addOfficeReminder = async (formData) => {
		return await (
			await api.post('office-reminders', formData)
		).data;
	};

	const getOfficeReminder = async (id) => {
		return await (
			await api.get('office-reminders/' + id)
		).data;
	};

	const getOfficeReminders = async () => {
		return await (
			await api.get('office-reminders/')
		).data;
	};

	const updateOfficeReminder = async (id, formData) => {
		return await (
			await api.put('office-reminders/' + id, formData)
		).data;
	};

	const removeOfficeReminder = async (id) => {
		return await (
			await api.delete('office-reminders/' + id)
		).data;
	};

	// // ===========================  QUIRIES ===========================
	//  const add = async (formData) => {
	// 	return await (
	// 		await api.post('', formData)
	// 	).data;
	// };

	//  const get = async (id) => {
	// 	return await (
	// 		await api.get('/' + id)
	// 	).data;
	// };

	//  const update = async (id, formData) => {
	// 	return await (
	// 		await api.put('/' + id, formData)
	// 	).data;
	// };

	//  const remove = async (id) => {
	// 	return await (
	// 		await api.delete('/' + id)
	// 	).data;
	// };

	return (
		<RestQueriesContext.Provider
			value={{
				getClient,
				getAllClients,
				addClient,
				updateClient,
				searchClients,
				updatePersonalInformation,
				updateImmigrationInformation,
				getLanguage,
				addLanguage,
				updateLanguage,
				removeLanguage,
				getTravelRecord,
				addTravelRecord,
				updateTravelRecord,
				removeTravelRecord,
				getPassport,
				addPassport,
				updatePassport,
				removePassport,
				getPreviousAddress,
				addPreviousAddress,
				updatePreviousAddress,
				removePreviousAddress,
				getFamilyMember,
				addFamilyMember,
				updateFamilyMember,
				removeFamilyMember,
				getClientNote,
				addClientNote,
				updateClientNote,
				removeClientNote,
				addCase,
				getCase,
				updateCase,
				removeCase,
				addEvent,
				getEvent,
				getEvents,
				updateEvent,
				removeEvent,
				addCaseUpdate,
				getCaseUpdate,
				updateCaseUpdate,
				removeCaseUpdate,
				addNoticeOfAppearance,
				getNoticeOfAppearance,
				updateNoticeOfAppearance,
				removeNoticeOfAppearance,
				addQuickNote,
				getQuickNote,
				updateQuickNote,
				removeQuickNote,
				addService,
				getService,
				updateService,
				removeService,
				addFilingElement,
				getFilingElement,
				getFilingElements,
				updateFilingElement,
				removeFilingElement,
				addChecklistItem,
				getChecklistItem,
				updateChecklistItem,
				removeChecklistItem,
				addSupportingDocument,
				getSupportingDocument,
				updateSupportingDocument,
				removeSupportingDocument,
				addOfficeReminder,
				getOfficeReminder,
				getOfficeReminders,
				updateOfficeReminder,
				removeOfficeReminder,
				countClients,
			}}
		>
			{children}
		</RestQueriesContext.Provider>
	);
}
