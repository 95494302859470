import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';
import { format } from 'date-fns';
import {
	countriesList,
	languagesList,
	religionsList,
	sectsList,
	ethnicitiesList,
} from '../../../app/components/DataLists';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function PersonalInformationForm({
	updatePersonalInformationSubmitFunction,
	client,
}) {
	const location = useLocation();
	const history = useHistory();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit } = useForm({
		defaultValues: {
			otherNamesUsed: client?.otherNamesUsed || null,
			dateOfBirth:
				(client?.dateOfBirth && format(new Date(client?.dateOfBirth), 'yyyy-MM-dd')) || null,
			countryOfBirth: client?.countryOfBirth || null,
			countryOfResidence: client?.countryOfResidence || null,
			nationalityAtBirth: client?.nationalityAtBirth || null,
			currentNationality: client?.currentNationality || null,
			gender: client?.gender || null,
			maritalStatus: client?.maritalStatus || null,
			numberOfChildren: client?.numberOfChildren || null,
			religion: client?.religion || null,
			sect: client?.sect || null,
			ethnicity: client?.ethnicity || null,
			bestLanguage: client?.bestLanguage || null,
		},
	});

	const onSubmit = (formData) => {
		updatePersonalInformationSubmitFunction({
			...formData,
			dateOfBirth: (formData.dateOfBirth && formData.dateOfBirth + 'T00:00') || null,
		});
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('dateOfBirth')}
								max='2999-12-31'
								id='dateOfBirth'
								type='date'
								placeholder='Date of Birth'
							/>
							<label htmlFor='dateOfBirth'>Date of Birth</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<select className='form-select' id='gender' {...register('gender')}>
								<option value=''></option>
								<option value='Male'>Male</option>
								<option value='Female'>Female</option>
							</select>
							<label htmlFor='gender'>Gender</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('otherNamesUsed')}
								id='otherNamesUsed'
								type='text'
								placeholder='Other Names Used'
							/>
							<label htmlFor='otherNamesUsed'>Other Names Used</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('bestLanguage')}
								id='bestLanguage'
								type='text'
								list='languages'
								placeholder='Best Language'
							/>
							<label htmlFor='bestLanguage'>Best Language</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='languages'>
								{languagesList.map((language) => (
									<option key={language} value={language} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('countryOfBirth')}
								id='countryOfBirth'
								type='text'
								list='countries'
								placeholder='Country of Birth'
							/>
							<label htmlFor='countryOfBirth'>Country of Birth</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='countries'>
								{countriesList.map((country) => (
									<option key={country} value={country} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('countryOfResidence')}
								id='countryOfResidence'
								type='text'
								list='countries'
								placeholder='Country of Residence'
							/>
							<label htmlFor='countryOfResidence'>Country of Residence</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('nationalityAtBirth')}
								id='nationalityAtBirth'
								type='text'
								list='countries'
								placeholder='Nationality at Birth'
							/>
							<label htmlFor='nationalityAtBirth'>Nationality at Birth</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('currentNationality')}
								id='currentNationality'
								type='text'
								list='countries'
								placeholder='Current Nationality'
							/>
							<label htmlFor='currentNationality'>Current Nationality</label>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<select className='form-select' id='maritalStatus' {...register('maritalStatus')}>
								<option value=''></option>
								<option value='Single'>Single</option>
								<option value='Married'>Married</option>
								<option value='Divorced'>Divorced</option>
								<option value='Widowed'>Widowed</option>
							</select>
							<label htmlFor='maritalStatus'>Marital Status</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('numberOfChildren')}
								id='numberOfChildren'
								type='text'
								placeholder='Number of Children'
							/>
							<label htmlFor='numberOfChildren'>Number of Children</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								id='religion'
								{...register('religion')}
								type='text'
								list='religions'
								placeholder='Religion'
							/>
							<label htmlFor='religion'>Religion</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='religions'>
								{religionsList.map((religion) => (
									<option key={religion} value={religion} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								id='sect'
								{...register('sect')}
								type='text'
								list='sects'
								placeholder='Sect'
							/>
							<label htmlFor='sect'>Sect</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='sects'>
								{sectsList.map((sect) => (
									<option key={sect} value={sect} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								id='ethnicity'
								{...register('ethnicity')}
								type='text'
								list='ethnicities'
								placeholder='Ethnicity'
							/>
							<label htmlFor='ethnicity'>Ethnicity</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='ethnicities'>
								{ethnicitiesList.map((ethnicity) => (
									<option key={ethnicity} value={ethnicity} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
