import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import TravelRecordForm from '../components/forms/Travel-Record-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateTravelRecord, getTravelRecord } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateTravelRecordPage() {
	const { client } = useContext(AppContext);
	const { updateTravelRecord, getTravelRecord } = useContext(RestQueriesContext);
	const history = useHistory();
	const { travRecID } = useParams();

	const {
		isLoading,
		isError,
		data: travelRecord,
		error,
	} = useQuery(['getTravelRecord', travRecID], () => getTravelRecord(travRecID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateTravelRecord(travRecID, formData), {
		onSuccess: () => {
			history.push('/view-client/' + client.id);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle
					client={client}
					caption={`Travel Record - [ ${travelRecord.originCountry} - ${travelRecord.destinationCountry} ]`}
				/>
				<TravelRecordForm updateTravelRecordSubmitFunction={onSubmit} travelRecord={travelRecord} />
			</Container>
		</>
	);
}
