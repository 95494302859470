import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { confDeleteFn } from '../../../app/utils/confirm-delete';
import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import NotSet from '../../../app/components/NotSet';
// import { removeService } from '../../../api/restQueries';
import { RestQueriesContext } from '../../../api/restQueries';

export default function ServicesView() {
	const { removeService } = useContext(RestQueriesContext);
	const { cCase, refetchCase: refetch } = useContext(AppContext);
	const location = useLocation();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeService(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing service...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return cCase?.services?.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-service/${cCase.id}`,
					state: { prevURL: location.pathname },
				}}
			>
				+ Service
			</Link>
		</div>
	) : (
		<>
			<Table bordered size='sm'>
				<thead>
					<tr>
						<th>Date</th>
						<th>Type</th>
						<th>Description</th>
						<th>Agency</th>
						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{cCase?.services?.map((service) => (
						<tr key={service.id}>
							<td>
								{(service.date && format(new Date(service.date), 'MM/dd/yyyy')) || <NotSet />}
							</td>
							<td>{service.type || <NotSet />}</td>
							<td>{service.description || <NotSet />}</td>
							<td>{service.agency || <NotSet />}</td>
							<td>
								<Link
									to={{
										pathname: `/update-service/${service.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td>
								{/* <Button variant='link' onClick={() => mutate(service.id)}> */}
								<Button variant='link' onClick={() => confDeleteFn(service.id, mutate, 'Service')}>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<div className='text-center'>
				<Link
					to={{
						pathname: `/add-service/${cCase?.id}`,
						state: { prevURL: location.pathname },
					}}
				>
					+ Service
				</Link>
			</div>
		</>
	);
}
