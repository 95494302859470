import { Link, useLocation } from 'react-router-dom';
import { Card, ListGroup, Table, Row, Col } from 'react-bootstrap';
import { format } from 'date-fns';

export default function SearchResultsCardView({ clients }) {
	const location = useLocation();
	return (
		<>
			{clients.map((client) => (
				<Row key={client.id}>
					<Col md={4} className='mb-3'>
						<Card>
							<Card.Body>
								<Card.Title>
									<Link to={`/view-client/${client.id}`}>
										{client.lastName}, {client.firstName}
										{' - A' +
											client.aNumber.substr(0, 3) +
											'-' +
											client.aNumber.substr(3, 3) +
											'-' +
											client.aNumber.substr(6)}
									</Link>
								</Card.Title>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										{'(' +
											client.phone_1.substr(0, 3) +
											') ' +
											client.phone_1.substr(3, 3) +
											'-' +
											client.phone_1.substr(6)}
										<br />{' '}
										{(client.phone_2 &&
											'(' +
												client.phone_2.substr(0, 3) +
												') ' +
												client.phone_2.substr(3, 3) +
												'-' +
												client.phone_2.substr(6)) ||
											null}
									</ListGroup.Item>
									<ListGroup.Item>
										{client.email_1 || (
											<span className='text-muted fst-italic'>email is not set</span>
										)}{' '}
										<br /> {client.email_2 || null}
									</ListGroup.Item>
									<ListGroup.Item>
										{client.mailingAddress || (
											<span className='text-muted fst-italic'>mailing address is not set</span>
										)}
									</ListGroup.Item>
									{/* <ListGroup.Item>
										{client.countryOfBirth || (
											<span className='text-muted fst-italic'>country of birth is not set</span>
										)}
									</ListGroup.Item>
									<ListGroup.Item>
										{client.bestLanguage || (
											<span className='text-muted fst-italic'>best language is not set</span>
										)}
									</ListGroup.Item>
									<ListGroup.Item>
										{client.ethnicity || (
											<span className='text-muted fst-italic'>ethnicity is not set</span>
										)}
									</ListGroup.Item>
									<ListGroup.Item>
										{client.religion || (
											<span className='text-muted fst-italic'>religion is not set</span>
										)}{' '}
										{client.sect || null}
									</ListGroup.Item>
									<ListGroup.Item>
										{client.gender || (
											<span className='text-muted fst-italic'>gender is not set</span>
										)}
									</ListGroup.Item> */}
								</ListGroup>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						{client?.cases?.map((cCase) => (
							<div key={cCase.id}>
								{/* <h5>{cCase.type}</h5> */}
								{cCase.events.length === 0 ? null : ( // <p>No events found</p>
									<div className='mb-4'>
										<h5>
											{cCase.type} - {cCase.agency}
										</h5>
										<Table bordered>
											<tbody>
												{cCase?.events.map((event) => (
													<tr
														key={event.id}
														className={
															(event.category === 'Filing Deadline' &&
															event.status === 'in-progress'
																? 'table-warning'
																: undefined) ||
															(event.category === 'Filing (no deadline)' &&
															event.status === 'in-progress'
																? 'table-warning'
																: undefined) ||
															(event.category === 'Hearing' && event.status === 'scheduled'
																? 'table-danger'
																: undefined) ||
															(event.category === 'Interview' && event.status === 'scheduled'
																? 'table-info'
																: undefined) ||
															(event.category === 'Appointment' && event.status === 'scheduled'
																? 'table-success'
																: undefined) ||
															(event.category === 'Reminder' && event.status === 'unconfirmed'
																? 'table-light'
																: undefined) ||
															'table-secondary'
														}
													>
														<td style={{ width: '15%' }}>
															{format(new Date(event.originalDateTime), 'MMMM dd, yyyy')}

															{event.category === 'Filing Deadline' ||
															event.category === 'Filing (no deadline)'
																? null
																: ' at ' + format(new Date(event.originalDateTime), 'hh:mm a')}
														</td>
														<td style={{ width: '10%' }}>
															<span
																className={
																	(event.type === 'Individual' ? 'individual' : undefined) ||
																	(event.type === 'Master' ? 'master' : undefined) ||
																	(event.type === 'Criminal' ? 'criminal' : undefined) ||
																	''
																}
															>
																{event.type}
															</span>{' '}
															{event.category}
														</td>

														<td style={{ width: '20%' }}>
															{event.regarding || (
																<span className='text-muted fst-italic'>(Regarding)</span>
															)}
														</td>
														<td style={{ width: '10%' }}>
															{event.location || (
																<span className='text-muted fst-italic'>(Location)</span>
															)}
														</td>
														<td style={{ width: '10%' }}>
															{(event.immigrationJudge && 'IJ: ' + event.immigrationJudge) || (
																<span className='text-muted fst-italic'>(IJ)</span>
															)}
														</td>
														{/* <td className='text-center' style={{ width: '5%' }}>
														{event.status}
													</td> */}
														<td className='text-center' style={{ width: '5%' }}>
															<Link
																to={{
																	pathname: `/update-event/${event.id}`,
																	state: {
																		prevURL: location.pathname,
																		category: event.category,
																		clientID: client.id,
																		caseID: cCase.id,
																	},
																}}
															>
																Edit
															</Link>
														</td>
													</tr>
												))}
											</tbody>
										</Table>
									</div>
								)}
							</div>
						))}
					</Col>
				</Row>
			))}
		</>
	);
}
