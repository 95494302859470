import { useState, useEffect } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import SpouseView from './family-member/Spouse-View';
import ChildrenView from './family-member/Children-View';
import ParentsView from './family-member/Parents-View';
import SiblingsView from './family-member/Siblings-View';
import ContactsView from './family-member/Contacts-View';
// import { removeFamilyMember } from '../../../api/restQueries';
import { RestQueriesContext } from '../../../api/restQueries';

export default function FamilyMembersView() {
	const { removeFamilyMember } = useContext(RestQueriesContext);
	const { client, refetchClient: refetch } = useContext(AppContext);
	const location = useLocation();

	const [spouse, setSpouse] = useState([]);
	const [children, setChildren] = useState([]);
	const [parents, setParents] = useState([]);
	const [siblings, setSiblings] = useState([]);
	const [contacts, setContacts] = useState([]);

	useEffect(() => {
		setSpouse(
			client?.family_members?.filter((member) => {
				return member.category === 'Spouse';
			})
		);

		setChildren(
			client?.family_members?.filter((member) => {
				return member.category === 'Child';
			})
		);

		setParents(
			client?.family_members?.filter((member) => {
				return member.category === 'Parent';
			})
		);

		setSiblings(
			client?.family_members?.filter((member) => {
				return member.category === 'Sibling';
			})
		);

		setContacts(
			client?.family_members?.filter((member) => {
				return member.category === 'Contact';
			})
		);
	}, [client?.family_members]);

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeFamilyMember(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing family member...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return client?.family_members?.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-family-member/${client.id}`,
					state: { category: 'Spouse', prevURL: location.pathname },
				}}
			>
				+ Spouse
			</Link>
			<span className='mx-3'>|</span>

			<Link
				to={{
					pathname: `/add-family-member/${client.id}`,
					state: { category: 'Child', prevURL: location.pathname },
				}}
			>
				+ Child
			</Link>
			<span className='mx-3'>|</span>
			<Link
				to={{
					pathname: `/add-family-member/${client.id}`,
					state: { category: 'Parent', prevURL: location.pathname },
				}}
			>
				+ Parent
			</Link>
			<span className='mx-3'>|</span>
			<Link
				to={{
					pathname: `/add-family-member/${client.id}`,
					state: { category: 'Sibling', prevURL: location.pathname },
				}}
			>
				+ Sibling
			</Link>
			<span className='mx-3'>|</span>

			<Link
				to={{
					pathname: `/add-family-member/${client.id}`,
					state: { category: 'Contact', prevURL: location.pathname },
				}}
			>
				+ Contact
			</Link>
		</div>
	) : (
		<>
			{/* ============================================================ */}
			<SpouseView spouse={spouse} mutate={mutate} />
			<ChildrenView children={children} mutate={mutate} />
			<ParentsView parents={parents} mutate={mutate} />
			<SiblingsView siblings={siblings} mutate={mutate} />
			<ContactsView contacts={contacts} mutate={mutate} />
			{/* ============================================================ */}
			<div className='text-center'>
				<Link
					to={{
						pathname: `/add-family-member/${client.id}`,
						state: { category: 'Spouse', prevURL: location.pathname },
					}}
				>
					+ Spouse
				</Link>
				<span className='mx-3'>|</span>

				<Link
					to={{
						pathname: `/add-family-member/${client.id}`,
						state: { category: 'Child', prevURL: location.pathname },
					}}
				>
					+ Child
				</Link>
				<span className='mx-3'>|</span>
				<Link
					to={{
						pathname: `/add-family-member/${client.id}`,
						state: { category: 'Parent', prevURL: location.pathname },
					}}
				>
					+ Parent
				</Link>
				<span className='mx-3'>|</span>
				<Link
					to={{
						pathname: `/add-family-member/${client.id}`,
						state: { category: 'Sibling', prevURL: location.pathname },
					}}
				>
					+ Sibling
				</Link>
				<span className='mx-3'>|</span>

				<Link
					to={{
						pathname: `/add-family-member/${client.id}`,
						state: { category: 'Contact', prevURL: location.pathname },
					}}
				>
					+ Contact
				</Link>
			</div>
		</>
	);
}
