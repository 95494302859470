import React, { useState, createContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// const api = axios.create({ baseURL: 'http://localhost:1337/' });
const api = axios.create({ baseURL: process.env.REACT_APP_REST_ENDPOINT });

export const AuthContext = createContext();

export default function ProvideAuth({ children }) {
	const history = useHistory();
	const [user, setUser] = useState(null);
	const [jwt, setJWT] = useState(null);
	const [error, setError] = useState(null);

	// ==================== METHODS ============================
	const signin = async (identifier, password) => {
		setError(null);
		try {
			const data = await (
				await api.post('auth/local', {
					identifier: identifier,
					password: password,
				})
			).data;

			setUser(data.user);
			setJWT(data.jwt);
			history.push('/');
		} catch (error) {
			setError(error);
		}
	};

	const signup = (username, email, password) => {
		return;
	};

	const signout = () => {
		setUser(null);
		setJWT(null);
	};

	const sendPasswordResetEmail = (email) => {
		return;
	};

	const confirmPasswordReset = (code, password) => {
		return;
	};

	// ==================== END: METHODS ============================

	return (
		<AuthContext.Provider
			value={{
				user,
				jwt,
				signin,
				signup,
				signout,
				sendPasswordResetEmail,
				confirmPasswordReset,
				error,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

// export default ProvideAuth;
