import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SaveButton from '../../../../app/components/SaveButton';
import CancelButton from '../../../../app/components/CancelButton';
import {
	agenciesList,
	hearingTypesList,
	immigrationJudgesList,
	immigrationCourtsList,
} from '../../../../app/components/DataLists';

export default function HearingForm({
	addEventSubmitFunction,
	updateEventSubmitFunction,
	event,
	category,
	caseID,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			originalDateTime: event?.originalDateTime || null,
			angecy: event?.agency || null,
			type: event?.type || null,
			immigrationJudge: event?.immigrationJudge || null,
			location: event?.location || null,
			regarding: event?.regarding || null,
			note: event?.note || null,
		},
	});

	useEffect(() => {
		event && reset(event);
	}, [reset, event]);

	const onSubmit = (formData) => {
		if (!event) {
			addEventSubmitFunction({
				...formData,
				cases: [caseID],
				category: category,
				status: 'scheduled',
			});
		}
		if (event) {
			updateEventSubmitFunction(formData);
		}
	};

	return (
		<>
			{/* <h3>Hearing</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('originalDateTime')}
						max='2999-12-31T00:00'
						id='dateTime'
						type='datetime-local'
						placeholder='Date & Time'
						required
					/>
					<label htmlFor='originalDateTime'>Date & Time</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						id='agency'
						{...register('agency')}
						list='agencies'
						type='text'
						placeholder='Agency'
					/>
					<label htmlFor='agency'>Agency</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='agencies'>
						{agenciesList.map((agency) => (
							<option key={agency} value={agency} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('type')}
						id='type'
						list='hearingTypes'
						type='text'
						placeholder='Type'
						required
					/>
					<label htmlFor='type'>Type</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='hearingTypes'>
						{hearingTypesList.map((hearing) => (
							<option key={hearing} value={hearing} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('immigrationJudge')}
						id='immigrationJudge'
						list='immigrationJudges'
						type='text'
						placeholder='Immigration Judge'
					/>
					<label htmlFor='immigrationJudge'>Immigration Judge</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='immigrationJudges'>
						{immigrationJudgesList.map((ij, i) => (
							<option key={i} value={ij.name}>
								{ij.court}
							</option>
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('location')}
						id='location'
						list='courtsLocations'
						type='text'
						placeholder='Location'
					/>
					<label htmlFor='location'>Location</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='courtsLocations'>
						{immigrationCourtsList.map((location) => (
							<option key={location} value={location} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('regarding')}
						id='regarding'
						type='text'
						placeholder='Regarding'
					/>
					<label htmlFor='regarding'>Regarding</label>
				</div>

				<div className='form-floating mb-3'>
					<textarea
						className='form-control'
						{...register('note')}
						id='note'
						type='text'
						placeholder='Note'
						style={{ height: '10rem' }}
					></textarea>
					<label htmlFor='note'>Note</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
