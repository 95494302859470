import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { useAuth } from './use-auth';
import { AuthContext } from '../../contexts/AuthContext';

export default function PrivateRoute({ children, ...rest }) {
	// let auth = useAuth();
	const { user } = useContext(AuthContext);
	return (
		<Route
			{...rest}
			render={({ location }) =>
				user ? (
					children
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}
