import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import SupportingDocumentForm from '../components/forms/events/Supporting-Document-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { addSupportingDocument, getFilingElement } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function AddSupportingDocumentPage() {
	const { addSupportingDocument, getFilingElement } = useContext(RestQueriesContext);
	const { client, cCase } = useContext(AppContext);
	const history = useHistory();
	const { elementID } = useParams();

	const {
		isLoading,
		isError,
		data: element,
		error,
	} = useQuery(['getFilingElement', elementID], () => getFilingElement(elementID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => addSupportingDocument(formData), {
		onSuccess: (data) => {
			history.push({
				pathname: '/view-supporting-documents/' + element.id,
				// state: { client: client, cCase: cCase },
			});
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} cCase={cCase} caption='Add Supporting Document' />
				<SupportingDocumentForm
					addSupportingDocumentSubmitFunction={onSubmit}
					elementID={element.id}
				/>
			</Container>
		</>
	);
}
