import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { languagesList, fluencyList } from '../../../app/components/DataLists';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function LanguageForm({
	addLanguageSubmitFunction,
	updateLanguageSubmitFunction,
	language,
	clientID,
}) {
	const location = useLocation();
	const history = useHistory();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			language: '' || language?.language,
			fluency: '' || language?.fluency,
		},
	});

	useEffect(() => {
		reset(language);
	}, [reset, language]);

	const onSubmit = (formData) => {
		if (!language) addLanguageSubmitFunction({ ...formData, clients: [clientID] });
		if (language) updateLanguageSubmitFunction(formData);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('language')}
								id='language'
								type='text'
								list='languages'
								placeholder='language'
								required
							/>
							<label htmlFor='language'>language</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='languages'>
								{languagesList.map((language) => (
									<option key={language} value={language} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('fluency')}
								id='fluency'
								type='text'
								list='fluencies'
								placeholder='fluency'
								required
							/>
							<label htmlFor='fluency'>fluency</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='fluencies'>
								{fluencyList.map((fluency) => (
									<option key={fluency} value={fluency} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
