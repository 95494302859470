import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';
import { countriesList } from '../../../app/components/DataLists';
import { format } from 'date-fns';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function PassportForm({
	addPassportSubmitFunction,
	updatePassportSubmitFunction,
	passport,
	clientID,
}) {
	const location = useLocation();
	const history = useHistory();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			country: '' || passport?.country,
			number: '' || passport?.number,
			issueDate: '' || passport?.issueDate,
			expiryDate: '' || passport?.expiryDate,
			withClient: '' || passport?.withClient,
		},
	});

	useEffect(() => {
		passport &&
			reset({
				...passport,
				issueDate:
					(passport.issueDate && format(new Date(passport.issueDate), 'yyyy-MM-dd')) || null,
				expiryDate:
					(passport.expiryDate && format(new Date(passport.expiryDate), 'yyyy-MM-dd')) || null,
			});
	}, [reset, passport]);

	const onSubmit = (formData) => {
		if (!passport)
			addPassportSubmitFunction({
				...formData,
				clients: [clientID],
				issueDate: (formData.issueDate && formData.issueDate + 'T00:00') || null,
				expiryDate: (formData.expiryDate && formData.expiryDate + 'T00:00') || null,
			});
		if (passport)
			updatePassportSubmitFunction({
				...formData,
				issueDate: (formData.issueDate && formData.issueDate + 'T00:00') || null,
				expiryDate: (formData.expiryDate && formData.expiryDate + 'T00:00') || null,
			});
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('country')}
						id='country'
						type='text'
						list='countries'
						placeholder='Country'
						required
					/>
					<label htmlFor='country'>Country</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='countries'>
						{countriesList.map((country) => (
							<option key={country} value={country} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('number')}
						id='number'
						type='text'
						placeholder='Number'
					/>
					<label htmlFor='number'>Number</label>
				</div>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('issueDate')}
						max='2999-12-31'
						id='issueDate'
						type='date'
						placeholder='Issue Date'
					/>
					<label htmlFor='issueDate'>Issue Date</label>
				</div>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('expiryDate')}
						max='2999-12-31'
						id='expiryDate'
						type='date'
						placeholder='Expiry Date'
					/>
					<label htmlFor='expiryDate'>Expiry Date</label>
				</div>
				<div className='form-floating mb-3'>
					<select
						className='form-select'
						{...register('withClient')}
						id='withClient'
						aria-label='with Client'
					>
						<option value='Yes'>Yes</option>
						<option value='No with ICE'>No, with ICE</option>
					</select>
					<label htmlFor='withClient'>with Client</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
