import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import SaveButton from '../../../../app/components/SaveButton';
import CancelButton from '../../../../app/components/CancelButton';
import {
	agenciesList,
	immigrationCourtsList,
	filingMethodsList,
} from '../../../../app/components/DataLists';

export default function FilingForm({
	addEventSubmitFunction,
	updateEventSubmitFunction,
	event,
	category,
	caseID,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			originalDateTime: event?.originalDateTime || null,
			agency: event?.agency || null,
			location: event?.location || null,
			regarding: event?.regarding || null,
			note: event?.note || null,
		},
	});

	useEffect(() => {
		event &&
			reset({
				...event,
				originalDateTime:
					(event.originalDateTime && format(new Date(event.originalDateTime), 'yyyy-MM-dd')) ||
					null,
			});
	}, [reset, event]);

	const onSubmit = (formData) => {
		if (!event) {
			addEventSubmitFunction({
				...formData,
				cases: [caseID],
				category: category,
				status: 'in-progress',
				originalDateTime:
					(formData.originalDateTime && formData.originalDateTime + 'T00:00') || null,
			});
		}
		if (event) {
			updateEventSubmitFunction({
				...formData,
				originalDateTime:
					(formData.originalDateTime && formData.originalDateTime + 'T00:00') || null,
			});
		}
	};

	return (
		<>
			{/* <h3>Filing (no deadline)</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('originalDateTime')}
						max='2999-12-31'
						id='date'
						type='date'
						placeholder='Date'
						required
					/>
					<label htmlFor='originalDateTime'>Date</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						id='agency'
						{...register('agency')}
						list='agencies'
						type='text'
						placeholder='Agency'
						required
					/>
					<label htmlFor='agency'>Agency</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='agencies'>
						{agenciesList.map((agency) => (
							<option key={agency} value={agency} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('location')}
						id='location'
						list='courtsLocations'
						type='text'
						placeholder='Location'
					/>
					<label htmlFor='location'>Location</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='courtsLocations'>
						{immigrationCourtsList.map((location) => (
							<option key={location} value={location} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('filingTitle')}
						id='filingTitle'
						type='text'
						placeholder='Filing Title'
						required
					/>
					<label htmlFor='filingTitle'>Filing Title</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('filingMethod')}
						id='filingMethod'
						list='filingMethods'
						type='text'
						placeholder='Filing Method'
					/>
					<label htmlFor='filingMethod'>Filing Method</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='filingMethods'>
						{filingMethodsList.map((method) => (
							<option key={method} value={method} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('regarding')}
						id='regarding'
						type='text'
						placeholder='Regarding'
					/>
					<label htmlFor='regarding'>Regarding</label>
				</div>

				<div className='form-floating mb-3'>
					<textarea
						className='form-control'
						{...register('note')}
						id='note'
						placeholder='Note'
						style={{ height: '10rem' }}
					></textarea>
					<label htmlFor='note'>Note</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
