// import EventForm from '../components/forms/Event-Form';
import { useContext, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import HearingForm from '../components/forms/events/Hearing-Form';
import FilingForm from '../components/forms/events/Filing-Form';
import FilingNDForm from '../components/forms/events/FilingND-Form';
import InterviewForm from '../components/forms/events/Interview-Form';
import AppointmentForm from '../components/forms/events/Appointment-Form';
import ReminderForm from '../components/forms/events/Reminder-Form';
import ReceivedDocumentForm from '../components/forms/events/Received-Document-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container, Modal } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { addEvent, getCase } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function AddEventPage() {
	const { addEvent, getCase } = useContext(RestQueriesContext);
	const { client } = useContext(AppContext);
	const history = useHistory();
	const { caseID } = useParams();
	const location = useLocation();
	const { category, showModal } = location.state;

	const [show, setShow] = useState(false || showModal);

	const {
		isLoading,
		isError,
		data: cCase,
		error,
	} = useQuery(['getCase', caseID], () => getCase(caseID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => addEvent(formData), {
		onSuccess: (data) => {
			history.push({
				// pathname: '/view-client/' + client.id,
				pathname: `/view-client/${client.id}/${cCase.id}`,
				state: data,
			});
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} cCase={cCase} caption={`Add ${category}`} />

				{category === 'Filing Deadline' && (
					<Modal size='lg' show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>
								<FormTitle client={client} cCase={cCase} caption={`Add ${category}`} />
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<FilingForm
								addEventSubmitFunction={onSubmit}
								caseID={cCase.id}
								category={category}
								clientID={client.id}
							/>
						</Modal.Body>
					</Modal>
				)}

				{/* {category === 'Filing Deadline' && (
					<FilingForm
						addEventSubmitFunction={onSubmit}
						caseID={cCase.id}
						category={category}
						clientID={client.id}
					/>
				)} */}

				{category === 'Filing (no deadline)' && (
					<FilingNDForm
						addEventSubmitFunction={onSubmit}
						caseID={cCase.id}
						category={category}
						clientID={client.id}
					/>
				)}

				{category === 'Hearing' && (
					<HearingForm addEventSubmitFunction={onSubmit} caseID={cCase.id} category={category} />
				)}

				{category === 'Interview' && (
					<InterviewForm addEventSubmitFunction={onSubmit} caseID={cCase.id} category={category} />
				)}

				{category === 'Appointment' && (
					<AppointmentForm
						addEventSubmitFunction={onSubmit}
						caseID={cCase.id}
						category={category}
					/>
				)}

				{category === 'Reminder' && (
					<ReminderForm addEventSubmitFunction={onSubmit} caseID={cCase.id} category={category} />
				)}

				{category === 'Received Document' && (
					<ReceivedDocumentForm
						addEventSubmitFunction={onSubmit}
						caseID={cCase.id}
						category={category}
					/>
				)}
			</Container>
		</>
	);
}
