import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// import { format } from 'date-fns';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function QuickNoteForm({
	addQuickNoteSubmitFunction,
	updateQuickNoteSubmitFunction,
	quickNote,
	caseID,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			application: quickNote?.application || null,
			receiptNumber: quickNote?.receiptNumber || null,
		},
	});

	useEffect(() => {
		quickNote && reset(quickNote);
	}, [reset, quickNote]);

	const onSubmit = (formData) => {
		if (!quickNote) {
			addQuickNoteSubmitFunction({
				...formData,
				cases: [caseID],
			});
		}
		if (quickNote) {
			updateQuickNoteSubmitFunction(formData);
		}
	};

	return (
		<>
			{/* <h3>Quick Note</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('application')}
						id='application'
						type='text'
						placeholder='Application'
					/>
					<label htmlFor='application'>Application</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('receiptNumber')}
						id='receiptNumber'
						type='text'
						placeholder='Receipt Number'
						required
					/>
					<label htmlFor='receiptNumber'>Receipt Number</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
