import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import ClientNoteForm from '../../client/components/forms/Client-Note-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
// import { addClientNote } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function AddClientNotePage() {
	const { addClientNote } = useContext(RestQueriesContext);
	const { client } = useContext(AppContext);
	const history = useHistory();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => addClientNote(formData), {
		onSuccess: (data) => {
			history.push({
				pathname: '/view-client/' + client.id,
				state: data,
			});
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isMutationLoading) return <div>Loading...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} caption='Add Note' />
				<ClientNoteForm addClientNoteSubmitFunction={onSubmit} clientID={client.id} />
			</Container>
		</>
	);
}
