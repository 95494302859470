import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import QuickNoteForm from '../components/forms/Quick-Note-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateQuickNote, getQuickNote } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateQuickNotePage() {
	const history = useHistory();
	const { client, cCase } = useContext(AppContext);
	const { updateQuickNote, getQuickNote } = useContext(RestQueriesContext);
	const { quickNoteID } = useParams();

	const {
		isLoading,
		isError,
		data: quickNote,
		error,
	} = useQuery(['getQuickNote', quickNoteID], () => getQuickNote(quickNoteID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateQuickNote(quickNoteID, formData), {
		onSuccess: () => {
			// history.push('/view-client/' + client.id);
			history.push(`/view-client/${client.id}/${cCase.id}`);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} cCase={cCase} caption='Edit Quick Note' />
				<QuickNoteForm
					updateQuickNoteSubmitFunction={onSubmit}
					caseID={cCase.id}
					quickNote={quickNote}
				/>
			</Container>
		</>
	);
}
