import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import NotSet from '../../../../app/components/NotSet';
import { formatANum, formatPhoneNum, confDeleteFn } from '../../../../app/utils/helper-functions';

export default function SpouseView({ spouse, mutate }) {
	const location = useLocation();
	return spouse?.length === 0 ? null : (
		<>
			<h5 className='text-center'>Spouse</h5>
			<Table bordered size='sm' className='text-center'>
				<thead>
					<tr>
						<th>Name</th>
						<th>Phone</th>
						<th>Email</th>
						{/* <th>Address</th> */}
						<th>Relation</th>
						<th>Date of Birth</th>
						{/* <th>Nationality</th> */}
						{/* <th>Religion</th> */}
						{/* <th>Sect</th> */}
						{/* <th>Ethnicity</th> */}
						<th>Immigration Status</th>
						{/* <th>Status Exp. Date</th> */}
						<th>A-Number</th>
						<th>Role</th>
						<th>Note</th>
						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{spouse?.map((spouse) => (
						<tr key={spouse.id}>
							<td>
								{spouse.firstName} {spouse.lastName}
							</td>
							<td>{(spouse.phone && formatPhoneNum(spouse.phone)) || <NotSet />}</td>
							<td>{spouse.email || <NotSet />}</td>
							{/* <td>{spouse.address || <NotSet />}</td> */}
							<td>{spouse.relationToClient || <NotSet />}</td>
							<td>
								{(spouse.dateOfBirth && format(new Date(spouse.dateOfBirth), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td>
							{/* <td>{spouse.nationality || <NotSet />}</td> */}
							{/* <td>{spouse.religion || <NotSet />}</td> */}
							{/* <td>{spouse.sect || <NotSet />}</td> */}
							{/* <td>{spouse.ethnicity || <NotSet />}</td> */}
							<td>{spouse.immigrationStatus || <NotSet />}</td>
							{/* <td>
								{(spouse.immigrationStatusExpiryDate &&
									format(new Date(spouse.immigrationStatusExpiryDate), 'MM/dd/yyyy')) || <NotSet />}
							</td> */}
							<td>{(spouse.aNumber && formatANum(spouse.aNumber)) || <NotSet />}</td>
							<td>{spouse.role || <NotSet />}</td>
							<td>{spouse.note || <NotSet />}</td>

							<td>
								<Link
									to={{
										pathname: `/update-family-member/${spouse.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td>
								<Button variant='link' onClick={() => confDeleteFn(spouse.id, mutate, 'Spouse')}>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
}
