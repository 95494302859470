import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { confDeleteFn } from '../../../app/utils/confirm-delete';
import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
// import { removeLanguage } from '../../../api/restQueries';
import { RestQueriesContext } from '../../../api/restQueries';

export default function LanguagesView() {
	const { removeLanguage } = useContext(RestQueriesContext);
	const { client, refetchClient: refetch } = useContext(AppContext);
	const location = useLocation();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeLanguage(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing language...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return client?.languages?.length === 0 ? (
		<div>
			<Link to={{ pathname: `/add-language/${client.id}`, state: { prevURL: location.pathname } }}>
				+ Language
			</Link>
		</div>
	) : (
		<>
			<Table bordered size='sm'>
				<thead>
					<tr>
						<th width='33.3%'>Language</th>
						<th width='33.3%'>Fluency</th>
						<th colSpan='2' width='33.3%'>
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					{client?.languages?.map((language) => (
						<tr key={language.id}>
							<td>{language.language}</td>
							<td>{language.fluency}</td>
							<td width='16.65%'>
								<Link
									to={{
										pathname: `/update-language/${language.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td width='16.65%'>
								{/* <Button variant='link' onClick={() => mutate(language.id)}> */}
								<Button
									variant='link'
									onClick={() => confDeleteFn(language.id, mutate, 'Language')}
								>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<div>
				<Link
					to={{ pathname: `/add-language/${client.id}`, state: { prevURL: location.pathname } }}
				>
					+ Language
				</Link>
			</div>
		</>
	);
}
