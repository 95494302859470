import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { confDeleteFn } from '../../../app/utils/confirm-delete';
import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import NotSet from '../../../app/components/NotSet';
// import { removePassport } from '../../../api/restQueries';
import { RestQueriesContext } from '../../../api/restQueries';

export default function PassportsView() {
	const { removePassport } = useContext(RestQueriesContext);
	const { client, refetchClient: refetch } = useContext(AppContext);
	const location = useLocation();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removePassport(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing passport...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return client?.passports?.length === 0 ? (
		<div>
			<Link to={{ pathname: `/add-passport/${client.id}`, state: { prevURL: location.pathname } }}>
				+ Passport
			</Link>
		</div>
	) : (
		<>
			<Table bordered size='sm'>
				<thead>
					<tr>
						<th>Country</th>
						<th>Number</th>
						<th>Issue Date</th>
						<th>Exp. Date</th>
						<th>with Client?</th>
						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{client?.passports?.map((passport) => (
						<tr key={passport.id}>
							<td>{passport.country || <NotSet />}</td>
							<td>{passport.number || <NotSet />}</td>
							<td>
								{(passport.issueDate && format(new Date(passport.issueDate), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td>
							<td>
								{(passport.expiryDate && format(new Date(passport.expiryDate), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td>
							<td>{passport.withClient || <NotSet />}</td>

							<td>
								<Link
									to={{
										pathname: `/update-passport/${passport.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td width='16.65%'>
								{/* <Button variant='link' onClick={() => mutate(passport.id)}> */}
								<Button
									variant='link'
									onClick={() => confDeleteFn(passport.id, mutate, 'Passport')}
								>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>

			<div>
				<Link
					to={{ pathname: `/add-passport/${client.id}`, state: { prevURL: location.pathname } }}
				>
					+ Passport
				</Link>
			</div>
		</>
	);
}
