import { Col, Row } from 'react-bootstrap';
import LoginForm from '../components/forms/Login-Form';
// import { useAuth } from '../auth/use-auth';
import { AuthContext } from '../../contexts/AuthContext';
import { useContext } from 'react';

export default function LoginPage() {
	// const auth = useAuth();
	const { error } = useContext(AuthContext);
	return (
		<>
			<Row>
				<Col className='text-center mt-5'>
					<div>{error && <h4>Incorrect username or password, please try again.</h4>}</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className='vertical-center'>
						<LoginForm />
					</div>
				</Col>
			</Row>
		</>
	);
}
