import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { confDeleteFn } from '../../../../app/utils/confirm-delete';
import { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { format, formatDistanceToNow } from 'date-fns';
import NotSet from '../../../../app/components/NotSet';
import { orderBy } from 'lodash';
// import { removeEvent } from '../../../../api/restQueries';
import { RestQueriesContext } from '../../../../api/restQueries';

export default function ReceivedDocumentsView() {
	const { removeEvent } = useContext(RestQueriesContext);
	const { client, cCase, refetchCase: refetch } = useContext(AppContext);
	const [orderByDateEvents, setOrderByDateEvents] = useState(null);
	const [filteredOrderedEvents, setFilteredOrderedEvents] = useState(null);
	const location = useLocation();
	const events = cCase?.events;

	useEffect(() => {
		if (events) setOrderByDateEvents(orderBy(events, ['originalDateTime'], ['asc']));
	}, [events]);

	useEffect(() => {
		if (orderByDateEvents)
			setFilteredOrderedEvents(
				orderByDateEvents.filter((event) => event.category === 'Received Document')
			);
	}, [orderByDateEvents]);

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeEvent(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing event...</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return filteredOrderedEvents?.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-event/${cCase.id}`,
					state: { prevURL: location.pathname, category: 'Received Document' },
				}}
			>
				+ Document
			</Link>
		</div>
	) : (
		cCase && (
			<>
				<Table bordered size='sm'>
					<thead>
						<tr>
							<th>Received</th>
							<th>Date</th>
							<th>Agency</th>
							<th>Regarding</th>
							<th>Document</th>
							<th>Receipt #</th>
							<th>Note</th>

							<th colSpan='2'>Action</th>
						</tr>
					</thead>
					<tbody>
						{filteredOrderedEvents?.map((event) => {
							return (
								<tr key={event.id}>
									<td>
										{formatDistanceToNow(new Date(event.originalDateTime), { addSuffix: true })}
									</td>
									<td>{format(new Date(event.originalDateTime), 'MM/dd/yyyy')}</td>
									<td>{event.agency}</td>
									<td>{event.regarding || <NotSet />}</td>
									<td>{event.documentReceived}</td>
									<td
										className='copy'
										onClick={() => navigator.clipboard.writeText(event.receiptNumber)}
									>
										{event.receiptNumber || <NotSet />}
									</td>
									<td>{event.note || <NotSet />}</td>
									<td>
										<Link
											to={{
												pathname: `/update-event/${event.id}`,
												state: {
													prevURL: location.pathname,
													category: event.category,
													caseID: cCase.id,
													clientID: client.id,
												},
											}}
										>
											Edit
										</Link>
									</td>
									<td>
										{/* <Button variant='link' onClick={() => mutate(event.id)}> */}
										<Button
											variant='link'
											onClick={() => confDeleteFn(event.id, mutate, 'Document')}
										>
											Remove
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
				<div>
					<Link
						to={{
							pathname: `/add-event/${cCase.id}`,
							state: { prevURL: location.pathname, category: 'Received Document' },
						}}
					>
						+ Document
					</Link>
				</div>
			</>
		)
	);
}
