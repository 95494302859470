import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import NotSet from '../../../app/components/NotSet';
import { orderBy } from 'lodash';
import { useLocation, Link } from 'react-router-dom';
// import { updateEvent } from '../../../api/restQueries';
import { RestQueriesContext } from '../../../api/restQueries';

export default function EventHistoryView() {
	const { updateEvent } = useContext(RestQueriesContext);
	const { cCase, refetchCase: refetch } = useContext(AppContext);
	const location = useLocation();
	const [orderByDateEvents, setOrderByDateEvents] = useState(null);
	const [filteredOrderedEvents, setFilteredOrderedEvents] = useState(null);
	const events = cCase?.events;

	useEffect(() => {
		if (events) setOrderByDateEvents(orderBy(events, ['originalDateTime'], ['asc']));
	}, [events]);

	useEffect(() => {
		if (orderByDateEvents)
			setFilteredOrderedEvents(
				orderByDateEvents.filter(
					(event) =>
						(event.category === 'Filing Deadline' ||
							event.category === 'Filing (no deadline)' ||
							event.category === 'Hearing' ||
							event.category === 'Interview') &&
						event.updateDateTime !== null
				)
			);
	}, [orderByDateEvents]);

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((restoredEvent) => updateEvent(restoredEvent.id, restoredEvent), {
		onSuccess: () => {
			refetch();
		},
	});

	const restoreEvent = (event) => {
		const status =
			event.category === 'Filing Deadline' || event.category === 'Filing (no deadline)'
				? 'in-progress'
				: 'scheduled';
		mutate({ ...event, updateDateTime: null, status: status });
	};

	if (isMutationLoading) return <div>Restoring event...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return filteredOrderedEvents?.length === 0 ? (
		<div>No History/Updates</div>
	) : (
		cCase && (
			<>
				<Table bordered size='sm'>
					<thead>
						<tr>
							<th>Event</th>
							<th>Status</th>
							<th>Update Date</th>
							<th>Original Date</th>
							<th>Regarding</th>
							<th>Note</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{filteredOrderedEvents?.map((event) => {
							return (
								<tr key={event.id}>
									<td>
										{event.type || null} {event.category || <NotSet />}
									</td>
									<td>{event.status || <NotSet />}</td>
									<td>
										{(event.updateDateTime &&
											format(new Date(event.updateDateTime), 'MM/dd/yyyy')) || <NotSet />}
									</td>
									<td>
										{(event.originalDateTime &&
											format(new Date(event.originalDateTime), 'MM/dd/yyyy')) || <NotSet />}
									</td>

									<td>
										{event.category === 'Filing Deadline' ||
										event.category === 'Filing (no deadline)' ? (
											<>
												{event.filingTitle} {' - '}
												<Link
													to={{
														pathname: `/filing-page/${event.id}`,
														state: {
															prevURL: location.pathname,
														},
													}}
												>
													{`Elements (${event.elements?.length})`}
												</Link>
											</>
										) : (
											event.regarding
										)}
									</td>

									<td style={{ whiteSpace: 'pre-line', textAlign: '' }}>
										{event.note || <NotSet />}
									</td>
									<td>
										<Button variant='link' onClick={() => restoreEvent(event)}>
											Restore
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</>
		)
	);
}
