export default function CancelButton({ cancelButtonHandler }) {
	return (
		<div className='d-grid'>
			<button
				className='btn btn-secondary btn-lg'
				id='backButton'
				type='button'
				onClick={cancelButtonHandler}
			>
				Cancel
			</button>
		</div>
	);
}
