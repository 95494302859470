import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';
import { immStatusList, custodyFacilitiesList } from '../../../app/components/DataLists';
import { format } from 'date-fns';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function ImmigrationInformationForm({
	updateImmigrationInformationSubmitFunction,
	client,
}) {
	const location = useLocation();
	const history = useHistory();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit } = useForm({
		defaultValues: {
			aNumber: client?.aNumber || null,
			imm_status: client?.imm_status || null,
			imm_detained: client?.imm_detained || null,
			imm_custodyFacility: client?.imm_custodyFacility || null,
			imm_dateOfArrest:
				(client?.imm_dateOfArrest && format(new Date(client?.imm_dateOfArrest), 'yyyy-MM-dd')) ||
				null,
			imm_statusIssueDate:
				(client?.imm_statusIssueDate &&
					format(new Date(client?.imm_statusIssueDate), 'yyyy-MM-dd')) ||
				null,
			imm_statusExpiryDate:
				(client?.imm_statusExpiryDate &&
					format(new Date(client?.imm_statusExpiryDate), 'yyyy-MM-dd')) ||
				null,
			imm_dateOfRelease:
				(client?.imm_dateOfRelease && format(new Date(client?.imm_dateOfRelease), 'yyyy-MM-dd')) ||
				null,
		},
	});

	const onSubmit = (formData) => {
		updateImmigrationInformationSubmitFunction({
			...formData,
			imm_statusIssueDate:
				(formData.imm_statusIssueDate && formData.imm_statusIssueDate + 'T00:00') || null,
			imm_statusExpiryDate:
				(formData.imm_statusExpiryDate && formData.imm_statusExpiryDate + 'T00:00') || null,
			imm_dateOfArrest: (formData.imm_dateOfArrest && formData.imm_dateOfArrest + 'T00:00') || null,
			imm_dateOfRelease:
				(formData.imm_dateOfRelease && formData.imm_dateOfRelease + 'T00:00') || null,
		});
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Row>
				<Col>
					<div className='form-floating mb-3'>
						<input
							className='form-control'
							{...register('aNumber')}
							id='aNumber'
							type='text'
							placeholder='A-Number'
						/>
						<label htmlFor='aNumber'>A-Number</label>
					</div>
				</Col>
				<Col>
					{' '}
					<div className='form-floating mb-3'>
						<input
							className='form-control'
							{...register('imm_status')}
							id='imm_status'
							type='text'
							list='imm-status'
							placeholder='Immigration Status'
						/>
						<label htmlFor='imm_status'>Immigration Status</label>
						{/* ===============DATALIST OPTIONS================== */}
						<datalist id='imm-status'>
							{immStatusList.map((status) => (
								<option key={status} value={status} />
							))}
						</datalist>
						{/* ================================================= */}
					</div>
				</Col>
				<Col>
					{' '}
					<div className='form-floating mb-3'>
						<input
							className='form-control'
							{...register('imm_statusIssueDate')}
							max='2999-12-31'
							id='imm_statusIssueDate'
							type='date'
							placeholder='Immigration Status Issue Date'
						/>
						<label htmlFor='imm_statusIssueDate'>Status Issue Date</label>
					</div>
				</Col>
				<Col>
					<div className='form-floating mb-3'>
						<input
							className='form-control'
							{...register('imm_statusExpiryDate')}
							max='2999-12-31'
							id='imm_statusExpiryDate'
							type='date'
							placeholder='Immigration Status Expiry Date'
						/>
						<label htmlFor='imm_statusExpiryDate'>Status Expiry Date</label>
					</div>
				</Col>
			</Row>

			<Row>
				<Col>
					{' '}
					<div className='form-floating mb-3'>
						<select className='form-select' {...register('imm_detained')} id='imm_detained'>
							<option value=''></option>
							<option value='No'>No</option>
							<option value='Yes'>Yes</option>
						</select>
						<label htmlFor='imm_detained'>Detained?</label>
					</div>
				</Col>
				<Col>
					{' '}
					<div className='form-floating mb-3'>
						<input
							className='form-control'
							{...register('imm_custodyFacility')}
							id='imm_custodyFacility'
							type='text'
							list='custody-facilities'
							placeholder='Custody Facility'
						/>
						<label htmlFor='imm_custodyFacility'>Custody Facility</label>
						{/* ===============DATALIST OPTIONS================== */}
						<datalist id='custody-facilities'>
							{custodyFacilitiesList.map((facility) => (
								<option key={facility} value={facility} />
							))}
						</datalist>
						{/* ================================================= */}
					</div>
				</Col>
				<Col>
					{' '}
					<div className='form-floating mb-3'>
						<input
							className='form-control'
							{...register('imm_dateOfArrest')}
							max='2999-12-31'
							id='imm_dateOfArrest'
							type='date'
							placeholder='Imm Date of Arrest'
						/>
						<label htmlFor='imm_dateOfArrest'>Date of Arrest</label>
					</div>
				</Col>
				<Col>
					{' '}
					<div className='form-floating mb-3'>
						<input
							className='form-control'
							{...register('imm_dateOfRelease')}
							max='2999-12-31'
							id='imm_dateOfRelease'
							type='date'
							placeholder='Imm Date of Release'
						/>
						<label htmlFor='imm_dateOfRelease'>Date of Release</label>
					</div>
				</Col>
			</Row>

			<Row>
				<Col>
					<CancelButton cancelButtonHandler={cancelButtonHandler} />
				</Col>
				<Col>
					<SaveButton />
				</Col>
			</Row>
		</form>
	);
}
