import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SaveButton from '../../../../app/components/SaveButton';
import CancelButton from '../../../../app/components/CancelButton';

export default function FilingPrepChecklistForm({
	addFilingChecklistItemSubmitFunction,
	updateFilingChecklistItemSubmitFunction,
	eventID,
	item,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			item: item?.item || null,
			status: item?.status || null,
			providedBy: item?.providedBy || null,
			note: item?.note || null,
		},
	});

	useEffect(() => {
		item && reset(item);
	}, [reset, item]);

	const onSubmit = (formData) => {
		if (!item) {
			addFilingChecklistItemSubmitFunction({
				...formData,
				events: [eventID],
			});
		}
		if (item) {
			updateFilingChecklistItemSubmitFunction(formData);
		}
	};

	return (
		<>
			{/* <h3>Filing Checklist</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('item')}
						id='item'
						type='text'
						placeholder='Item'
						required
					/>
					<label htmlFor='item'>Item</label>
				</div>

				<div className='form-floating mb-3'>
					<select className='form-select' {...register('status')} id='status' aria-label='Status'>
						<option value='not-started'>not-started</option>
						<option value='in-progress'>in-progress</option>
						<option value='completed'>completed</option>
					</select>
					<label htmlFor='status'>Status</label>
				</div>

				<div className='form-floating mb-3'>
					<select
						className='form-select'
						{...register('providedBy')}
						id='providedBy'
						aria-label='Provided By'
					>
						<option value='office'>office</option>
						<option value='client'>client</option>
					</select>
					<label htmlFor='providedBy'>Provided By</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('note')}
						id='note'
						type='text'
						placeholder='Note'
					/>
					<label htmlFor='note'>Note</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
