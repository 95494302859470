import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';
import { format } from 'date-fns';

export default function DashbordReminderForm({
	addReminderSubmitFunction,
	updateReminderSubmitFunction,
	reminder,
}) {
	const location = useLocation();
	const history = useHistory();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			date: reminder?.date || format(new Date(), 'yyyy-MM-dd'),
			note: reminder?.note || null,
		},
	});

	useEffect(() => {
		reminder &&
			reset({
				...reminder,
				date: (reminder.date && format(new Date(reminder.date), 'yyyy-MM-dd')) || null,
			});
	}, [reset, reminder]);

	const onSubmit = (formData) => {
		if (!reminder) addReminderSubmitFunction({ ...formData, date: formData.date + 'T00:00' });
		if (reminder) updateReminderSubmitFunction({ ...formData, date: formData.date + 'T00:00' });
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input className='form-control' {...register('date')} max='2999-12-31' type='date' />
							<label htmlFor='date'>Date</label>
						</div>

						<div className='form-floating mb-3'>
							<textarea
								className='form-control'
								{...register('note')}
								id='note'
								placeholder='Note'
								style={{ height: '20rem' }}
								required
							></textarea>
							<label htmlFor='note'>Note</label>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
