import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { format } from 'date-fns';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function ClientNoteForm({
	addClientNoteSubmitFunction,
	updateClientNoteSubmitFunction,
	note,
	clientID,
}) {
	const location = useLocation();
	const history = useHistory();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			date: note?.date || null,
			title: note?.title || null,
			body: note?.body || null,
		},
	});

	useEffect(() => {
		note &&
			reset({ ...note, date: (note.date && format(new Date(note.date), 'yyyy-MM-dd')) || null });
	}, [reset, note]);

	const onSubmit = (formData) => {
		if (!note) {
			addClientNoteSubmitFunction({
				...formData,
				clients: [clientID],
				date: (formData.date && formData.date + 'T00:00') || null,
			});
		}

		if (note) {
			updateClientNoteSubmitFunction({
				...formData,
				date: (formData.date && formData.date + 'T00:00') || null,
			});
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Row>
				<Col>
					<div className='form-floating mb-3'>
						<input
							className='form-control'
							{...register('title')}
							id='title'
							type='text'
							placeholder='Title'
						/>
						<label htmlFor='title'>Title</label>
					</div>
				</Col>
				<Col>
					<div className='form-floating mb-3'>
						<input
							className='form-control'
							{...register('date')}
							max='2999-12-31'
							id='date'
							type='date'
							placeholder='Date'
						/>
						<label htmlFor='date'>Date</label>
					</div>
				</Col>
			</Row>

			<div className='form-floating mb-3'>
				<textarea
					className='form-control'
					{...register('body')}
					id='body'
					placeholder='Note'
					style={{ height: '10rem' }}
					required
				></textarea>
				<label htmlFor='body'>Note</label>
			</div>

			<Row>
				<Col>
					<CancelButton cancelButtonHandler={cancelButtonHandler} />
				</Col>
				<Col>
					<SaveButton />
				</Col>
			</Row>
		</form>
	);
}
