import { useContext, useEffect } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { confDeleteFn } from '../../../../app/utils/confirm-delete';
import { Table, Button } from 'react-bootstrap';
import { useParams, useLocation, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import NotSet from '../../../../app/components/NotSet';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
// import { getFilingElement, removeSupportingDocument } from '../../../../api/restQueries';
import { RestQueriesContext } from '../../../../api/restQueries';

export default function SupportingDocumentsView() {
	const { getFilingElement, removeSupportingDocument } = useContext(RestQueriesContext);
	const { selectElement, refetchElementFn, refetchElement: refetch } = useContext(AppContext);
	const { elementID } = useParams();
	const location = useLocation();

	const {
		isLoading,
		isError,
		data: element,
		error,
		refetch: refetchElement,
	} = useQuery(['getFilingElement', elementID], () => getFilingElement(elementID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeSupportingDocument(id), {
		onSuccess: () => {
			refetch();
		},
	});

	useEffect(() => {
		if (element) {
			selectElement(element);
			refetchElementFn(refetchElement);
		}
		// eslint-disable-next-line
	}, [element, refetchElement]);

	if (isLoading) return <div> Loding...</div>;
	if (isMutationLoading) return <div>Removing document...</div>;

	if (isError) return <div>A mutation error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return element?.supporting_documents.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-supporting-document/${element.id}`,
					state: { prevURL: location.pathname },
				}}
			>
				+ Supporting Document
			</Link>
		</div>
	) : (
		<>
			<Table bordered size='sm'>
				<thead>
					<tr>
						<th>Category</th>
						<th>Name</th>
						<th>From</th>
						<th>To</th>
						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{element?.supporting_documents.map((document) => {
						return (
							<tr key={document.id}>
								<td>{document.category || <NotSet />}</td>
								<td>{document.name || <NotSet />}</td>
								<td>
									{(document.fromDate && format(new Date(document.fromDate), 'MM/dd/yyyy')) || (
										<NotSet />
									)}
								</td>
								<td>
									{(document.toDate && format(new Date(document.toDate), 'MM/dd/yyyy')) || (
										<NotSet />
									)}
								</td>

								<td>
									<Link
										to={{
											pathname: `/update-supporting-document/${document.id}`,
											state: {
												prevURL: location.pathname,
											},
										}}
									>
										Edit
									</Link>
								</td>
								<td>
									{/* <Button variant='link' onClick={() => mutate(document.id)}> */}
									<Button
										variant='link'
										onClick={() => confDeleteFn(document.id, mutate, 'Supporting Document')}
									>
										Remove
									</Button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
			<div>
				<Link
					to={{
						pathname: `/add-supporting-document/${element.id}`,
						state: { prevURL: location.pathname },
					}}
				>
					+ Supporting Document
				</Link>
			</div>
		</>
	);
}
