import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function HistoryUpdateForm({
	addCaseUpdateSubmitFunction,
	updateCaseUpdateSubmitFunction,
	caseUpdate,
	caseID,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			date: caseUpdate?.date || null,
			title: caseUpdate?.title || null,
			note: caseUpdate?.note || null,
		},
	});

	useEffect(() => {
		caseUpdate &&
			reset({
				...caseUpdate,
				date: (caseUpdate.date && format(new Date(caseUpdate.date), 'yyyy-MM-dd')) || null,
			});
	}, [reset, caseUpdate]);

	const onSubmit = (formData) => {
		if (!caseUpdate) {
			addCaseUpdateSubmitFunction({
				...formData,
				cases: [caseID],
				date: (formData.date && formData.date + 'T00:00') || null,
			});
		}
		if (caseUpdate) {
			updateCaseUpdateSubmitFunction({
				...formData,
				date: (formData.date && formData.date + 'T00:00') || null,
			});
		}
	};

	return (
		<>
			{/* <h3>Case History / Updates</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						max='2999-12-31'
						{...register('date')}
						id='date'
						type='date'
						placeholder='Date'
						required
					/>
					<label htmlFor='date'>Date</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('title')}
						id='title'
						type='text'
						placeholder='Title'
					/>
					<label htmlFor='title'>Title</label>
				</div>

				<div className='form-floating mb-3'>
					<textarea
						className='form-control'
						{...register('note')}
						id='note'
						type='text'
						placeholder='Note'
						style={{ height: '10rem' }}
						required
					></textarea>
					<label htmlFor='note'>Note</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
