import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import FilingElementForm from '../components/forms/events/Filing-Element-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateFilingElement, getFilingElement } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdateFilingElementPage() {
	const { updateFilingElement, getFilingElement } = useContext(RestQueriesContext);
	const { client, cCase, event } = useContext(AppContext);
	const history = useHistory();
	const { elementID } = useParams();

	const {
		isLoading,
		isError,
		data: element,
		error,
	} = useQuery(['getFilingElement', elementID], () => getFilingElement(elementID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updateFilingElement(elementID, formData), {
		onSuccess: () => {
			history.push('/filing-page/' + event.id);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} cCase={cCase} caption='Edit Filing Element' />
				<FilingElementForm updateFilingElementSubmitFunction={onSubmit} element={element} />
			</Container>
		</>
	);
}
