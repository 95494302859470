import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import NotSet from '../../../app/components/NotSet';
import { confDeleteFn } from '../../../app/utils/confirm-delete';
// import { removeClientNote } from '../../../api/restQueries';
import { RestQueriesContext } from '../../../api/restQueries';

export default function ClientNotesView() {
	const { removeClientNote } = useContext(RestQueriesContext);
	const { client, refetchClient: refetch } = useContext(AppContext);
	const location = useLocation();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeClientNote(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing note...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return client?.client_notes?.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-client-note/${client.id}`,
					state: { prevURL: location.pathname },
				}}
			>
				+ Note
			</Link>
		</div>
	) : (
		<>
			<Table bordered size='sm'>
				<thead>
					<tr>
						<th>Date</th>
						<th>Title</th>
						<th>Note</th>
						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{client?.client_notes?.map((note) => (
						<tr key={note.id}>
							<td>{(note.date && format(new Date(note.date), 'MM/dd/yyyy')) || <NotSet />}</td>
							<td>{note.title || <NotSet />}</td>
							<td style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>{note.body}</td>
							<td>
								<Link
									to={{
										pathname: `/update-client-note/${note.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td>
								{/* <Button variant='link' onClick={() => mutate(note.id)}> */}
								<Button variant='link' onClick={() => confDeleteFn(note.id, mutate, 'Note')}>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<div className='text-center'>
				<Link
					to={{
						pathname: `/add-client-note/${client.id}`,
						state: { prevURL: location.pathname },
					}}
				>
					+ Note
				</Link>
			</div>
		</>
	);
}
