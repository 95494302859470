import { Form, FormControl, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

export default function NavBarSearchForm() {
	const { register, handleSubmit } = useForm();
	const history = useHistory();

	const onSubmit = ({ searchTerm }) => {
		if (!searchTerm) alert('Please enter a Name, A-Number or a Phone Number');
		history.push(`/search-results/${searchTerm}`);
	};

	return (
		<Form className='d-flex' onSubmit={handleSubmit(onSubmit)}>
			<FormControl
				type='search'
				placeholder='First name - Last name - A# - Phone# - Country - Religion - Gender'
				className='me-2'
				{...register('searchTerm')}
				style={{ fontSize: '1.1rem' }}
			/>

			<Button variant={'outline-light'} type='submit'>
				Search
			</Button>
		</Form>
	);
}
