import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { confDeleteFn } from '../../../app/utils/confirm-delete';
import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { RestQueriesContext } from '../../../api/restQueries';

export default function QuickNotesView() {
	const { removeQuickNote } = useContext(RestQueriesContext);
	const { cCase, refetchCase: refetch } = useContext(AppContext);
	const location = useLocation();
	const quickNotes = cCase?.case_quick_access_notes;

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeQuickNote(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing quick note...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return quickNotes?.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-case-quick-note/${cCase.id}`,
					state: { prevURL: location.pathname },
				}}
			>
				+ Quick Note
			</Link>
		</div>
	) : (
		cCase && (
			<>
				<Table bordered size='sm'>
					<thead>
						<tr>
							<th>Application</th>
							<th>Receipt #</th>
							<th colSpan='2'>Action</th>
						</tr>
					</thead>
					<tbody>
						{quickNotes?.map((quickNote) => (
							<tr key={quickNote.id}>
								<td>{quickNote.application}</td>
								<td
									className='copy'
									onClick={() => navigator.clipboard.writeText(quickNote.receiptNumber)}
								>
									{quickNote.receiptNumber}
								</td>
								<td>
									<Link
										to={{
											pathname: `/update-case-quick-note/${quickNote.id}`,
											state: { prevURL: location.pathname },
										}}
									>
										Edit
									</Link>
								</td>
								<td>
									{/* <Button variant='link' onClick={() => mutate(quickNote.id)}> */}
									<Button
										variant='link'
										onClick={() => confDeleteFn(quickNote.id, mutate, 'Quick Note')}
									>
										Remove
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				<div className='text-center'>
					<Link
						to={{
							pathname: `/add-case-quick-note/${cCase.id}`,
							state: { prevURL: location.pathname },
						}}
					>
						+ Quick Note
					</Link>
				</div>
			</>
		)
	);
}
