import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import PassportForm from '../../client/components/forms/Passport-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updatePassport, getPassport } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdatePassportPage() {
	const { client } = useContext(AppContext);
	const { updatePassport, getPassport } = useContext(RestQueriesContext);
	const history = useHistory();
	const { passportID } = useParams();

	const {
		isLoading,
		isError,
		data: passport,
		error,
	} = useQuery(['getPassport', passportID], () => getPassport(passportID));

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updatePassport(passportID, formData), {
		onSuccess: () => {
			history.push('/view-client/' + client.id);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} caption={`${passport.country} Passport`} />
				<PassportForm updatePassportSubmitFunction={onSubmit} passport={passport} />
			</Container>
		</>
	);
}
