import { Table, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import NotSet from '../../../../app/components/NotSet';
import { formatANum, formatPhoneNum, confDeleteFn } from '../../../../app/utils/helper-functions';

export default function ParentsView({ parents, mutate }) {
	const location = useLocation();
	return parents?.length === 0 ? null : (
		<>
			<h5>Parents</h5>
			<Table bordered size='sm' className='text-center'>
				<thead>
					<tr>
						<th>Name</th>
						<th>Phone</th>
						<th>Email</th>
						{/* <th>Address</th> */}
						<th>Relation</th>
						<th>Date of Birth</th>
						<th>Immigration Status</th>
						{/* <th>Status Expiry Date</th> */}
						<th>A-Number</th>
						<th>Role</th>
						{/* <th>Note</th> */}
						<th colSpan='2'>Action</th>
					</tr>
				</thead>
				<tbody>
					{parents?.map((parent) => (
						<tr key={parent.id}>
							<td>
								{parent.firstName} {parent.lastName}
							</td>
							<td>{(parent.phone && formatPhoneNum(parent.phone)) || <NotSet />}</td>
							<td>{parent.email || <NotSet />}</td>
							{/* <td>{parent.address || <NotSet />}</td> */}
							<td>{parent.relationToClient || <NotSet />}</td>
							<td>
								{(parent.dateOfBirth && format(new Date(parent.dateOfBirth), 'MM/dd/yyyy')) || (
									<NotSet />
								)}
							</td>
							<td>{parent.immigrationStatus || <NotSet />}</td>
							{/* <td>
								{(parent.immigrationStatusExpiryDate &&
									format(new Date(parent.immigrationStatusExpiryDate), 'MM/dd/yyyy')) || <NotSet />}
							</td> */}
							<td>{(parent.aNumber && formatANum(parent.aNumber)) || <NotSet />}</td>
							<td>{parent.role || <NotSet />}</td>
							{/* <td>{parent.note || <NotSet />}</td> */}
							<td>
								<Link
									to={{
										pathname: `/update-family-member/${parent.id}`,
										state: { prevURL: location.pathname },
									}}
								>
									Edit
								</Link>
							</td>
							<td>
								<Button variant='link' onClick={() => confDeleteFn(parent.id, mutate, 'Parent')}>
									Remove
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
}
