import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import PersonalInformationForm from '../components/forms/Personal-Information-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
// import { updatePersonalInformation } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';

export default function UpdatePersonalInformationPage() {
	const { updatePersonalInformation } = useContext(RestQueriesContext);
	const { client } = useContext(AppContext);
	const history = useHistory();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((formData) => updatePersonalInformation(client.id, formData), {
		onSuccess: () => {
			history.push('/view-client/' + client.id);
		},
	});

	const onSubmit = async (data) => {
		mutate(data);
	};

	if (isMutationLoading) return <div>Mutation Loading...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} caption='Personal Information' />
				<PersonalInformationForm
					updatePersonalInformationSubmitFunction={onSubmit}
					client={client}
				/>
			</Container>
		</>
	);
}
