import { Row, Col } from 'react-bootstrap';
import NotSet from '../../app/components/NotSet';
import { Link } from 'react-router-dom';

export default function FormTitle({ client, cCase, caption }) {
	return (
		<>
			<Row className='mb-4'>
				<Col>
					<Link to={`/view-client/${client?.id}/${cCase?.id}`}>
						{/* <Link to={`/view-client/${client?.id}`}> */}
						<h6>
							{client?.lastName}, {client?.firstName} -
							{(client?.aNumber && ` A${client?.aNumber}`) || <NotSet a='A#' />}
						</h6>
						{cCase && <h6>Case: {cCase.type}</h6>}
					</Link>
				</Col>
			</Row>
			<Row>
				<Col className='text-center'>
					<h3>{caption}</h3>
				</Col>
			</Row>
		</>
	);
}
