import { useEffect, useContext, useState } from 'react';
import { ListGroup, Table, Accordion, Row, Col } from 'react-bootstrap';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { AppContext } from '../../../contexts/AppContext';
import { orderBy } from 'lodash';

// export default function CasesView({ client, url }) {
export default function CasesView({ url }) {
	const [orderedCases, setOrderedCases] = useState(null);
	// const [caseHeading, setCaseHeading] = useState(null);
	// const [caseDateOpened, setCaseDateOpened] = useState(null);
	const { client, cCase, selectCase } = useContext(AppContext);
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (client) {
			setOrderedCases(orderBy(client?.cases, ['dateOpened'], ['desc']));
		}
	}, [client]);

	// useEffect(() => {
	// 	if (cCase) {
	// 		setCaseHeading(`${cCase.type} - ${cCase.agency}`);
	// 		setCaseDateOpened(format(new Date(cCase?.dateOpened), 'MMMM dd, yyyy'));
	// 		return;
	// 	}
	// 	setCaseHeading('Please select a case');
	// 	setCaseDateOpened(null);
	// }, [cCase]);

	return client?.cases?.length === 0 ? (
		<div className='text-center mt-5'>
			<button
				className='btn btn-primary'
				onClick={() =>
					history.push({
						pathname: '/create-case',
						state: { prevURL: location.pathname },
					})
				}
			>
				+ New Case
			</button>
		</div>
	) : (
		<Row style={{ background: '#ffffff', padding: '15px', marginTop: '0' }}>
			<Col md={5}>
				<Accordion className='accordion-select-case'>
					<Accordion.Item eventKey='0'>
						<Accordion.Header>Select Case</Accordion.Header>
						<Accordion.Body>
							{/* =================== LIST ================ */}
							<ListGroup>
								{orderedCases?.map((c) => (
									<div key={c.id}>
										<ListGroup.Item>
											<Table borderless className='p-0 m-0'>
												<tbody>
													<tr>
														<td width='75%'>
															<Link
																to={{
																	pathname: `${url}/${c.id}`,
																	state: { prevURL: location.pathname },
																}}
																onClick={() => {
																	selectCase(c);
																}}
															>
																{c.type} - {c.agency} - [ {c.officeCaseStatus.toUpperCase()} ]
															</Link>
														</td>
														<td className='text-center'>
															<Link
																to={{
																	pathname: `/update-case/${c.id}`,
																	state: { prevURL: location.pathname },
																}}
																className='btn btn-outline-primary'
															>
																Edit
															</Link>
														</td>
													</tr>
												</tbody>
											</Table>
										</ListGroup.Item>
									</div>
								))}
							</ListGroup>
							{/* =================== LIST ================ */}

							<hr />
							{/* =================== + Case ================ */}
							<div className='text-center'>
								<button
									className='btn btn-primary'
									onClick={() =>
										history.push({
											pathname: '/create-case',
											state: { prevURL: location.pathname },
										})
									}
								>
									+ New Case
								</button>
							</div>
							{/* =================== + Case ================ */}
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Col>
			<Col className='text-center'>
				{/* <h4>{caseHeading}</h4>
				<h5>{caseDateOpened}</h5> */}
				{cCase ? (
					<>
						<h4>
							{cCase.type} - {cCase.agency}{' '}
						</h4>
						<h5>{cCase && format(new Date(cCase.dateOpened), 'MMMM dd, yyyy')}</h5>
					</>
				) : (
					<h4>Please select a case.</h4>
				)}
			</Col>
		</Row>
	);
}
