import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { confDeleteFn } from '../../../../app/utils/confirm-delete';
import { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import NotSet from '../../../../app/components/NotSet';
import { orderBy } from 'lodash';
// import { removeEvent, updateEvent } from '../../../../api/restQueries';
import { RestQueriesContext } from '../../../../api/restQueries';
import { format, formatDistanceToNow } from 'date-fns';

export default function RemindersView() {
	const { removeEvent, updateEvent } = useContext(RestQueriesContext);
	const { client, cCase, refetchCase: refetch } = useContext(AppContext);
	const [orderByDateEvents, setOrderByDateEvents] = useState(null);
	const [filteredOrderedEvents, setFilteredOrderedEvents] = useState(null);
	const location = useLocation();
	const events = cCase?.events;

	useEffect(() => {
		if (events) setOrderByDateEvents(orderBy(events, ['originalDateTime'], ['asc']));
	}, [events]);

	useEffect(() => {
		if (orderByDateEvents)
			setFilteredOrderedEvents(
				orderByDateEvents.filter(
					(event) => event.category === 'Reminder' && event.updateDateTime === null
				)
			);
	}, [orderByDateEvents]);

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeEvent(id), {
		onSuccess: () => {
			refetch();
		},
	});

	const {
		mutate: mutateUpdate,
		isError: isUpdateMutationError,
		error: updateMutationError,
		isLoading: isUpdateMutationLoading,
	} = useMutation((confirmedEvent) => updateEvent(confirmedEvent.id, confirmedEvent), {
		onSuccess: () => {
			refetch();
		},
	});

	const confirmReminder = (event) => {
		if (window.confirm('confirming Reminder will remove it from the list, continue ?')) {
			mutateUpdate({ ...event, status: 'confirmed' });
			return true;
		} else {
			return false;
		}
	};

	if (isMutationLoading) return <div>Removing event...</div>;
	if (isUpdateMutationLoading) return <div>Updating event...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;
	if (isUpdateMutationError)
		return <div>A mutation error occurred: {updateMutationError.message}</div>;

	return filteredOrderedEvents?.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-event/${cCase.id}`,
					state: { prevURL: location.pathname, category: 'Reminder' },
				}}
			>
				+ Reminder
			</Link>
		</div>
	) : (
		cCase && (
			<>
				<Table bordered size='sm'>
					<thead>
						<tr>
							<th>Count Down</th>
							<th>Date</th>
							<th>Regarding</th>
							<th>Note</th>
							<th>Status</th>
							<th colSpan='2'>Action</th>
						</tr>
					</thead>
					<tbody>
						{filteredOrderedEvents?.map((event) => {
							return (
								<tr key={event.id}>
									<td>
										{formatDistanceToNow(new Date(event.originalDateTime), { addSuffix: true })}
									</td>
									<td>{format(new Date(event.originalDateTime), 'E - MMM dd, yyyy - hh:mm a')}</td>
									<td>{event.regarding || <NotSet />}</td>
									<td>{event.note || <NotSet />}</td>
									<td>
										<Button variant='link' onClick={() => confirmReminder(event)}>
											{event.status}
										</Button>
									</td>
									<td>
										<Link
											to={{
												pathname: `/update-event/${event.id}`,
												state: {
													prevURL: location.pathname,
													category: event.category,
													caseID: cCase.id,
													clientID: client.id,
												},
											}}
										>
											Edit
										</Link>
									</td>
									<td>
										{/* <Button variant='link' onClick={() => mutate(event.id)}> */}
										<Button
											variant='link'
											onClick={() => confDeleteFn(event.id, mutate, 'Reminder')}
										>
											Remove
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
				<div>
					<Link
						to={{
							pathname: `/add-event/${cCase.id}`,
							state: { prevURL: location.pathname, category: 'Reminder' },
						}}
					>
						+ Reminder
					</Link>
				</div>
			</>
		)
	);
}
