import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import SaveButton from '../../../app/components/SaveButton';
// import { useAuth } from '../../auth/use-auth';
import { AuthContext } from '../../../contexts/AuthContext';
import { useContext } from 'react';
export default function LoginForm() {
	// const auth = useAuth();
	const { signin } = useContext(AuthContext);
	const { register, handleSubmit, reset } = useForm();

	useEffect(() => {
		reset();
	}, [reset]);

	const onSubmit = (formData) => {
		signin(formData.username, formData.password);
	};

	return (
		<>
			{/* <h3>Login</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('username')}
						id='username'
						type='text'
						placeholder='username'
						required
					/>
					<label htmlFor='username'>Username</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('password')}
						id='password'
						type='password'
						placeholder='Receipt Number'
						required
					/>
					<label htmlFor='password'>Password</label>
				</div>

				<Row>
					<Col>
						<SaveButton name='Login' />
					</Col>
				</Row>
			</form>
		</>
	);
}
