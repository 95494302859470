import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router';
import {
	countriesList,
	travelMethodsList,
	immStatusList,
	portOfEntriesList,
} from '../../../app/components/DataLists';
import { format } from 'date-fns';
import SaveButton from '../../../app/components/SaveButton';
import CancelButton from '../../../app/components/CancelButton';

export default function TravelRecordForm({
	addTravelRecordSubmitFunction,
	updateTravelRecordSubmitFunction,
	travelRecord,
	clientID,
}) {
	const location = useLocation();
	const history = useHistory();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			dateOfTrip: travelRecord?.dateOfTrip || null,
			originCountry: travelRecord?.originCountry || null,
			destinationCountry: travelRecord?.destinationCountry || null,
			travelMethod: travelRecord?.travelMethod || null,
			travelThroughCountries: travelRecord?.travelThroughCountries || null,
			dateOfArrival: travelRecord?.dateOfArrival || null,
			portOfEntry: travelRecord?.portOfEntry || null,
			immigrationStatus: travelRecord?.immigrationStatus || null,
			typeOfEntry: travelRecord?.typeOfEntry || null,
			purposeOfTrip: travelRecord?.purposeOfTrip || null,
			durationOfTrip: travelRecord?.durationOfTrip || null,
		},
	});

	useEffect(() => {
		travelRecord &&
			reset({
				...travelRecord,
				dateOfTrip:
					(travelRecord.dateOfTrip && format(new Date(travelRecord.dateOfTrip), 'yyyy-MM-dd')) ||
					null,
				dateOfArrival:
					(travelRecord.dateOfArrival &&
						format(new Date(travelRecord.dateOfArrival), 'yyyy-MM-dd')) ||
					null,
			});
	}, [reset, travelRecord]);

	const onSubmit = (formData) => {
		if (!travelRecord)
			addTravelRecordSubmitFunction({
				...formData,
				clients: [clientID],
				dateOfTrip: (formData.dateOfTrip && formData.dateOfTrip + 'T00:00') || null,
				dateOfArrival: (formData.dateOfArrival && formData.dateOfArrival + 'T00:00') || null,
			});
		if (travelRecord)
			updateTravelRecordSubmitFunction({
				...formData,
				dateOfTrip: (formData.dateOfTrip && formData.dateOfTrip + 'T00:00') || null,
				dateOfArrival: (formData.dateOfArrival && formData.dateOfArrival + 'T00:00') || null,
			});
	};
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('dateOfTrip')}
								max='2999-12-31'
								id='dateOfTrip'
								type='date'
								placeholder='Date of Trip'
							/>
							<label htmlFor='dateOfTrip'>Date of Trip</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('originCountry')}
								id='originCountry'
								type='text'
								list='countries'
								placeholder='Origin Country'
								required
							/>
							<label htmlFor='originCountry'>Origin Country</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='countries'>
								{countriesList.map((country) => (
									<option key={country} value={country} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
					<Col>
						{' '}
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('destinationCountry')}
								id='destinationCountry'
								type='text'
								list='countries'
								placeholder='Destination Country'
								required
							/>
							<label htmlFor='destinationCountry'>Destination Country</label>
						</div>
					</Col>
				</Row>

				<Row>
					<Col sm='8'>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('purposeOfTrip')}
								id='purposeOfTrip'
								type='text'
								placeholder='Purpose of Trip'
							/>
							<label htmlFor='purposeOfTrip'>Purpose of Trip</label>
						</div>
					</Col>
					<Col>
						{' '}
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('durationOfTrip')}
								id='durationOfTrip'
								type='text'
								placeholder='Duration of Trip'
							/>
							<label htmlFor='durationOfTrip'>Duration of Trip</label>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('travelMethod')}
								id='travelMethod'
								type='text'
								list='travel-methods'
								placeholder='Travel Method'
							/>
							<label htmlFor='travelMethod'>Travel Method</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='travel-methods'>
								{travelMethodsList.map((method) => (
									<option key={method} value={method} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
					<Col>
						{' '}
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('travelThroughCountries')}
								id='travelThroughCountries'
								type='text'
								list='countries'
								placeholder='Travel Through Countries'
							/>
							<label htmlFor='travelThroughCountries'>Travel Through Countries</label>
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('dateOfArrival')}
								max='2999-12-31'
								id='dateOfArrival'
								type='date'
								placeholder='Date of Arrival'
							/>
							<label htmlFor='dateOfArrival'>Date of Arrival</label>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						{' '}
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('portOfEntry')}
								id='portOfEntry'
								type='text'
								list='ports'
								placeholder='Port of Entry'
							/>
							<label htmlFor='portOfEntry'>Port of Entry</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='ports'>
								{portOfEntriesList.map((ports) => (
									<option key={ports} value={ports} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
					<Col>
						<div className='form-floating mb-3'>
							<input
								className='form-control'
								{...register('immigrationStatus')}
								id='immigrationStatus'
								type='text'
								list='imm-status'
								placeholder='Immigration Status'
							/>
							<label htmlFor='immigrationStatus'>Immigration Status</label>
							{/* ===============DATALIST OPTIONS================== */}
							<datalist id='imm-status'>
								{immStatusList.map((status) => (
									<option key={status} value={status} />
								))}
							</datalist>
							{/* ================================================= */}
						</div>
					</Col>
					<Col>
						{' '}
						<div className='form-floating mb-3'>
							<select className='form-select' {...register('typeOfEntry')} id='typeOfEntry'>
								<option value=''></option>
								<option value='Lawful'>Lawful</option>
								<option value='Unlawful'>Unlawful</option>
							</select>
							<label htmlFor='typeOfEntry'>Type of Entry</label>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
