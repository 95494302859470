import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { confDeleteFn } from '../../../../app/utils/confirm-delete';
import { Table, Button } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import NotSet from '../../../../app/components/NotSet';
// import { removeChecklistItem } from '../../../../api/restQueries';
import { RestQueriesContext } from '../../../../api/restQueries';

export default function FilingPrepChecklistView() {
	const { removeChecklistItem } = useContext(RestQueriesContext);
	const { event, refetchEvent: refetch } = useContext(AppContext);
	const location = useLocation();

	const {
		mutate,
		isError: isMutationError,
		error: mutationError,
		isLoading: isMutationLoading,
	} = useMutation((id) => removeChecklistItem(id), {
		onSuccess: () => {
			refetch();
		},
	});

	if (isMutationLoading) return <div>Removing checklist item...</div>;

	if (isMutationError) return <div>A mutation error occurred: {mutationError.message}</div>;

	return event?.prep_checklists.length === 0 ? (
		<div>
			<Link
				to={{
					pathname: `/add-checklist-item/${event.id}`,
					state: { prevURL: location.pathname },
				}}
			>
				+ Filing Checklist Item
			</Link>
		</div>
	) : (
		event && (
			<>
				<Table bordered size='sm'>
					<thead>
						<tr>
							<th>Item</th>
							<th>Status</th>
							<th>Provided By</th>
							<th>Note</th>
							<th colSpan='2'>Action</th>
						</tr>
					</thead>
					<tbody>
						{event?.prep_checklists.map((item) => {
							return (
								<tr key={item.id}>
									<td className='text-start'>{item.item}</td>
									<td>{item.status}</td>
									<td>{item.providedBy}</td>
									<td>{item.note || <NotSet />}</td>

									<td>
										<Link
											to={{
												pathname: `/update-checklist-item/${item.id}`,
												state: {
													prevURL: location.pathname,
												},
											}}
										>
											Edit
										</Link>
									</td>
									<td>
										{/* <Button variant='link' onClick={() => mutate(element.id)}> */}
										<Button
											variant='link'
											onClick={() => confDeleteFn(item.id, mutate, 'Filing Checklist Item')}
										>
											Remove
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
				<div>
					<Link
						to={{
							pathname: `/add-checklist-item/${event.id}`,
							state: { prevURL: location.pathname },
						}}
					>
						+ Filing Checklist Item
					</Link>
				</div>
			</>
		)
	);
}
