import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import SaveButton from '../../../../app/components/SaveButton';
import CancelButton from '../../../../app/components/CancelButton';
import { agenciesList } from '../../../../app/components/DataLists';

export default function ReceivedDocumentForm({
	addEventSubmitFunction,
	updateEventSubmitFunction,
	event,
	category,
	caseID,
}) {
	const history = useHistory();
	const location = useLocation();

	const cancelButtonHandler = () => history.push(location.state.prevURL);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			originalDateTime: event?.originalDateTime || null,
			agency: event?.agency || null,
			regarding: event?.regarding || null,
			documentReceived: event?.documentReceived || null,
			receiptNumber: event?.receiptNumber || null,
			note: event?.note || null,
		},
	});

	useEffect(() => {
		event &&
			reset({
				...event,
				originalDateTime:
					(event.originalDateTime && format(new Date(event.originalDateTime), 'yyyy-MM-dd')) ||
					null,
			});
	}, [reset, event]);

	const onSubmit = (formData) => {
		if (!event) {
			addEventSubmitFunction({
				...formData,
				cases: [caseID],
				category: category,
				status: 'incoming',
				originalDateTime:
					(formData.originalDateTime && formData.originalDateTime + 'T00:00') || null,
			});
		}
		if (event) {
			updateEventSubmitFunction({
				...formData,
				originalDateTime:
					(formData.originalDateTime && formData.originalDateTime + 'T00:00') || null,
			});
		}
	};

	return (
		<>
			{/* <h3>Received Document</h3> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('originalDateTime')}
						max='2999-12-31'
						id='date'
						type='date'
						placeholder='Date'
						required
					/>
					<label htmlFor='originalDateTime'>Date</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						id='agency'
						{...register('agency')}
						list='agencies'
						type='text'
						placeholder='Agency'
					/>
					<label htmlFor='agency'>Agency</label>
					{/* ===============DATALIST OPTIONS================== */}
					<datalist id='agencies'>
						{agenciesList.map((agency) => (
							<option key={agency} value={agency} />
						))}
					</datalist>
					{/* ================================================= */}
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('documentReceived')}
						id='documentReceived'
						type='text'
						placeholder='Document Received'
						required
					/>
					<label htmlFor='documentReceived'>Document Received</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('receiptNumber')}
						id='receiptNumber'
						type='text'
						placeholder='Receipt # (if any)'
					/>
					<label htmlFor='receiptNumber'>Receipt # (if any)</label>
				</div>

				<div className='form-floating mb-3'>
					<input
						className='form-control'
						{...register('regarding')}
						id='regarding'
						type='text'
						placeholder='Regarding'
					/>
					<label htmlFor='regarding'>Regarding</label>
				</div>

				<div className='form-floating mb-3'>
					<textarea
						className='form-control'
						{...register('note')}
						id='note'
						placeholder='Note'
						style={{ height: '10rem' }}
					></textarea>
					<label htmlFor='note'>Note</label>
				</div>

				<Row>
					<Col>
						<CancelButton cancelButtonHandler={cancelButtonHandler} />
					</Col>
					<Col>
						<SaveButton />
					</Col>
				</Row>
			</form>
		</>
	);
}
