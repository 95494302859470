import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import EventStatusUpdateForm from '../components/forms/Event-Status-Update-Form';
import FormTitle from '../../app/components/FormTitle';
import { Container, Table } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router';
import { useMutation, useQuery } from 'react-query';
// import { updateEvent, addEvent, getEvent } from '../../api/restQueries';
import { RestQueriesContext } from '../../api/restQueries';
import format from 'date-fns/format';

export default function UpdateEventStatusPage() {
	const { updateEvent, addEvent, getEvent } = useContext(RestQueriesContext);
	const { client, cCase } = useContext(AppContext);
	const history = useHistory();
	const { eventID } = useParams();
	const location = useLocation();
	const { category } = location.state;

	const {
		isLoading,
		isError,
		data: event,
		error,
	} = useQuery(['getEvent', eventID], () => getEvent(eventID));

	const {
		mutate: mutateUpdate,
		isError: isUpdateMutationError,
		error: updateMutationError,
		isLoading: isUpdateMutationLoading,
	} = useMutation((formData) => updateEvent(eventID, formData), {
		onSuccess: () => {
			history.push('/view-client/' + client.id);
		},
	});

	const {
		mutate: mutateAdd,
		isError: isAddMutationError,
		error: AddMutationError,
		isLoading: isAddMutationLoading,
	} = useMutation((formData) => addEvent(formData), {
		onSuccess: (data) => {
			history.push({
				// pathname: '/view-client/' + client.id,
				pathname: `/view-client/${client.id}/${cCase.id}`,
				state: data,
			});
		},
	});

	const onSubmitUpdate = async (data) => {
		mutateUpdate(data);
	};

	const onSubmitAdd = async (data) => {
		mutateAdd(data);
	};

	if (isLoading) return <div>Loading...</div>;
	if (isUpdateMutationLoading) return <div>UPDATE Mutation Loading...</div>;
	if (isAddMutationLoading) return <div>ADD Mutation Loading...</div>;

	if (isError) return <div>An error occurred: {error.message}</div>;
	if (isUpdateMutationError)
		return <div> UPDATE mutation error occurred: {updateMutationError.message}</div>;
	if (isAddMutationError)
		return <div> ADD mutation error occurred: {AddMutationError.message}</div>;

	return (
		<>
			<Container>
				<FormTitle client={client} cCase={cCase} caption={`Change ${event.category} status `} />
				<Table
					bordered
					className='text-center mx-auto'
					style={{ width: '60%', background: '#fff' }}
				>
					<thead>
						<tr>
							<th>Current Date</th>
							<th>Event</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{format(new Date(event.originalDateTime), 'MMMM dd, yyyy')}</td>
							<td>
								{event.type} {event.category}
							</td>
						</tr>
					</tbody>
				</Table>
				<div className='mx-auto'>
					<EventStatusUpdateForm
						updateEventSubmitFunction={onSubmitUpdate}
						addEventSubmitFunction={onSubmitAdd}
						caseID={cCase?.id}
						category={category}
						event={event}
					/>
				</div>
			</Container>
		</>
	);
}
